import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import BounceLoader from "react-spinners/BounceLoader";

const Homepage = () =>
{
	const currentUser = localStorage.getItem("session_token")

    let history = useHistory();
    const purple = '#6571FE'

    function login() {
        if (currentUser)
        {
            const url = 'https://app-slfinance.fr/api/connexion.php';
			const params = {
				'session_token': currentUser
			};
			axios.post(url, qs.stringify(params))
            .then((res) => {
                if (res.data.id_type_users === '1') {
                    history.push("/adminpage");
                }
                else if (res.data.id_type_users === '2') {
                    history.push("/organismepage");
                }
                else if (res.data.id_type_users === '3') {
                    history.push("/organismepage");
                }
                else {
                    history.push("/logout");
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            history.push("/login");
        }
    }

    useEffect(() => {
        login()
        
    }, [])

	return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}></div>
            <div style={{marginTop: 100}}>
                <BounceLoader color={purple} />
            </div>
            <div style={{flex: 1}}></div>
        </div>
	);
}

export default Homepage;
