import React, { useEffect, useState } from 'react';
import InvitUser from "./components/InvitUser";
import CreateOrganisme from "./components/CreateOrganisme";
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import NavbarAdmin from "./components/NavbarAdmin";
import SwitchOrganisme from "./components/SwitchOrganisme";
import SiphonnageDataOrg from './components/SiphonnageDataOrg';
import CreateBudget from "./components/CreateBudget";
import DeleteOrganisme from "./components/DeleteOrganisme";
import SetupYearsApplication from "./components/SetupYearsApplication";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
// import { feedRei } from './components/Tools';
import {NotificationManager} from 'react-notifications';


const Adminpage = (props) =>
{
    const [organisme, setOrganisme] = useState(false);
    let history = useHistory();
    const purple = '#6571FE'

    useEffect(() => {
		props.getUser();
        console.log(props);
    }, [])


    function siretexist(siret, tab)
    {
        var i = 0
        while(i < tab.length)
        {
            if (tab[i].siret == siret)
            {
                return true;
            }
            i++;
        }
        return false;
    }


    async function foundDette(siren, type, code_geo = "", deb = '', fin = '')
    {
        // Recuperation des valeurs par defaut des variables recup_an_deb et recup_an_fin dans la table application.
        const url = 'https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales';
        await axios.get(url)
        .then(async function (res) {
            if (res.data.recup_an_deb && res.data.recup_an_fin) {
                if (deb === '') {
                    deb = parseInt(res.data.recup_an_deb)
                }
                if (fin === '') {
                    fin = parseInt(res.data.recup_an_fin)
                }
                var token_api_insee = res.data.token_api_insee
                var budgets_tmp = []
                var index = 0
                var j = 0

                // Recuperation de l'id de organisme.
                var id_organisme = 0
                await axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true, 'siren': siren}))
                .then(function (ret) {
                    id_organisme = ret.data[0].id
                    setOrganisme(ret.data[0])
                })
                .catch((err) => {
                    console.log(err)
                })

                // verification de mon annee de creation et de cessation et si le siren existe
                var config = {
                    method: 'get',
                    url: 'https://api.insee.fr/entreprises/sirene/V3/siren/'+siren+'?champs=siren%2CetatAdministratifUniteLegale%2CdateCreationUniteLegale',
                    headers: {
                        'Authorization': 'Bearer '+token_api_insee
                    },
                };
                await axios(config)
                .then(async function (response)
                {
                    // Cette partie definie la date de debut et de fin
                    response = response.data.uniteLegale
                    var annee_deb = parseInt(response.dateCreationUniteLegale.split('-')[0])
                    var annee_fin = ''
                    await response.periodesUniteLegale.forEach(element => {
                        if (element.etatAdministratifUniteLegale == "C")
                        {
                            annee_fin = parseInt(element.dateDebut) -1
                        }
                    });
                    if (parseInt(annee_deb) <= deb) {
                        annee_deb = deb
                    }
                    if (annee_fin == '') {
                        annee_fin = fin
                    }
                    deb = annee_deb
                    fin = annee_fin
                    
                    var liste_annees = []
                    deb++
                    while (deb <= fin) {
                        liste_annees.push(deb)
                        deb++
                    }
                    deb = annee_deb
                    var list_budgets = {}

                    var count_sc = []
                    // Boucle qui va recuperer les infos des budgets par année.
                    while(deb <= fin)
                    {
                        count_sc[fin] = [];
                        /******* Protocole si c'est une Commune ********/
                        if (type === "COM")
                        {
                            // Recuperation de la liste des budgets (siret, nom, budget annexe)
                            await axios.get('https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-communes-en-'+fin+'&q=&rows=-1&facet=ident&facet=lbudg&facet=cbudg&refine.siren='+siren)
                            .then(async function (result)
                            {
                                await result.data.records.forEach(record =>
                                {
                                    // Creation de la liste des budgets
                                    if (siretexist(record.fields.ident, budgets_tmp) === false)
                                    {
                                        if (record.fields.cbudg == "1" || record.fields.budget == "BP")
                                        {
                                            budgets_tmp[j] = {
                                                'id_organisme': id_organisme,
                                                'siret': record.fields.ident,
                                                'nom': record.fields.lbudg,
                                                'budget_annexe': 0
                                            }
                                        }
                                        else
                                        {
                                            budgets_tmp[j] = {
                                                'id_organisme': id_organisme,
                                                'siret': record.fields.ident,
                                                'nom': record.fields.lbudg,
                                                'budget_annexe': 1
                                            }
                                        }
                                        j++;
                                    }
                                    
                                    if (record.fields.nomen === "M57" || record.fields.nomen === "M14" || record.fields.nomen === "M57A" || record.fields.nomen === "M14A" || record.fields.nomen === "M4" || record.fields.nomen === "M41" || record.fields.nomen === "M43" || record.fields.nomen === "M49")
                                    {
                                        if ((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0)
                                        {
                                            if (record.fields.exer > deb)
                                            {
                                                if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                                    if (!count_sc[fin][record.fields.lbudg]) {
                                                        count_sc[fin][record.fields.lbudg] = 0;
                                                    }
                                                    count_sc[fin][record.fields.lbudg] += record.fields.sc
                                                }
                                                index++
                                            }
                                        }
                                    }
                                    else {
                                        console.log("WIP maquettes comptables...", record.fields.nomen)
                                    }
                                });
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        }
                        /******* Protocole si c'est un EPCI ou Syndicat ********/
                        else if (type === "GFP" || type === "SYN")
                        {
                            if (type === "GFP" && fin > 2018)
                            {
                                // Requette pour recuperer les dernieres années sur data.economie.gouv pour un GFP
                                var url = ""
                                if (type === "GFP") {
                                    url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-groupements-a-fiscalite-propre-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.siren='+siren+'&refine.exer='+fin
                                }
                                else if (type === "SYN") {
                                    url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-syndicats-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.siren='+siren+'&refine.exer='+fin
                                }
                                await axios.get(url)
                                .then(async function (result) {
                                    await result.data.records.forEach(record =>
                                    {
                                        // Creation de la liste des budgets
                                        if (siretexist(record.fields.ident, budgets_tmp) === false)
                                        {
                                            if (record.fields.cbudg == "1" || record.fields.budget == "BP")
                                            {
                                                budgets_tmp[j] = {
                                                    'id_organisme': id_organisme,
                                                    'siret': record.fields.ident,
                                                    'nom': record.fields.lbudg,
                                                    'budget_annexe': 0
                                                }
                                            }
                                            else
                                            {
                                                budgets_tmp[j] = {
                                                    'id_organisme': id_organisme,
                                                    'siret': record.fields.ident,
                                                    'nom': record.fields.lbudg,
                                                    'budget_annexe': 1
                                                }
                                            }
                                            j++;
                                        }
                                        // Creation de la liste des datas_brut
                                        if (record.fields.nomen === "M57" || record.fields.nomen === "M14" || record.fields.nomen === "M57A" || record.fields.nomen === "M14A" || record.fields.nomen === "M4" || record.fields.nomen === "M41" || record.fields.nomen === "M43" || record.fields.nomen === "M49")
                                        {
                                            if ((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0)
                                            {
                                                if (record.fields.exer > deb)
                                                {
                                                    if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                                        if (!count_sc[fin][record.fields.lbudg]) {
                                                            count_sc[fin][record.fields.lbudg] = 0;
                                                        }
                                                        count_sc[fin][record.fields.lbudg] += record.fields.sc
                                                    }
                                                    index++
                                                }
                                            }
                                        }
                                        else {
                                            console.log("WIP maquettes comptables...", record.fields.nomen)
                                        }
                                    });
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            }
                            else if ((type === "GFP" && fin >= 2013 && fin <= 2018) || type == "SYN")
                            {
                                // Requette pour lires les infos depuis les fichiers
                                var params = {}
                                if (type === "GFP") {
                                    params = {
                                        'annee': fin,
                                        'siren': siren,
                                        'GFP': true
                                    }
                                }
                                else if (type === "SYN") {
                                    params = {
                                        'annee': fin,
                                        'siren': siren,
                                        'SYN': true
                                    }
                                }
                                await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                                .then(async (result) => {
                                    if (result.data.status !== 0)
                                    {
                                        await result.data.forEach(async record =>
                                        {
                                            // Creation de la liste des budgets
                                            if (siretexist(record.ident, budgets_tmp) === false)
                                            {
                                                if (record.cbudg === "1" || record.budget === "BP")
                                                {
                                                    budgets_tmp[j] = {
                                                        'id_organisme': id_organisme,
                                                        'siret': record.ident,
                                                        'nom': record.lbudg,
                                                        'budget_annexe': 0
                                                    }
                                                }
                                                else
                                                {
                                                    budgets_tmp[j] = {
                                                        'id_organisme': id_organisme,
                                                        'siret': record.ident,
                                                        'nom': record.lbudg,
                                                        'budget_annexe': 1
                                                    }
                                                }
                                                j++;
                                            }
                                            
                                            if (record.nomen === "M57" || record.nomen === "M14" || record.nomen === "M57A" || record.nomen === "M14A" || record.nomen === "M4" || record.nomen === "M41" || record.nomen === "M43" || record.nomen === "M49")
                                            {
                                                if ((record.obnetcre - record.oobcre) != 0 || (record.obnetdeb - record.oobdeb) != 0)
                                                {
                                                    if (record.exer > 2013)
                                                    {
                                                        if (record.compte === '1641' || record.compte === '1643' || record.compte.startsWith('163')) {
                                                            if (!count_sc[fin][record.lbudg]) {
                                                                count_sc[fin][record.lbudg] = 0;
                                                            }
                                                            count_sc[fin][record.lbudg] += record.sc
                                                        }
                                                        index++
                                                    }
                                                }
                                            }
                                            else {
                                                console.log("WIP maquettes comptables...", record.nomen)
                                            }
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            }
                        }
                        fin--;
                    }
                    
                    console.log(count_sc);
                })
                .catch((err) => {
                    console.log(err)
                    if (err.includes('Error: Network Error')) {
                        NotificationManager.warning('API INSEE DOWN', '', 2000);
                        setTimeout(() => window.location = window.location.href, 2500);
                    }
                })
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    function tout_faire()
    {
        // axios.get('https://app-slfinance.fr/api/cron_pilotage_projet.php')
        // .then((res) => {
        //     console.log(res.data)
        //     // NotificationManager.success(res.data, '', 5000);
        // })
    }


	return (
        <Access needConnection={true} type_user={[1]}>
            <Navbar />
            <NavbarAdmin />
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <div style={{display: 'flex'}}>

                        <div style={{flex: 1, textAlign: 'center'}}>
                            <div style={{margin: 20}}>
                                <SwitchOrganisme user={props.user} getUser={props.getUser}/>
                            </div>
                            <div style={{flex: 1, margin: 20}}>
                                <InvitUser />
                            </div>

                            {props.user && props.user.email === 'victor.castera@strategies-locales.fr'?
                            <div>
                                <div style={{margin: 20}}>
                                    <button className='shadow-md p-2 rounded-md' style={{backgroundColor: purple, color: 'white', fontSize: 14, fontSize: 14}}
                                    onClick={() => {tout_faire()}}>Le bouton à tout faire
                                    </button>
                                </div>
                                <div style={{margin: 20}}>
                                    <button className='shadow-md p-2 rounded-md' style={{backgroundColor: purple, color: 'white', fontSize: 14, fontSize: 14}}
                                    onClick={() => {history.push('/massivemailing')}}>Massive Mailing
                                    </button>
                                </div>
                            </div>
                            :null}
                        </div>


                        <div style={{flex: 1}}>
                            <div style={{margin: 20}}>
                                <CreateOrganisme />
                            </div>
                            <div style={{margin: 20}}>
                                <CreateBudget />
                            </div>
                        </div>


                        <div style={{flex: 1, textAlign: 'center'}}>
                            <div style={{margin: 20, border: '2px solid orange', borderRadius: 5}}>
                                <SiphonnageDataOrg user={props.user} getUser={props.getUser}/>
                            </div>
                            <div style={{flex: 1, margin: 20, border: '2px solid orange', borderRadius: 5}}>
                                <SetupYearsApplication />
                            </div>
                            <div style={{margin: 20, border: '2px solid red', borderRadius: 5}}>
                                <DeleteOrganisme />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Access>
	);
}

export default Adminpage;
