import React, { useEffect, useState } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import BounceLoader from "react-spinners/BounceLoader";
import axios from 'axios';
import qs from 'qs';
import { getyears, addSpace } from './components/Tools';

const VrefiRetro = (props) =>
{
    const [annee, setAnnee] = useState()
    const [annees, setAnnees] = useState([])
    const [aff, setAff] = useState(true)

    const [reelDebFct, setReelDebFct] = useState(0)
    const [reelCreFct, setReelCreFct] = useState(0)
    const [reelDebInv, setReelDebInv] = useState(0)
    const [reelCreInv, setReelCreInv] = useState(0)

    const [oobDebFct, setOobDebFct] = useState(0)
    const [oobCreFct, setOobCreFct] = useState(0)
    const [oobDebInv, setOobDebInv] = useState(0)
    const [oobCreInv, setOobCreInv] = useState(0)

    const [d002, setD002] = useState(0)
    const [r002, setR002] = useState(0)
    const [d001, setD001] = useState(0)
    const [r001, setR001] = useState(0)

    const [tot_rea_report_dep, setTot_rea_report_dep] = useState(0)
    const [tot_rea_report_rec, setTot_rea_report_rec] = useState(0)

    const [rarDepFct, setRarDepFct] = useState(0)
    const [rarRecFct, setRarRecFct] = useState(0)
    const [rarDepInv, setRarDepInv] = useState(0)
    const [rarRecInv, setRarRecInv] = useState(0)
    const [totRarDep, setTotRarDep] = useState(0)
    const [totRarRec, setTotRarRec] = useState(0)

    const [totDepFct, setTotDepFct] = useState(0)
    const [totRecFct, setTotRecFct] = useState(0)
    const [totDepInv, setTotDepInv] = useState(0)
    const [totRecInv, setTotRecInv] = useState(0)
    const [totDepCumule, setTotDepCumule] = useState(0)
    const [totRecCumule, setTotRecCumule] = useState(0)

    const purple = '#6571FE'
    const greyChapter = '#DBDBDB'
    const blue = '#069BD3'
    
    useEffect(() => {
        if (props.user === null) {
            props.getUser()
        }
        else {
            if (!annee || !annees) {
                getyears(props.user.id_budget, 'R')
                .then(async(result) => {
                    await setAnnee(result[0])
                    await setAnnees(result)
                })
            }
            else {
                const params = {
                    getVerifSaisie: true,
                    annee: annee,
                    id_budget: props.user.id_budget
                }
                axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify(params))
                .then((ret) => {
                    
                    var tmp_tot_rea_ordre_reports_dep = 0
                    var tmp_tot_rea_ordre_reports_rec = 0
                    var tmp_tot_restes_rea_dep = 0
                    var tmp_tot_restes_rea_rec = 0
                    var tmp_totDepFct = 0
                    var tmp_totRecFct = 0
                    var tmp_totDepInv = 0
                    var tmp_totRecInv = 0
                    var tmp_DepCumule = 0
                    var tmp_RecCumule = 0

                    ret.data.forEach(element => {
                        // A 
                        if (element[0] === '2014M57REELDEBFCT') {
                            setReelDebFct(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepFct = tmp_totDepFct + element[1];
                        }
                        if (element[0] === '2014M57REELCREFCT') {
                            setReelCreFct(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecFct = tmp_totRecFct + element[1];
                        }
                        // B
                        if (element[0] === '2014M57REELDEBINV') {
                            setReelDebInv(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepInv = tmp_totDepInv + element[1];
                        }
                        if (element[0] === '2014M57REELCREINV') {
                            setReelCreInv(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecInv = tmp_totRecInv + element[1];
                        }
                        // C
                        if (element[0] === '2014M57OOBDEBFCT') {
                            setOobDebFct(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepFct = tmp_totDepFct + element[1];
                        }
                        if (element[0] === '2014M57OOBCREFCT') {
                            setOobCreFct(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecFct = tmp_totRecFct + element[1];
                        }
                        // D
                        if (element[0] === '2014M57OOBDEBINV') {
                            setOobDebInv(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepInv = tmp_totDepInv + element[1];
                        }
                        if (element[0] === '2014M57OOBCREINV') {
                            setOobCreInv(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecInv = tmp_totRecInv + element[1];
                        }
                        //  E
                        if (element[0] === '2014M57D002') {
                            setD002(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepFct = tmp_totDepFct + element[1];
                        }
                        if (element[0] === '2014M57R002') {
                            setR002(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecFct = tmp_totRecFct + element[1];
                        }
                        // F
                        if (element[0] === '2014M57D001') {
                            setD001(element[1])
                            tmp_tot_rea_ordre_reports_dep = tmp_tot_rea_ordre_reports_dep + element[1];
                            tmp_totDepInv = tmp_totDepInv + element[1];
                        }
                        if (element[0] === '2014M57R001') {
                            setR001(element[1])
                            tmp_tot_rea_ordre_reports_rec = tmp_tot_rea_ordre_reports_rec + element[1];
                            tmp_totRecInv = tmp_totRecInv + element[1];
                        }
                        // G
                        if (element[0] === '2014M57RARDEPFCT') {
                            setRarDepFct(element[1])
                            tmp_tot_restes_rea_dep = tmp_tot_restes_rea_dep + element[1];
                            tmp_totDepFct = tmp_totDepFct + element[1];
                        }
                        if (element[0] === '2014M57RARRECFCT') {
                            setRarRecFct(element[1])
                            tmp_tot_restes_rea_rec = tmp_tot_restes_rea_rec + element[1];
                            tmp_totRecFct = tmp_totRecFct + element[1];
                        }
                        // H
                        if (element[0] === '2014M57RARDEPINV') {
                            setRarDepInv(element[1])
                            tmp_tot_restes_rea_dep = tmp_tot_restes_rea_dep + element[1];
                            tmp_totDepInv = tmp_totDepInv + element[1];
                        }
                        if (element[0] === '2014M57RARRECINV') {
                            setRarRecInv(element[1])
                            tmp_tot_restes_rea_rec = tmp_tot_restes_rea_rec + element[1];
                            tmp_totRecInv = tmp_totRecInv + element[1];
                        }
                    });
                    setTot_rea_report_dep(Math.round(tmp_tot_rea_ordre_reports_dep * 100) / 100)
                    setTot_rea_report_rec(Math.round(tmp_tot_rea_ordre_reports_rec * 100) / 100)
                    setTotRarDep(Math.round(tmp_tot_restes_rea_dep * 100) / 100)
                    setTotRarRec(Math.round(tmp_tot_restes_rea_rec * 100) / 100)
                    setTotDepFct(Math.round(tmp_totDepFct * 100) / 100)
                    setTotRecFct(Math.round(tmp_totRecFct * 100) / 100)
                    setTotDepInv(Math.round(tmp_totDepInv * 100) / 100)
                    setTotRecInv(Math.round(tmp_totRecInv * 100) / 100)
                    setTotDepCumule(Math.round((tmp_totDepFct + tmp_totDepInv) * 100) / 100)
                    setTotRecCumule(Math.round((tmp_totRecFct + tmp_totRecInv) * 100) / 100)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    }, [props.user, annee])

    
    const setYear = async(event) => {
        await setAnnee(parseInt(event));
    }
    
	return (
        <Access needConnection={true} type_user={[0]}>
            <Navbar user={props.user} />

            <div style={{display: 'flex'}}>

                <div style={{flex: 1, margin: 20, display: 'flex'}}>
                    <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Vérification de la saisie</p>
                </div>

                {/* Nom du Budget */}
                {props.user && props.user.nom_budget?
                <p style={{flex: 1, textAlign: 'center', margin: 20}}>{props.user.nom_budget}</p>
                :null}

                <div style={{flex: 1}}></div>

            </div>

            {/* Tableau des vérifications des saisies */}
            <div style={{flex: 1, margin: 50}}>

                {/* Premiere ligne */}
                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        {/* Selection de l'annee */}
                        <select
                        className='shadow-md'
                        style={{paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15, marginTop: 15, marginRight: 15}}
                        onChange={async(e) => {
                            await setYear(e.target.value)
                        }}>
                            {annees.map((year, i) => 
                            <option key={i} value={year}>{year}</option>
                            )}
                        </select>
                        
                    </div>

                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, borderRight: '1px solid white'}}>
                        <div>
                            <p style={{color: 'white'}}>DÉPENSES</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopRightRadius: 5, borderBottomRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>RECETTES</p>
                        </div>
                    </div>
                </div>


                {/* Ligne : REALISATION DE L'EXERCICE */}
                <div style={{display: 'flex', marginTop: 15}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>RÉALISATION DE</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        <p>Section de fonctionnement</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        <p>{addSpace(reelDebFct)}</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                        <p>{addSpace(reelCreFct)}</p>
                    </div>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>L'EXERCICE</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>Section d'investissement</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>{addSpace(reelDebInv)}</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black'}}>
                        <p>{addSpace(reelCreInv)}</p>
                    </div>
                </div>


                {/* Ligne : OPERATIONS D'ORDRE */}
                <div style={{display: 'flex', marginTop: 15}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>OPÉRATIONS</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        <p>Section de fonctionnement</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        {oobDebFct == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(oobDebFct)}</p>}
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                        {oobCreFct == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(oobCreFct)}</p>}
                    </div>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>D'ORDRE</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>Section d'investissement</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        {oobDebInv == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(oobDebInv)}</p>}
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black'}}>
                        {oobCreInv == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(oobCreInv)}</p>}
                    </div>
                </div>


                {/* Ligne : REPORTS DE L'EXERCICE ANTERIEUR */}
                <div style={{display: 'flex', marginTop: 15}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>REPORT DE</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        <p>Report en section de fonctionnement (002)</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                        <p>{addSpace(d002)}</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                        <p>{addSpace(r002)}</p>
                    </div>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                        <div>
                            <p style={{color: 'white'}}>L'EXERCICE ANTÉRIEUR</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>Report en section d'investissement (001)</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>{addSpace(d001)}</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black'}}>
                        <p>{addSpace(r001)}</p>
                    </div>
                </div>


                {/* Ligne : Total (realisation + reports) */}
                <div style={{display: 'flex', marginTop: 10}}>
                    <div style={{display: 'flex', flex: 1}}>

                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>TOTAL (réalisation + ordre + reports)</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>{addSpace(tot_rea_report_dep)}</p>
                    </div>
                    <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black'}}>
                        <p>{addSpace(tot_rea_report_rec)}</p>
                    </div>
                </div>


            {/* Ligne : RESTES A REALISER A REPORTER SUR L'EXERCICE SUIVANT */}
            <div style={{display: 'flex', marginTop: 25}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                    <div>
                        <p style={{color: 'white'}}>RESTES À RÉALISER À</p>
                    </div>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>Section de fonctionnement</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    {rarDepFct == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(rarDepFct)}</p>}
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                    {rarRecFct == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(rarRecFct)}</p>}
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                    <div>
                        <p style={{color: 'white'}}>REPORTER SUR L'EXERCICE SUIVANT</p>
                    </div>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>Section d'investissement</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    {rarDepInv == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(rarDepInv)}</p>}
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                    {rarRecInv == 0?<p style={{color: "red"}}>(non-saisie ou 0)</p>:<p>{addSpace(rarRecInv)}</p>}
                </div>
            </div>


            {/* Ligne : Total (realisation + reports) */}
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>

                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>TOTAL des restes à réaliser</p>
                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>{addSpace(totRarDep)}</p>
                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>{addSpace(totRarRec)}</p>
                </div>
            </div>


            {/* Ligne : RESULTAT CUMULÉ */}
            <div style={{display: 'flex', marginTop: 25}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                    <div>
                        <p style={{color: 'white'}}>RÉSULTAT</p>
                    </div>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>Section de fonctionnement</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>{addSpace(totDepFct)}</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                    <p>{addSpace(totRecFct)}</p>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: blue, borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                    <div>
                        <p style={{color: 'white'}}>CUMULÉ</p>
                    </div>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>Section d'investissement</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black'}}>
                    <p>{addSpace(totDepInv)}</p>
                </div>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black'}}>
                    <p>{addSpace(totRecInv)}</p>
                </div>
            </div>


            {/* Ligne : Total (realisation + reports) */}
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>

                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', marginLeft: 10, borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>TOTAL CUMULÉ</p>
                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>{addSpace(totDepCumule)}</p>
                </div>
                <div style={{display: 'flex', flex: 1, backgroundColor: greyChapter, alignItems: 'center', justifyContent: 'center', borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black'}}>
                    <p>{addSpace(totRecCumule)}</p>
                </div>
            </div>

        </div>

        </Access>
	);
}

export default VrefiRetro;
