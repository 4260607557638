import './Navbar.css';
import React, {useState, useEffect} from 'react';
import { ReactComponent as IconUser } from '../Img/icon-user.svg';
import { ReactComponent as IconAPI } from '../Img/icon-API.svg';
import { useHistory } from 'react-router-dom';


const NavbarAdmin = () =>
{
    let history = useHistory();
    // const [aff, setAff] = useState(false)

    const purple = '#6571FE'
    const greyChapter = '#DBDBDB'
    
        
    useEffect(() => {
    }, [])
    

	return (
        <div className='shadow-md' style={{flex: 1, backgroundColor: 'white', marginTop: 0.5, display: 'flex', minHeight: 65}}>

            {/* Bouton Gestion de la table Application */}
            <button
            style={{marginLeft: 10, display: 'flex'}}
            onClick={() => {
                window.location = window.location.origin + '/gestion/application'
            }}
            >
                <IconAPI width="20px" style={{marginTop: 20}} />
                <p style={{flex: 1, marginTop: 20, marginLeft: 10}}>Application</p>
            </button>

            {/* Bouton Gestion des utilisateurs */}
            <button
            style={{marginLeft: 10, display: 'flex'}}
            onClick={() => {
                window.location = window.location.origin + '/gestion/utilisateurs'
            }}
            >
                <IconUser width="20px" style={{marginTop: 20}} />
                <p style={{flex: 1, marginTop: 20, marginLeft: 10}}>Utilisateurs</p>
            </button>

            <div style={{flex: 1}}></div>

        </div>
	);
}

export default NavbarAdmin;
