import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import BounceLoader from "react-spinners/BounceLoader";
import {NotificationManager} from 'react-notifications';

const Checktoken = () =>
{
    const purple = '#6571FE'
	const token = window.location.search.split('?token=')[1];
    let history = useHistory();

    function Checktoken() {
        if (token !== null) {
            const url = 'https://app-slfinance.fr/api/auth.php';
            const params = {
                'session_token': token
            };
            axios.post(url, qs.stringify(params))
            .then(async (res) => {
                if (res.data.status === 1) {
                    await localStorage.setItem("session_token", JSON.stringify(token));
                    if (res.data.id_type_users === '1') {
                        history.push("/adminpage");
                    }
                    else if (res.data.id_type_users === '2') {
                        history.push("/organismepage");
                    }
                    else if (res.data.id_type_users === '3') {
                        history.push("/organismepage");
                    }
                }
                else {
                    history.push("/logout");
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        Checktoken();
    }, [])

	return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}></div>
            <div style={{marginTop: 100}}>
                <BounceLoader color={purple} />
            </div>
            <div style={{flex: 1}}></div>
        </div>
	);
}

export default Checktoken;
