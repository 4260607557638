import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useForm } from "react-hook-form";
import { escapeHtml, feedRei } from './Tools';
import Modal from 'react-modal';
import BounceLoader from "react-spinners/BounceLoader";
import ModalCreateBudget from './ModalCreateBudget';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from '../Img/icon-add.svg';
import '../App.css';

const CreateOrganisme = () =>
{
    const [type_organismes, setType_organismes] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [organisme, setOrganisme] = useState(false);

    const green = '#59B44A'
    const purple = '#6571FE'

    useEffect(() => {
        axios.get("https://app-slfinance.fr/api/type_organismes.php")
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: res.data[i].nom_categorie
                }
            }
            setType_organismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])


    function isin(val, tab)
    {
        var i = 0
        while (i < tab.length)
        {
            if (tab[i][0] == val) {
                return(true);
            }
            i++;
        }
        return(false);
    }


    function siretexist(siret, tab)
    {
        var i = 0
        while(i < tab.length)
        {
            if (tab[i].siret == siret)
            {
                return true;
            }
            i++;
        }
        return false;
    }


    async function createbudgets_datas(siren, type, code_geo = "", deb = '', fin = '')
    {
        // Recuperation des valeurs par defaut des variables recup_an_deb et recup_an_fin dans la table application.
        const url = 'https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales';
        await axios.get(url)
        .then(async function (res) {
            if (res.data.recup_an_deb && res.data.recup_an_fin) {
                if (deb === '') {
                    deb = parseInt(res.data.recup_an_deb)
                }
                if (fin === '') {
                    fin = parseInt(res.data.recup_an_fin)
                }
                var token_api_insee = res.data.token_api_insee
                var version_api_insee = res.data.version_api_insee
                var budgets_tmp = []
                var datas_tmp = []
                var listsiret = []
                var index = 0
                var j = 0

                // Recuperation de l'id de organisme.
                var id_organisme = 0
                await axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true, 'siren': siren}))
                .then(function (ret) {
                    id_organisme = ret.data[0].id
                    setOrganisme(ret.data[0])
                })
                .catch((err) => {
                    console.log(err)
                })

                // verification de mon annee de creation et de cessation et si le siren existe
                var config = {
                    method: 'get',
                    url: 'https://api.insee.fr/entreprises/sirene/'+version_api_insee+'/siren/'+siren+'?champs=siren%2CetatAdministratifUniteLegale%2CdateCreationUniteLegale',
                    headers: {
                        'Authorization': 'Bearer '+token_api_insee
                    },
                };
                await axios(config)
                .then(async function (response)
                {
                    // Cette partie definie la date de debut et de fin
                    response = response.data.uniteLegale
                    var annee_deb = parseInt(response.dateCreationUniteLegale.split('-')[0])
                    var annee_fin = ''
                    await response.periodesUniteLegale.forEach(element => {
                        if (element.etatAdministratifUniteLegale == "C")
                        {
                            annee_fin = parseInt(element.dateDebut) -1
                        }
                    });
                    if (parseInt(annee_deb) <= deb) {
                        annee_deb = deb
                    }
                    if (annee_fin == '') {
                        annee_fin = fin
                    }
                    deb = annee_deb
                    fin = annee_fin
                    
                    var liste_annees = []
                    deb++
                    while (deb <= fin) {
                        liste_annees.push(deb)
                        deb++
                    }
                    deb = annee_deb
                    var list_budgets = {}

                    var count_sc = [];
                    // Boucle qui va recuperer les infos des budgets par année.
                    while(deb <= fin)
                    {
                        count_sc[fin] = [];
                        /******* Protocole si c'est une Commune ********/
                        if (type === "COM")
                        {
                            // Recuperation de la liste des budgets (siret, nom, budget annexe)
                            await axios.get('https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-communes-en-'+fin+'&q=&rows=-1&facet=ident&facet=lbudg&facet=cbudg&refine.siren='+siren)
                            .then(async function (result)
                            {
                                await result.data.records.forEach(record =>
                                {
                                    // Creation de la liste des budgets
                                    if (siretexist(record.fields.ident, budgets_tmp) === false)
                                    {
                                        if (record.fields.cbudg == "1" || record.fields.budget == "BP")
                                        {
                                            budgets_tmp[j] = {
                                                'id_organisme': id_organisme,
                                                'siret': record.fields.ident,
                                                'nom': record.fields.lbudg,
                                                'budget_annexe': 0
                                            }
                                        }
                                        else
                                        {
                                            budgets_tmp[j] = {
                                                'id_organisme': id_organisme,
                                                'siret': record.fields.ident,
                                                'nom': record.fields.lbudg,
                                                'budget_annexe': 1
                                            }
                                        }
                                        j++;
                                    }
                                    // Creation de la liste des datas_brut
                                    if (record.fields.nomen === "M57" || record.fields.nomen === "M14" || record.fields.nomen === "M57A" || record.fields.nomen === "M14A" || record.fields.nomen === "M4" || record.fields.nomen === "M41" || record.fields.nomen === "M43" || record.fields.nomen === "M49")
                                    {
                                        if (list_budgets[record.fields.ident] === undefined || (list_budgets[record.fields.ident] !== undefined && list_budgets[record.fields.ident][record.fields.exer] === undefined)) {
                                            if (list_budgets[record.fields.ident] === undefined) {
                                                list_budgets[record.fields.ident] = {}
                                            }
                                            if (list_budgets[record.fields.ident][record.fields.exer] === undefined)
                                            {
                                                list_budgets[record.fields.ident][record.fields.exer] = []
                                                list_budgets[record.fields.ident][record.fields.exer].rd001 = 0
                                                list_budgets[record.fields.ident][record.fields.exer].rd001_tmp = 0
                                            }
                                        }
                                        
                                        record.fields.compte = record.fields.compte.toString();
                                        record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100;
                                        record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100;
                                        record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100;
                                        record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100;
                                        record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100;
                                        record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100;
                                        record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100;
                                        record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100;

                                        if (record.fields.compte === '110') {
                                            list_budgets[record.fields.ident][record.fields.exer].compte110 = record.fields.sc;
                                            list_budgets[record.fields.ident][record.fields.exer].rd001_tmp -= (record.fields.sc);
                                        }
                                        if (record.fields.compte === '119') {
                                            list_budgets[record.fields.ident][record.fields.exer].compte119 = record.fields.sd;
                                            list_budgets[record.fields.ident][record.fields.exer].rd001_tmp += (record.fields.sd);
                                        }
                                        
                                        if (record.fields.compte === '1069'
                                        || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                                        || (record.fields.compte.startsWith('1688'))
                                        || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                                        || record.fields.compte.startsWith('3')
                                        || record.fields.compte.startsWith('4')
                                        || record.fields.compte.startsWith('5'))
                                        {
                                            if (!record.fields.compte.startsWith('31')
                                            && !record.fields.compte.startsWith('33')
                                            && !record.fields.compte.startsWith('35')
                                            && !record.fields.compte.startsWith('454')
                                            && !record.fields.compte.startsWith('455')
                                            && !record.fields.compte.startsWith('456')
                                            && !record.fields.compte.startsWith('457')
                                            && !record.fields.compte.startsWith('458')
                                            && !record.fields.compte.startsWith('481'))
                                            {
                                                if ((record.fields.compte.startsWith('39')
                                                || record.fields.compte.startsWith('49')
                                                || record.fields.compte.startsWith('59'))
                                                && record.fields.compte.endsWith('2')) {}
                                                else {
                                                    list_budgets[record.fields.ident][record.fields.exer].rd001 += (record.fields.sd - record.fields.sc);
                                                }
                                            }
                                        }
                                        
                                        if (record.fields.compte.startsWith('1')
                                        || record.fields.compte.startsWith('2')
                                        || record.fields.compte.startsWith('31')
                                        || record.fields.compte.startsWith('33')
                                        || record.fields.compte.startsWith('35'))
                                        {
                                            if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                                list_budgets[record.fields.ident][record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb);
                                            }
                                        }

                                        if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                                        {
                                            if (record.fields.exer > deb)
                                            {
                                                record.fields.nomen = record.fields.nomen.replace('A', '');

                                                datas_tmp[index] = [];
                                                datas_tmp[index]['siret'] = record.fields.ident;
                                                datas_tmp[index]['nomen'] = record.fields.nomen;
                                                datas_tmp[index]['annee'] = record.fields.exer;
                                                datas_tmp[index]['compte'] = record.fields.compte;
                                                if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                                    datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100;
                                                }
                                                if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                                    datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100;
                                                }
                                                datas_tmp[index]['hypothese'] = 'R';

                                                // remplissage du config_budget
                                                if (isin(record.fields.ident, listsiret) === false) {
                                                    listsiret.push([record.fields.ident, [record.fields.nomen]]);
                                                }
                                                else {
                                                    listsiret.forEach(element => {
                                                        if (element[0] === record.fields.ident) {
                                                            if (element[1].includes(record.fields.nomen) === false) {
                                                                element[1].push(record.fields.nomen);
                                                            }
                                                        }
                                                    });
                                                }
                                                if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                                    if (!count_sc[fin][record.fields.ident]) {
                                                        count_sc[fin][record.fields.ident] = 0;
                                                    }
                                                    count_sc[fin][record.fields.ident] += record.fields.sc;
                                                }
                                                index++
                                            }
                                        }
                                    }
                                    else {
                                        console.log("WIP maquettes comptables...", record.fields.nomen)
                                    }
                                });
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        }
                        /******* Protocole si c'est un EPCI ou Syndicat ********/
                        else if (type === "GFP" || type === "SYN")
                        {
                            if (type === "GFP" && fin > 2018)
                            {
                                // Requette pour recuperer les dernieres années sur data.economie.gouv pour un GFP
                                var url = ""
                                if (type === "GFP") {
                                    url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-groupements-a-fiscalite-propre-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.siren='+siren+'&refine.exer='+fin
                                }
                                else if (type === "SYN") {
                                    url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-syndicats-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.siren='+siren+'&refine.exer='+fin
                                }
                                await axios.get(url)
                                .then(async function (result) {
                                    await result.data.records.forEach(record =>
                                    {
                                        // Creation de la liste des budgets
                                        if (siretexist(record.fields.ident, budgets_tmp) === false)
                                        {
                                            if (record.fields.cbudg == "1" || record.fields.budget == "BP")
                                            {
                                                budgets_tmp[j] = {
                                                    'id_organisme': id_organisme,
                                                    'siret': record.fields.ident,
                                                    'nom': record.fields.lbudg,
                                                    'budget_annexe': 0
                                                }
                                            }
                                            else
                                            {
                                                budgets_tmp[j] = {
                                                    'id_organisme': id_organisme,
                                                    'siret': record.fields.ident,
                                                    'nom': record.fields.lbudg,
                                                    'budget_annexe': 1
                                                }
                                            }
                                            j++;
                                        }
                                        // Creation de la liste des datas_brut
                                        if (record.fields.nomen === "M57" || record.fields.nomen === "M14" || record.fields.nomen === "M57A" || record.fields.nomen === "M14A" || record.fields.nomen === "M4" || record.fields.nomen === "M41" || record.fields.nomen === "M43" || record.fields.nomen === "M49")
                                        {
                                            if (list_budgets[record.fields.ident] === undefined || (list_budgets[record.fields.ident] !== undefined && list_budgets[record.fields.ident][record.fields.exer] === undefined)) {
                                                if (list_budgets[record.fields.ident] === undefined) {
                                                    list_budgets[record.fields.ident] = {}
                                                }
                                                if (list_budgets[record.fields.ident][record.fields.exer] === undefined)
                                                {
                                                    list_budgets[record.fields.ident][record.fields.exer] = []
                                                    list_budgets[record.fields.ident][record.fields.exer].rd001 = 0
                                                    list_budgets[record.fields.ident][record.fields.exer].rd001_tmp = 0
                                                }
                                            }

                                            record.fields.compte = record.fields.compte.toString();
                                            record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100
                                            record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100
                                            record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100
                                            record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100
                                            record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100
                                            record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100
                                            record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100
                                            record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100
    
                                            if (record.fields.compte === '110') {
                                                list_budgets[record.fields.ident][record.fields.exer].compte110 = record.fields.sc;
                                                list_budgets[record.fields.ident][record.fields.exer].rd001_tmp -= (record.fields.sc)
                                            }
                                            if (record.fields.compte === '119') {
                                                list_budgets[record.fields.ident][record.fields.exer].compte119 = record.fields.sd;
                                                list_budgets[record.fields.ident][record.fields.exer].rd001_tmp += (record.fields.sd)
                                            }
    
                                            if (record.fields.compte === '1069'
                                            || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                                            || (record.fields.compte.startsWith('1688'))
                                            || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                                            || record.fields.compte.startsWith('3')
                                            || record.fields.compte.startsWith('4')
                                            || record.fields.compte.startsWith('5'))
                                            {
                                                if (!record.fields.compte.startsWith('31')
                                                && !record.fields.compte.startsWith('33')
                                                && !record.fields.compte.startsWith('35')
                                                && !record.fields.compte.startsWith('454')
                                                && !record.fields.compte.startsWith('455')
                                                && !record.fields.compte.startsWith('456')
                                                && !record.fields.compte.startsWith('457')
                                                && !record.fields.compte.startsWith('458')
                                                && !record.fields.compte.startsWith('481'))
                                                {
                                                    if ((record.fields.compte.startsWith('39')
                                                    || record.fields.compte.startsWith('49')
                                                    || record.fields.compte.startsWith('59'))
                                                    && record.fields.compte.endsWith('2')) {}
                                                    else {
                                                        list_budgets[record.fields.ident][record.fields.exer].rd001 += (record.fields.sd - record.fields.sc)
                                                    }
                                                }
                                            }
                                            
                                            if (record.fields.compte.startsWith('1')
                                            || record.fields.compte.startsWith('2')
                                            || record.fields.compte.startsWith('31')
                                            || record.fields.compte.startsWith('33')
                                            || record.fields.compte.startsWith('35'))
                                            {
                                                if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                                    list_budgets[record.fields.ident][record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb)
                                                }
                                            }
    
                                            if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                                            {
                                                if (record.fields.exer > deb)
                                                {
                                                    record.fields.nomen = record.fields.nomen.replace('A', '');
                                                    datas_tmp[index] = []
                                                    datas_tmp[index]['siret'] = record.fields.ident
                                                    datas_tmp[index]['nomen'] = record.fields.nomen
                                                    datas_tmp[index]['annee'] = record.fields.exer
                                                    datas_tmp[index]['compte'] = record.fields.compte
                                                    if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                                        datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100
                                                    }
                                                    if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                                        datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100
                                                    }
                                                    datas_tmp[index]['hypothese'] = 'R'
                                                    
                                                    // remplissage du config_budget
                                                    if (isin(record.fields.ident, listsiret) === false) {
                                                        listsiret.push([record.fields.ident, [record.fields.nomen]])
                                                    }
                                                    else {
                                                        listsiret.forEach(element => {
                                                            if (element[0] === record.fields.ident) {
                                                                if (element[1].includes(record.fields.nomen) === false) {
                                                                    element[1].push(record.fields.nomen)
                                                                }
                                                            }
                                                        });
                                                    }
                                                    if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                                        if (!count_sc[fin][record.fields.ident]) {
                                                            count_sc[fin][record.fields.ident] = 0;
                                                        }
                                                        count_sc[fin][record.fields.ident] += record.fields.sc
                                                    }
                                                    index++
                                                }
                                            }
                                        }
                                        else {
                                            console.log("WIP maquettes comptables...", record.fields.nomen)
                                        }
                                    });
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            }
                            else if ((type === "GFP" && fin >= 2013 && fin <= 2018) || type == "SYN")
                            {
                                /* Requette pour lires les infos depuis les fichiers */
                                var params = {}
                                if (type === "GFP") {
                                    params = {
                                        'annee': fin,
                                        'siren': siren,
                                        'GFP': true
                                    }
                                }
                                else if (type === "SYN") {
                                    params = {
                                        'annee': fin,
                                        'siren': siren,
                                        'SYN': true
                                    }
                                }
                                await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                                .then(async (result) => {
                                    if (result.data.status !== 0)
                                    {
                                        await result.data.forEach(async record =>
                                        {
                                            /* Creation de la liste des budgets */
                                            if (siretexist(record.ident, budgets_tmp) === false)
                                            {
                                                if (record.cbudg === "1" || record.budget === "BP")
                                                {
                                                    budgets_tmp[j] = {
                                                        'id_organisme': id_organisme,
                                                        'siret': record.ident,
                                                        'nom': record.lbudg,
                                                        'budget_annexe': 0
                                                    }
                                                }
                                                else
                                                {
                                                    budgets_tmp[j] = {
                                                        'id_organisme': id_organisme,
                                                        'siret': record.ident,
                                                        'nom': record.lbudg,
                                                        'budget_annexe': 1
                                                    }
                                                }
                                                j++;
                                            }
                                            // Creation de la liste des datas_brut
                                            if (record.nomen === "M57" || record.nomen === "M14" || record.nomen === "M57A" || record.nomen === "M14A" || record.nomen === "M4" || record.nomen === "M41" || record.nomen === "M43" || record.nomen === "M49")
                                            {
                                                if (list_budgets[record.ident] === undefined || (list_budgets[record.ident] !== undefined && list_budgets[record.ident][record.exer] === undefined)) {
                                                    if (list_budgets[record.ident] === undefined) {
                                                        list_budgets[record.ident] = {}
                                                    }
                                                    if (list_budgets[record.ident][record.exer] === undefined)
                                                    {
                                                        list_budgets[record.ident][record.exer] = []
                                                        list_budgets[record.ident][record.exer].rd001 = 0
                                                        list_budgets[record.ident][record.exer].rd001_tmp = 0
                                                    }
                                                }
                                                
                                                record.compte = record.compte.toString();
                                                record.onbcre = Math.round(parseFloat(record.onbcre.replace(",", ".")) * 100) / 100
                                                record.onbdeb = Math.round(parseFloat(record.onbdeb.replace(",", ".")) * 100) / 100
                                                record.sc = Math.round(parseFloat(record.sc.replace(",", ".")) * 100) / 100
                                                record.sd = Math.round(parseFloat(record.sd.replace(",", ".")) * 100) / 100
                                                record.obnetcre = Math.round(parseFloat(record.obnetcre.replace(",", ".")) * 100) / 100
                                                record.obnetdeb = Math.round(parseFloat(record.obnetdeb.replace(",", ".")) * 100) / 100
                                                record.oobcre = Math.round(parseFloat(record.oobcre.replace(",", ".")) * 100) / 100
                                                record.oobdeb = Math.round(parseFloat(record.oobdeb.replace(",", ".")) * 100) / 100
                                        
                                                if (record.compte === '110') {
                                                    list_budgets[record.ident][record.exer].compte110 = record.sc;
                                                    list_budgets[record.ident][record.exer].rd001_tmp -= (record.sc)
                                                }
                                                if (record.compte === '119') {
                                                    list_budgets[record.ident][record.exer].compte119 = record.sd;
                                                    list_budgets[record.ident][record.exer].rd001_tmp += (record.sd)
                                                }

                                                if (record.compte === '1069'
                                                || (record.compte.startsWith('15') && record.compte.endsWith('1'))
                                                || (record.compte.startsWith('1688'))
                                                || (record.compte.startsWith('29') && record.compte.endsWith('1'))
                                                || record.compte.startsWith('3')
                                                || record.compte.startsWith('4')
                                                || record.compte.startsWith('5'))
                                                {
                                                    if (!record.compte.startsWith('31')
                                                    && !record.compte.startsWith('33')
                                                    && !record.compte.startsWith('35')
                                                    && !record.compte.startsWith('454')
                                                    && !record.compte.startsWith('455')
                                                    && !record.compte.startsWith('456')
                                                    && !record.compte.startsWith('457')
                                                    && !record.compte.startsWith('458')
                                                    && !record.compte.startsWith('481'))
                                                    {
                                                        if ((record.compte.startsWith('39')
                                                        || record.compte.startsWith('49')
                                                        || record.compte.startsWith('59'))
                                                        && record.compte.endsWith('2')) {}
                                                        else {
                                                            list_budgets[record.ident][record.exer].rd001 += (record.sd - record.sc)
                                                        }
                                                    }
                                                }
                                                
                                                if (record.compte.startsWith('1')
                                                || record.compte.startsWith('2')
                                                || record.compte.startsWith('31')
                                                || record.compte.startsWith('33')
                                                || record.compte.startsWith('35'))
                                                {
                                                    if (record.compte !== '1069' && !record.compte.startsWith('15') && !record.compte.startsWith('1688')) {
                                                        list_budgets[record.ident][record.exer].rd001_tmp += (record.onbcre - record.onbdeb)
                                                    }
                                                }
        
                                                if (((record.obnetcre - record.oobcre) != 0 || (record.obnetdeb - record.oobdeb) != 0))
                                                {
                                                    if (record.exer > 2013)
                                                    {
                                                        record.nomen = record.nomen.replace('A', '');
                                                        datas_tmp[index] = []
                                                        datas_tmp[index]['siret'] = record.ident
                                                        datas_tmp[index]['nomen'] = record.nomen
                                                        datas_tmp[index]['annee'] = record.exer
                                                        datas_tmp[index]['compte'] = record.compte
                                                        if ((record.obnetcre - record.oobcre) != 0) {
                                                            datas_tmp[index]['recette'] = Math.round((record.obnetcre - record.oobcre) * 100) / 100
                                                        }
                                                        if ((record.obnetdeb - record.oobdeb) != 0) {
                                                            datas_tmp[index]['depense'] = Math.round((record.obnetdeb - record.oobdeb) * 100) / 100
                                                        }
                                                        datas_tmp[index]['hypothese'] = 'R'
                                                        // remplissage du config_budget
                                                        if (isin(record.ident, listsiret) === false) {
                                                            listsiret.push([record.ident, [record.nomen]])
                                                        }
                                                        else {
                                                            listsiret.forEach(element => {
                                                                if (element[0] === record.ident) {
                                                                    if (element[1].includes(record.nomen) === false) {
                                                                        element[1].push(record.nomen)
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        if (record.compte === '1641' || record.compte === '1643' || record.compte.startsWith('163')) {
                                                            if (!count_sc[fin][record.ident]) {
                                                                count_sc[fin][record.ident] = 0;
                                                            }
                                                            count_sc[fin][record.ident] += record.sc
                                                        }
                                                        index++
                                                    }
                                                }
                                            }
                                            else {
                                                console.log("WIP maquettes comptables...", record.nomen)
                                            }
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            }
                        }
                        
                        fin--;
                    }
                    

                    for(const siret in list_budgets) {
                        
                        /* parcour les annees de 2014 a aujourd hui */
                        for(const annee_tmp in list_budgets[siret])
                        {
                            console.log(annee_tmp);
                            if (list_budgets[siret][(parseInt(annee_tmp)+1)+''] !== undefined)
                            {
                                var rd001 = list_budgets[siret][annee_tmp]['rd001'] + list_budgets[siret][(parseInt(annee_tmp)+1)+'']['rd001_tmp'];
                                rd001 = Math.round(rd001 * 100) / 100;
                                if (rd001 != 0)
                                {
                                    datas_tmp[index] = []
                                    datas_tmp[index]['siret'] = siret
                                    datas_tmp[index]['annee'] = (parseInt(annee_tmp)+1)+''
                                    if (rd001 > 0) {
                                        datas_tmp[index]['matricule'] = '2014M57R001'
                                        datas_tmp[index]['recette'] = rd001
                                    }
                                    else if (rd001 < 0) {
                                        datas_tmp[index]['matricule'] = '2014M57D001'
                                        datas_tmp[index]['depense'] = rd001 * -1
                                    }
                                    datas_tmp[index]['hypothese'] = 'R'
                                    index++;
                                    datas_tmp[index] = []
                                    datas_tmp[index]['siret'] = siret
                                    datas_tmp[index]['annee'] = (parseInt(annee_tmp)+1)+''
                                    datas_tmp[index]['matricule'] = '2014M57R002'
                                    datas_tmp[index]['recette'] = list_budgets[siret][(parseInt(annee_tmp)+1)+'']['compte110'];
                                    index++;
                                    datas_tmp[index] = []
                                    datas_tmp[index]['siret'] = siret
                                    datas_tmp[index]['annee'] = (parseInt(annee_tmp)+1)+''
                                    datas_tmp[index]['matricule'] = '2014M57D002'
                                    datas_tmp[index]['depense'] = list_budgets[siret][(parseInt(annee_tmp)+1)+'']['compte119'];
                                    index++;
                                    // datas_tmp[index] = []
                                    // datas_tmp[index]['siret'] = siret
                                    // datas_tmp[index]['annee'] = (parseInt(annee_tmp)+1)+''
                                    // datas_tmp[index]['matricule'] = '2014M5728'
                                    // datas_tmp[index]['recette'] = c28[siret][(parseInt(annee_tmp)+1)+''][0];
                                    // datas_tmp[index]['depense'] = c28[siret][(parseInt(annee_tmp)+1)+''][1];
                                    // index++;
                                    // datas_tmp[index] = []
                                    // datas_tmp[index]['siret'] = siret
                                    // datas_tmp[index]['annee'] = (parseInt(annee_tmp)+1)+''
                                    // datas_tmp[index]['matricule'] = '2014M5729'
                                    // datas_tmp[index]['recette'] = c29[siret][(parseInt(annee_tmp)+1)+''][0];
                                    // datas_tmp[index]['depense'] = c29[siret][(parseInt(annee_tmp)+1)+''][1];
                                    // index++;
                                }
                            }
                        }
                    }
                    

                    /* Creations des budgets */
                    if (budgets_tmp.length != 0)
                    {

                        /* Création des budgets dans la BDD */
                        const params = {
                            'feed_budgets': true,
                            'budget_list': budgets_tmp
                        };
                        await axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify(params))
                        .then(async function (result)
                        {
                            if (result.data['status'] === 1)
                            {
                                NotificationManager.success(result.data['status_message'], '', 2000);
                                
                                await axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify({'getBudgets': true, 'id_organisme': id_organisme}))
                                .then(async (ret) => {
                                    await ret.data.forEach(async (budget) => {
                                        var start = parseInt(res.data.retro_an_deb)
                                        var end = parseInt(res.data.retro_an_fin)
                                        while (start <= end)
                                        {
                                            for (var an in count_sc) {
                                                if (parseInt(an) === parseInt(start)) {
                                                    var dette = count_sc[an][budget.siret]
                                                    var nom_banque = 'Dette Financière'
                                                    var rang = 1

                                                    await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify({'add': true, 'id_budget': budget.id, 'nom': nom_banque, 'rang': rang, 'dette': dette, 'annee': start}))
                                                }
                                            }
                                            
                                            if (type === "GFP") {
                                                await feedRei(budget.id, start, 'R', type, siren)
                                            }
                                            else if (type === "COM" && code_geo !== '') {
                                                await feedRei(budget.id, start, 'R', type, code_geo)
                                            }
                                            start++;
                                        }
                                        return
                                    });
                                })
                                // Feed config_budget
                                await axios.post('https://app-slfinance.fr/api/config_budget.php', qs.stringify({'listsiret': listsiret}))
                                .then(function (result) {
                                    if (result.data['status'] === 1) {
                                        NotificationManager.success(result.data['status_message'], '', 2000);
                                    }
                                    else {
                                        NotificationManager.warning(result.data['status_message'], '', 2000);
                                    }
                                })

                                // Creations des donnees brutes
                                if (datas_tmp.length != 0)
                                {
                                    var tmpi = 0
                                    var tab_tmp = {}
                                    while(tmpi <= datas_tmp.length / 100)
                                    {
                                        tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 100), 100 + (tmpi * 100))
                                        const params = {
                                            'feed_data_brut': true,
                                            'id_organisme': id_organisme,
                                            'list_datas': tab_tmp[tmpi]
                                        };
                                        
                                        /* Insertion des datas_brut */
                                        await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                                        .then(async function (result) {
                                            if (result.data['status'] === 1) {
                                                NotificationManager.success(result.data['status_message'], '', 2000);
                                            }
                                            else {
                                                NotificationManager.warning(result.data['status_message'], '', 2000);
                                            }
                                        })
                                        tmpi++;
                                    }
                                    
                                    // creation des lignes data_restitution
                                    await axios.post('https://app-slfinance.fr/api/datas_restitution.php', qs.stringify({'feed_restitution': true, 'id_organisme': id_organisme, 'liste_annees': liste_annees}))
                                    .then(async function (result) {
                                        if (result.data['status'] === 1) {
                                            NotificationManager.success(result.data['status_message'], '', 2000);
                                        }
                                        else {
                                            NotificationManager.warning(result.data['status_message'], '', 2000);
                                        }
                                    })
                                   
                                    // Refresh de la page
                                    NotificationManager.success('Données brutes créées', '', 700);
                                    setTimeout(() => window.location = window.location.href, 900);
                                }
                                else {
                                    NotificationManager.warning('Pas de données brutes trouvées', '', 2000);
                                    setSpinner(false)
                                }
                            }
                            else {
                                NotificationManager.warning(result.data['status_message']+"\nError\n", '', 2000);
                                setSpinner(false)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }
                    else {
                        NotificationManager.warning('Pas de budgets trouvés', '', 2000);
                        setModalVisible(true)
                        setSpinner(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.includes('Error: Network Error')) {
                        NotificationManager.warning('API INSEE DOWN', '', 2000);
                        setTimeout(() => window.location = window.location.href, 2500);
                    }
                })
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = async data => {
        if (data.nom && data.siren)
        {
            if (data.siren.length === 9)
            {
                var type = "";
                if (data.type === "") {
                    type = "1"
                }
                else {
                    type = data.type
                }
                setSpinner(true);

                // Creation de l'organisme dans la table organismes
                const params = {
                    "createOrganisme": true,
                    "nom": escapeHtml(data.nom),
                    "siren": escapeHtml(data.siren),
                    "code_geo": escapeHtml(data.code_geo),
                    "id_organisme_type": escapeHtml(type)
                }
                await axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
                .then(async (res) => {
                    if (res.data['status'] === 1) {
                        await axios.get('https://app-slfinance.fr/api/type_organismes.php?id='+type)
                        .then(async (ret) => {
                            if (ret.data.length != 0) {
                                NotificationManager.success('Organisme créé', '', 2000);
                                await createbudgets_datas(data.siren, ret.data[0].id_url, data.code_geo);
                            }
                            else {
                                NotificationManager.warning(ret.data['status_message'], '', 2000);
                                console.log("id_url not found.")
                            }
                        })
                    }
                    else {
                        NotificationManager.warning(res.data['status_message'], '', 700);
                        setTimeout(() => window.location = window.location.href, 900);
                    }
                })
            }
            else {
                NotificationManager.warning('Le siren doit contenir 9 chiffres', '', 2000);
            }
        }
        else {
            NotificationManager.warning("Les champs 'Nom', 'Siren' sont obligatoires", '', 2000);
        }
    };

	return (
        <div style={{backgroundColor: 'white'}}>
            <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1}}>
                <NotificationContainer />
                <p>Créer un organisme :</p>
                <form style={{textAlign: 'center'}} onSubmit={handleSubmit(onSubmit)}>
                    <div style={{margin: 10}}>
                        <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nom' {...register("nom")} /></div>
                        <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Siren' {...register("siren")} /></div>
                        <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Code INSEE (Com)' {...register("code_geo")} /></div>
                        <div style={{margin: 10}}>
                            <select className='shadow-md' style={{width: 250, padding: 5}} {...register("type")}>
                                {type_organismes.map((type) =>
                                <option key={type['id']} value={type['id']}>{type['nom']}</option>
                                )}
                            </select>
                        </div>
                        {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 100}} placeholder='Date début' {...register("deb")} /></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 100}} placeholder='Date fin' {...register("fin")} /></div>
                        </div> */}


                        <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                                <Add width="12" />
                                <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Créer" />
                            </div>
                        </div>
                    </div>
                </form>
                <ModalCreateBudget modalVisible={modalVisible} setModalVisible={setModalVisible} organisme={organisme} />
            </div>
            <Modal isOpen={spinner} style={customStyles} ariaHideApp={false}>
                <BounceLoader color={purple} />
            </Modal>
        </div>
	);
}

const customStyles = {
    content: {
        flex: 1,
        border: '0px solid rgba(0, 0, 0, 0)',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.01)'
    },
};

export default CreateOrganisme;
