import React from 'react';
import LogoSLF from '../Img/logo_SLF.png';
import { rescueHtml } from '../components/Tools.js';


const Page2Sommaire = (props) =>
{
    const blue = '#069BD3'

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%'}}>
                <div style={{height: '25%'}}></div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, fontSize: 14, marginRight: 15, textAlign: 'right'}}>
                        {props.affRetro?
                        <div>
                            {(props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49')?
                            <p style={{marginTop: 10}}>Exploitation</p>
                            :
                            <p style={{marginTop: 10}}>Fonctionnement</p>
                            }
                            <p style={{marginTop: 10}}>Investissement</p>
                            {(props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49')?
                            <p style={{marginTop: 10}}>Fiscalité</p>
                            :null}
                            <p style={{marginTop: 10}}>Dette</p>
                        </div>
                        :null}

                        {props.affFP?
                        <p style={{marginTop: 10}}>Fiches projets</p>
                        :null}

                        {props.affProsp?
                        <p style={{marginTop: 10}}>Projection financière</p>
                        :null}
                    </div>

                    <div style={{flex: 1, fontSize: 14, marginLeft: 15, textAlign: 'left', color: blue, fontFamily: 'Times-Italic'}}>
                        {props.affRetro?
                        <div>
                            <p style={{marginTop: 10}} >Page n°3</p>
                            <p style={{marginTop: 10}} >Page n°11</p>
                            {props.user.budget_annexe == '0'?
                            <p style={{marginTop: 10}} >Page n°15</p>
                            :null}
                            <p style={{marginTop: 10}} >Page n°23</p>
                        </div>
                        :null}

                        {props.affFP?
                        <p style={{marginTop: 10}} >Page n°{!props.affRetro?3:28}</p>
                        :null}

                        {props.affProsp?
                        <p style={{marginTop: 10}}>Page n°
                        {!props.affRetro && !props.affFP? 3:null}
                        {props.affRetro && !props.affFP?28:null}
                        {!props.affRetro && props.affFP?(3+2+props.NBFP+(props.affH1?1:0)+(props.affH2?1:0)+(props.affH3?1:0)):null}
                        {props.affRetro && props.affFP?(3+27+props.NBFP+(props.affH1?1:0)+(props.affH2?1:0)+(props.affH3?1:0)):null}
                        </p>
                        :null}
                        
                    </div>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
}
  
  export default Page2Sommaire;