import React from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";

const Partenairepage = (props) =>
{
	return (
        <div>
            <Access needConnection={true} type_user={[4]}>
                <Navbar user={props.user} />
                <div style={{textAlign: 'center'}}>
                    Partenaire Page
                </div>
            </Access>
        </div>
	);
}

export default Partenairepage;
