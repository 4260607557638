import React, { useState } from 'react';
import Modal from 'react-modal';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Bin } from '../Img/icon-bin.svg';
import { addSpace } from "./Tools";
import Dialog from "./Dialog";


const customStyles = {
    content: {
        top: '35%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
};


const AddEmpruntManuel = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    const red = '#E33636'

    const [montant, setMontant] = useState(props.montant);
    const [txi, setTxi] = useState(props.txi);
    const [txa, setTxa] = useState(props.txa);
    const [annee, setAnnee] = useState(props.annee);
    const [duree, setDuree] = useState(props.duree);
    const [dureeDiff, setDureeDiff] = useState(props.dureeDiff);
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            suppress();
        }
    }

    function closeModal() {
        props.setModalVisible(false);
    }

    async function validation()
    {
        if (montant !== 0 && annee > 0 && duree > 0 && dureeDiff >= 0 && dureeDiff < duree) {
            const params = {
                'createEmprunt': true,
                'id_budget': props.user.id_budget,
                'hypothese': props.hypothese,
                'montant': montant,
                'txi': txi,
                'txa': txa,
                'annee': annee,
                'duree': duree,
                'dureeDiff': dureeDiff
            }
            axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
            .then(async (res) => {
                console.log(res.data)
                NotificationManager.success('Emprunt créé', '', 700);
                setTimeout(() => window.location = window.location.href, 800);
            })
            .catch((err) => {console.log(err)})

        }
        else {
            if (montant <= 0) {
                NotificationManager.warning('Montant invalide', '', 5000);
            }
            if (annee <= 0) {
                NotificationManager.warning('Année de souscription invalide', '', 5000);
            }
            if (dureeDiff < 0 || dureeDiff >= duree) {
                NotificationManager.warning('La durée du différé d\'amortissement doit être comprise entre 0 et la durée de l\'emprunt', '', 5000);
            }
        }
    }

    async function update()
    {
        if (parseFloat(montant) !== 0 && parseInt(annee) > 0 && parseInt(duree) > 0 && parseInt(dureeDiff) >= 0 && parseInt(dureeDiff) < parseInt(duree)) {
            const params = {
                'update': true,
                'id': props.id,
                'id_budget': props.user.id_budget,
                'montant': montant,
                'txi': txi,
                'txa': txa,
                'annee': annee,
                'duree': duree,
                'hypothese': props.hypothese,
                'dureeDiff': dureeDiff
            }
            axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
            .then(async (res) => {
                console.log(res.data)
                NotificationManager.success('Emprunt modifié', '', 700);
                setTimeout(() => window.location = window.location.href, 800);
            })
            .catch((err) => {console.log(err)})

        }
        else {
            if (montant !== 0) {
                NotificationManager.warning('Montant invalide', '', 5000);
            }
            if (annee <= 0) {
                NotificationManager.warning('Année de souscription invalide', '', 5000);
            }
            if (dureeDiff < 0 || dureeDiff >= duree) {
                NotificationManager.warning('La durée du différé d\'amortissement doit être comprise entre 0 et la durée de l\'emprunt', '', 5000);
            }
        }
    }

    async function suppress()
    {
        const params = {
            'suppress': true,
            'id_budget': props.user.id_budget,
            'hypothese': props.hypothese,
            'annee': annee,
            'id': props.id
        }
        
        axios.post("https://app-slfinance.fr/api/emprunts.php", qs.stringify(params))
        .then(async (res) => {
            NotificationManager.success('Emprunt supprimé', '', 300);
            setTimeout(() => window.location = window.location.href, 500);
        })
        .catch((err) => {console.log(err)})
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

        <Dialog
            setModalVisible={setDialogModalVisible}
            modalVisible={dialogModalVisible}
            callbackDialog={callbackDialog}
            message={"Confirmez la suppression :"}
        />
            <div>
                
                <p style={{color: purple, fontWeight: 'bold'}}>{props.create?'Création d\'emprunt':'Modification d\'emprunt'}</p>

                <div style={{display: 'flex', marginTop: 25}}>

                    {/* Montant */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Montant</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={addSpace(montant)+'€'}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseFloat(e.target.value.replace(',', '.'))
                                    if (val !== 0) {
                                        await setMontant(val);
                                    }
                                    else {
                                        await setMontant(0);
                                    }
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                    {/* Taux d'interet */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Taux d'intérêts</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={txi+"%"}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseFloat(e.target.value.replace(',', '.'))
                                    await setTxi(val);
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                    {/* Taux d'amortissement */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Taux d'amortissement</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={txa+"%"}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseFloat(e.target.value.replace(',', '.'))
                                    await setTxa(val);
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                </div>


                <div style={{display: 'flex', marginTop: 10}}>

                    {/* Année de souscription */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Année de souscription</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={annee}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseInt(e.target.value.replace(',', '.'))
                                    if (val >= 0) {
                                        await setAnnee(val);
                                    }
                                    else {
                                        await setAnnee('');
                                    }
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                    {/* Durée */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Durée</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={duree === 1?duree+' an':duree+' ans'}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseInt(e.target.value.replace(',', '.'))
                                    if (val >= 1) {
                                        await setDuree(val);
                                        if (val <= dureeDiff) {
                                            await setDureeDiff(0)
                                        }
                                    }
                                    else {
                                        await setDuree(1);
                                    }
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                    {/* Durée du différé d'amortissement */}
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', margin: 5, justifyContent: 'right'}}>
                        <p>Durée du différé d'amortissement</p>
                        <input
                            style={{marginLeft: 5, width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={dureeDiff <= 1?dureeDiff+' an':dureeDiff+' ans'}
                            type="float"
                            onBlur={async(e) => {
                                if (e.target.value !== '')
                                {
                                    const val = parseInt(e.target.value.replace(',', '.'))
                                    if (val >= 0 && val <= (duree - 1)) {
                                        await setDureeDiff(val);
                                    }
                                    else {
                                        NotificationManager.warning("La durée du différé d'amortissement doit être comprise entre 0 et "+duree+" ans (durée de l'emprunt)", '', 5000);
                                        await setDureeDiff(0);
                                    }
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>

                </div>

                <div style={{display: 'flex', marginTop: 25}}>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        {props.create === true?
                        <div>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={async() => {
                                    await validation();
                                }}
                            >
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Valider</p>
                            </button>
                        </div>
                        :
                        <div style={{display: 'flex'}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{marginLeft: 5, backgroundColor: red, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={() => {
                                    setDialogModalVisible(true)
                                }}
                            >
                                <Bin width="12"/>
                                <p style={{marginLeft: 5}}>Supprimer</p>
                            </button>

                            <button
                                className='shadow-md rounded-md'
                                style={{marginLeft: 5, backgroundColor: green, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={async() => {
                                    await update();
                                }}
                            >
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Modifier</p>
                            </button>
                        </div>
                        }
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default AddEmpruntManuel;