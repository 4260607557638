import React, { useState } from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { addSpace } from '../../components/Tools';
import { ReactComponent as Check } from '../../Img/icon-check.svg';
import { ReactComponent as Search } from '../../Img/icon-search.svg';

const AddlineSaisie = (props) =>
{
    const [lines, setLines] = useState([])
    const [line, setLine] = useState([])
    const [aff, setaff] = useState(false)
    const [objet, setObjet] = useState([])
    const heightObject = window.innerHeight/1.9

    const green = '#59B44A'
    const purple = '#6571FE'
    const blueTx = '#DEF3FF'
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    
    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    const onSubmit = async data => {
        setLines([])
        setLine([])
        if (data.nomen != '' || data.compte != '' || data.nom != '') {
            const params = {
                'getlines': true,
                'nomen': data.nomen.toUpperCase(),
                'compte': data.compte,
                'nom': data.nom,
                'rang': 'positif',
                'fctinv': props.fctinv
            }
            await axios.post('https://app-slfinance.fr/api/maquette_comptable.php', qs.stringify(params))
            .then(async (result) => {
                setLines(result.data)
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            setLines([])
            NotificationManager.warning('Champ(s) manquant(s)', '', 2000);
        }
    };


    async function updateObjetLine(an, montant, taux)
    {
        var tmp = objet
        var j = 1
        while(j < tmp[0].length)
        {
            if (tmp[0][j] && tmp[0][j][0] >= an)
            {
                if (taux === 'taux')
                {
                    if (tmp[0][j][1][0] === '' || tmp[0][j][1][0] === '0' || tmp[0][j][1][0] === 0) {
                        tmp[0][j][1][1] = 0
                        tmp[0][j][1][0] = 0
                    }
                    if (tmp[0][j+1] !== undefined)
                    {
                        var montant_tmp = (parseFloat(tmp[0][j][1][0]) + (parseFloat(tmp[0][j][1][0]) * montant / 100))
                        if ((Math.round(montant_tmp * 100) / 100) === 0) {
                            tmp[0][j+1][1][0] = 0
                        }
                        else {
                            tmp[0][j+1][1][0] = Math.round(montant_tmp * 100) / 100
                        }
                        tmp[0][j][1][1] = Math.round(montant * 100) / 100
                        montant = Math.round(tmp[0][j+1][1][1] * 100) / 100
                    }
                    if (tmp[0][j][1][1] === '-100') {
                        if (tmp[0][j+1]) {
                            await updateObjetLine(tmp[0][j+1][0], tmp[0][j+1][1][0], '')
                            return
                        }
                    }
                }
                else {
                    if (montant === ' ' || montant === '0' || montant === 0 || montant === '') {
                        // Calcule du nouveau pourcentage
                        if (j > 1 && tmp[0][j-1][1][0] !== '') {
                            tmp[0][j-1][1][1] = '-100'
                        }
                        while (tmp[0][j])
                        {
                            tmp[0][j][1][1] = 0
                            tmp[0][j][1][0] = 0
                            j++;
                        }
                        return
                    }
                    tmp[0][j][1][0] = Math.round(montant * 100) / 100
                    if (tmp[0][j+1]) {
                        montant = Math.round(tmp[0][j+1][1][0] * 100) / 100
                    }
                    // Calcule du nouveau pourcentage
                    if (j>1) {
                        tmp[0][j-1][1][1] = Math.round((((tmp[0][j][1][0] - tmp[0][j-1][1][0]) / tmp[0][j-1][1][0]) * 100) * 100) / 100
                        if (tmp[0][j-1][1][1] === Infinity) {
                            tmp[0][j-1][1][1] = 0
                        }
                    }
                    await updateObjetLine(tmp[0][j][0], tmp[0][j][1][1], 'taux')
                    return;
                }
            }
            j++;
        }
    }


    function validation(refresh = true)
    {
        const params = {
            'updateGrilleSaisieProsp': true,
            'fctinv': props.fctinv,
            'recdep': props.recdep,
            'hypothese': props.hypothese,
            'id_budget': props.id_budget,
            'grilleSaisie': objet
        }
        
        axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify(params))
        .then(async (res) => {
            if (refresh === true) {
                NotificationManager.success("Grille sauvegardée", '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            }
        })
        .catch((err) => {console.log(err)})
    }

    
	return (
        <Modal
        isOpen={props.modalVisible}
        style={{borderWidth: '0px solid black', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginRight: 1}}
        ariaHideApp={false}
        >
            <NotificationContainer />
            <button style={{textAlign: 'right', backgroundColor: 'red', borderWidth: '0px solid black', color: 'white', paddingInline: 5, borderRadius: 4}} onClick={() => {
                props.setModalVisible(false)
                // window.location = window.location.href
            }}>X</button>

            <div style={{display: 'flex', flex: 1, textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: 20}}>
                <div style={{flex: 1}}>
                    <p>Ajouter une ligne :</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{margin: 20}}>
                            <div style={{margin: 10}}></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Num compte' {...register("compte")} /></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nomenclature ex: M57' {...register("nomen")} /></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nom' {...register("nom")} /></div>


                            <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <div style={{display: 'flex', color: 'white', backgroundColor: purple, borderRadius: 5, paddingLeft: 10}}>
                                    <Search width="12" />
                                    <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Rechercher" />
                                </div>
                            </div>

                        </div>
                    </form>

                    {lines.length !== 0 && line.length === 0?
                    <div style={{flex: 1, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                            <div className='shadow-2xl' style={{flex: 2, borderRadius: 5, overflowY: 'scroll', height: '500px'}}>
                                {lines.map((line, i) =>
                                <div key={i} style={{display: 'flex'}}>
                                    <button
                                    className='shadow md:shadow-lg"'
                                    style={{flex: 1, margin: 5, textAlign: 'left'}}
                                    onClick={async () => {
                                        setLine(line)
                                        var tmp = []
                                        tmp[0] = []
                                        tmp[0][0] = [line[1], line[4], line[5], line[9], line[10]]
                                        var i = 1
                                        props.annees.forEach(annee => {
                                            tmp[0][i] = [annee, ['', '0']]
                                            i++;
                                        });
                                        await setObjet(tmp)
                                        await setaff(!aff)
                                    }}>
                                        <div>[{line[4]}] - {line[5]}</div>
                                    </button>
                                </div>
                                )}
                            </div>
                        <div style={{flex: 1}}></div>
                    </div>
                    :null
                    }
                    
                    
                    {line.length !== 0?
                    <div>
                        {/* Tableau Ligne à ajouter */}
                        <div className='table-auto shadow-md rounded-md' style={{flex: 1, margin: 20, backgroundColor: 'white'}}>
                            
                            {/* Entete Compte / Années */}
                            <div style={{flex: 1, display: 'flex', backgroundColor: blue, color: 'white'}}>
                                <div style={{flex: 3, textAlign: 'center', minWidth: 150}}>
                                    <p style={{fontSize: 18, margin: 10}}>Compte</p>
                                </div>
                                {props.annees.map((annee, j) =>
                                    <div key={j} style={{flex: 1, display: 'flex', fontSize: 15, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50}}>
                                        {j != 0?
                                        <p style={{flex: 1, fontSize: 12, margin: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Evol %</p>
                                        :null}
                                        <p style={{flex: 2, fontSize: 18, margin: 10}}>{annee}</p>
                                    </div>
                                )}
                            </div>

                            {/* Ligne */}
                            <div style={{flex: 1, maxHeight: heightObject}}>

                                <div style={{display: 'flex', flex: 1, minHeight: 35}}>
                                    
                                    {/* Numero de compte et intitulé */}
                                    <div style={{display: 'flex', flex: 3, fontSize: 14, minWidth: 150}}>
                                        <div style={{display: 'flex', margin: 5}}>
                                            <p style={{color: 'red'}}>{line[4] != ''?line[4]:'-'}</p>
                                            <p style={{flex: 1, marginLeft: 5, color: 'black', fontSize: 16}}>{line[5]}</p>
                                        </div>
                                    </div>

                                    {objet[0].map((annee, k) =>
                                    k >= 1?
                                    <div key={k} style={{flex: 1, display: 'flex', fontSize: 12, borderLeft: '1px solid '+smoothGrey, minWidth: 50}}>
                                        
                                        {/* input pour les taux */}
                                        {k >= 2?
                                        <input
                                        type="float"
                                        style={{flex: 1, width: 2, border: '0px solid black', textAlign: 'center', backgroundColor: blueTx}}
                                        onBlur={async (e) => {
                                            e.target.value = e.target.value.replace(',', '.')
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                e.target.value = parseFloat(e.target.value)
                                                await updateObjetLine(objet[0][k-1][0], e.target.value, 'taux')
                                            }
                                            await setaff(!aff)
                                            e.target.value = ''
                                        }}
                                        placeholder={addSpace(Math.round(objet[0][k-1][1][1] * 100) / 100)+'%'}
                                        />
                                        :null}

                                        {/* input pour les montants */}
                                        <input
                                        type="float"
                                        style={{flex: 1.8, width: 2, border: '0px solid black', textAlign: 'center'}}
                                        onBlur={async (e) => {
                                            e.target.value = e.target.value.replace(',', '.')
                                            if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                                e.target.value = parseFloat(e.target.value)
                                                await updateObjetLine(annee[0], parseFloat(Math.round(e.target.value * 100) / 100), '')
                                            }
                                            e.target.value = ''
                                            await setaff(!aff)
                                        }}
                                        placeholder={addSpace(Math.round(annee[1][0] * 100) / 100)}
                                        />

                                    </div>
                                    :null
                                    )}
                                </div>
                            </div>
                            
                        </div>


                        <button
                            className='shadow-md rounded-md'
                            style={{margin: 20, backgroundColor: green, color: 'white', fontSize: 14, justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                            onClick={async () => {
                                validation()
                            }}
                        >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Valider</p>
                            </div>
                        </button>

                    </div>
                    :null
                    }
                </div>
            </div>
        </Modal>
	);
}

export default AddlineSaisie;
