import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanEvolutionTauxTH(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(() => {
        var filename = "Répartition des ressources fiscales par nature";

        var annee = props.annees[props.annees.length-1];
        const colors = ['#16a085', '#2980b9', '#8e44ad', '#2c3e50', '#c0392b', '#e67e22', '#95a5a6', '#f1c40f']

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                type: 'bar',
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: [annee]
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return addSpace(Math.round(value*100)/100, true) + "%";
                    }
                },
            },
            dataLabels: {
                formatter: (val) => {
                    return addSpace(val, true)+"%";
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4
                },
            },
            fill: {
                opacity: 1
            }
        }
        setOptions(option_tmp)

        axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
            'PanierRessourcesFiscales': true,
            'id_budget': props.user.id_budget,
            'annee': annee,
            'hypothese': 'R',
        }))
        .then(async (res) => {
            var serie = [];
            await res.data.forEach(async (element, i) => {
                await serie.push({
                    name: [element[0]],
                    data: [element[1]+'%'],
                    color: colors[i],
                    type: 'column',
                })
            })
            await setSeries(serie);
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:400}
                type="bar"
            />:null}
		</div>
	)
}

export default BilanEvolutionTauxTH;
