import React, { useState } from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Check } from '../../Img/icon-check.svg';
import { ReactComponent as Search } from '../../Img/icon-search.svg';

const AddlineSaisie = (props) =>
{
    const [lines, setLines] = useState([])
    const [line, setLine] = useState([])
    const heightObject = window.innerHeight/1.9

    const green = '#59B44A'
    const purple = '#6571FE'
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'

    const addline = async data2 => {
        var tab = []
        var i = 0
        for(const cel in data2) {
            if (cel.split('-').length === 2) {
                if (data2[cel] != "" && parseFloat(data2[cel])) {
                    tab[i] = []
                    var j = 0
                    cel.split('-').forEach(async (element) => {
                        tab[i][j] = element
                        j++;
                    });
                    tab[i][j++] = props.recdep
                    tab[i][j++] = props.id_budget
                    tab[i][j++] = props.hypothese
                    tab[i][j++] = parseFloat(data2[cel])
                    i++;
                }
            }
        }
        const params = {
            'setGrilleSaisie': true,
            'datas': tab
        }
        await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
        .then(async (result) => {
            if (result.data['status'] === 1) {
                NotificationManager.success("Ligne ajoutée", '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            }
            else {
                NotificationManager.warning("Insertion de la ligne non aboutie", '', 2000);
            }
        })
        .catch((err) => {
            console.log(err)
        })
    };
    
    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    const onSubmit = async data => {
        setLines([])
        setLine([])
        if (data.nomen != '' || data.compte != '' || data.nom != '') {
            const params = {
                'getlines': true,
                'nomen': data.nomen.toUpperCase(),
                'compte': data.compte,
                'nom': data.nom,
                'rang': 'positif',
                'fctinv': props.fctinv
            }
            
            await axios.post('https://app-slfinance.fr/api/maquette_comptable.php', qs.stringify(params))
            .then(async (result) => {
                console.log(result.data)
                setLines(result.data)
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            setLines([])
            NotificationManager.warning('Champ(s) manquant(s)', '', 2000);
        }
    };

    
	return (
        <Modal
        isOpen={props.modalVisible}
        style={{borderWidth: '0px solid black', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginRight: 1}}
        ariaHideApp={false}
        >
            <NotificationContainer />
            <button style={{textAlign: 'right', backgroundColor: 'red', borderWidth: '0px solid black', color: 'white', paddingInline: 5, borderRadius: 4}} onClick={() => {
                props.setModalVisible(false)
                // window.location = window.location.href
            }}>X</button>

            <div style={{display: 'flex', flex: 1, textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: 20}}>
                <div style={{flex: 1}}>
                    <p>Ajouter une ligne :</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{margin: 20}}>
                            <div style={{margin: 10}}></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Num compte' {...register("compte")} /></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nomenclature ex: M57' {...register("nomen")} /></div>
                            <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nom' {...register("nom")} /></div>
                            
                            <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <div style={{display: 'flex', color: 'white', backgroundColor: purple, borderRadius: 5, paddingLeft: 10}}>
                                    <Search width="12" />
                                    <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Rechercher" />
                                </div>
                            </div>
                            
                        </div>
                    </form>


                    {lines.length !== 0 && line.length === 0?
                    <div style={{flex: 1, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                            <div style={{flex: 2, border: '1px solid black', borderRadius: 5, overflowY: 'scroll', height: '500px'}}>
                                {lines.map((line, i) =>
                                <div key={i} style={{textAlign: 'left', margin: 10}}>
                                    <button className='shadow md:shadow-lg"' key={i} onClick={() => {setLine(line)}}>[{line[4]}] - {line[5]}</button>
                                </div>
                                )}
                            </div>
                        <div style={{flex: 1}}></div>
                    </div>
                    :null}


                    {line.length !== 0?
                    <div style={{flex: 1}}>
                        <form onSubmit={handleSubmit(addline)}>
                            
                            {/* Tableau Ligne à ajouter */}
                            <div className='table-auto shadow-md rounded-md' style={{flex: 1, margin: 20, backgroundColor: 'white'}}>
                                
                                {/* Entete Compte / Années */}
                                <div style={{flex: 1, display: 'flex', backgroundColor: blue, color: 'white'}}>
                                    <div style={{flex: 3, textAlign: 'center', minWidth: 150}}>
                                        <p style={{fontSize: 18, margin: 10}}>Compte</p>
                                    </div>
                                    {props.annees.map((annee, j) =>
                                        <div key={j} style={{flex: 1, fontSize: 15, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50}}>
                                            <p style={{fontSize: 18, margin: 10}}>{annee}</p>
                                        </div>
                                    )}
                                </div>

                                {/* Ligne */}
                                <div style={{flex: 1, maxHeight: heightObject}}>

                                    <div style={{display: 'flex', flex: 1, minHeight: 35}}>
                                        
                                        {/* Numero de compte et intitulé */}
                                        <div style={{display: 'flex', flex: 3, fontSize: 14, minWidth: 150}}>
                                            <div style={{display: 'flex', margin: 5}}>
                                                <p style={{color: 'red'}}>{line[4] != ''?line[4]:'-'}</p>
                                                <p style={{flex: 1, marginLeft: 5, color: 'black', fontSize: 16}}>{line[5]}</p>
                                            </div>
                                        </div>

                                        {props.annees.map((annee, j) =>
                                        <div key={j} style={{flex: 1, display: 'flex', fontSize: 12, borderLeft: '1px solid '+smoothGrey, minWidth: 50}}>
                                            <input
                                            key={j}
                                            type="float"
                                            style={{flex: 1, width: 50, border: '0px solid black', textAlign: 'center'}}
                                            {...register(line[1]+'-'+annee)}
                                            />
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                                    <Check width="12" />
                                    <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Valider" />
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    :null
                    }
                </div>
            </div>
        </Modal>
	);
}

export default AddlineSaisie;
