import React from 'react';
import Error404 from './Img/Look404.gif';
import Navbar from "./components/Navbar";

const NotFound = (props) =>
{
	return (
        <div style={{backgroundColor: '#ffffff'}}>
            <Navbar user={props.user} />
            <div
            style={{
                backgroundImage: `url(${Error404})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100vw',
                height: '80vh'
            }}>
            </div>
        </div>
	);
}

export default NotFound;
