import React, { useEffect, useState, useRef } from 'react';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import { getyears } from "./components/Tools";
import BilanRepartRecettes from './Graph/Bilan/BilanRepartRecettes';
import BilanRepartDepenses from './Graph/Bilan/BilanRepartDepenses';
import BilanDynRecDepGestion from './Graph/Bilan/BilanDynRecDepGestion';
import BilanDynRecGestion from './Graph/Bilan/BilanDynRecGestion';
import BilanDynDepGestion from './Graph/Bilan/BilanDynDepGestion';
import BilanImpotsTaxesSurRecFct from './Graph/Bilan/BilanImpotsTaxesSurRecFct';
import BilanDynDotations from './Graph/Bilan/BilanDynDotations';
import BilanAcDscRecues from './Graph/Bilan/BilanAcDscRecues';
import BilanAcDscVersees from './Graph/Bilan/BilanAcDscVersees';
import BilanRevenusImmeubles from './Graph/Bilan/BilanRevenusImmeubles';
import BilanDepPersoSurRecFct from './Graph/Bilan/BilanDepPersoSurRecFct';
import BilanChargesGenSurRecFct from './Graph/Bilan/BilanChargesGenSurRecFct';
import BilanEvolDepEnergie from './Graph/Bilan/BilanEvolDepEnergie';
import BilanEpargneSurRecettes from './Graph/Bilan/BilanEpargneSurRecettes';
import BilanEpargneBruteSurRecettes from './Graph/Bilan/BilanEpargneBruteSurRecettes';
import BilanEpargneNetteSurRecettes from './Graph/Bilan/BilanEpargneNetteSurRecettes';
import BilanDepensesEquipement from './Graph/Bilan/BilanDepensesEquipement';
import BilanTauxSubvMoy from './Graph/Bilan/BilanTauxSubvMoy';
import BilanDepInvSurDepTot from './Graph/Bilan/BilanDepInvSurDepTot';
import BilanResultatCloture from './Graph/Bilan/BilanResultatCloture';
import BilanEquivalentResultClo from './Graph/Bilan/BilanEquivalentResultClo';
import BilanEvoRAR from './Graph/Bilan/BilanEvoRAR';
import BilanCouvertureRAR from './Graph/Bilan/BilanCouvertureRAR';
import BilanDynBasesTH from './Graph/Bilan/BilanDynBasesTH';
import BilanDynBasesTFB from './Graph/Bilan/BilanDynBasesTFB';
import BilanDynBasesFNB from './Graph/Bilan/BilanDynBasesFNB';
import BilanDynBasesCFE from './Graph/Bilan/BilanDynBasesCFE';
import BilanDynBasesTHRevalForf from './Graph/Bilan/BilanDynBasesTHRevalForf';
import BilanDynBasesTFBRevalForf from './Graph/Bilan/BilanDynBasesTFBRevalForf';
import BilanDynBasesFNBRevalForf from './Graph/Bilan/BilanDynBasesFNBRevalForf';
import BilanDynBasesCFERevalForf from './Graph/Bilan/BilanDynBasesCFERevalForf';
import BilanDynBasesTHhabitant from './Graph/Bilan/BilanDynBasesTHhabitant';
import BilanDynBasesTFBhabitant from './Graph/Bilan/BilanDynBasesTFBhabitant';
import BilanDynBasesFNBhabitant from './Graph/Bilan/BilanDynBasesFNBhabitant';
import BilanDynBasesCFEhabitant from './Graph/Bilan/BilanDynBasesCFEhabitant';
import BilanTauxTH from './Graph/Bilan/BilanTauxTH';
import BilanTauxFB from './Graph/Bilan/BilanTauxFB';
import BilanTauxFNB from './Graph/Bilan/BilanTauxFNB';
import BilanTauxCFE from './Graph/Bilan/BilanTauxCFE';
import BilanEvolutionTauxTH from './Graph/Bilan/BilanEvolutionTauxTH';
import BilanEvolutionTauxFB from './Graph/Bilan/BilanEvolutionTauxFB';
import BilanEvolutionTauxFNB from './Graph/Bilan/BilanEvolutionTauxFNB';
import BilanEvolutionTauxCFE from './Graph/Bilan/BilanEvolutionTauxCFE';
import BilanPanierRessourcesFiscales from './Graph/Bilan/BilanPanierRessourcesFiscales';
import BilanExtDetteCapital from './Graph/Bilan/BilanExtDetteCapital';
import BilanExtDetteAmortInterets from './Graph/Bilan/BilanExtDetteAmortInterets';
import BilanEncoursDettePreteur from './Graph/Bilan/BilanEncoursDettePreteur';
import BilanEvolNetteEncoursDette from './Graph/Bilan/BilanEvolNetteEncoursDette';
import BilanInteretsDette from './Graph/Bilan/BilanInteretsDette';
import BilanInteretsSurEquiFct from './Graph/Bilan/BilanInteretsSurEquiFct';
import BilanPoidsRembSurEquiFct from './Graph/Bilan/BilanPoidsRembSurEquiFct';
import BilanTauxEndettement from './Graph/Bilan/BilanTauxEndettement';
import BilanRythmeRembDette from './Graph/Bilan/BilanRythmeRembDette';
import BilanCapaciteDesendettement from './Graph/Bilan/BilanCapaciteDesendettement';
import BilanHypEvolRecDepGestion from './Graph/Bilan/BilanHypEvolRecDepGestion';
import BilanDepensesPersonnel from './Graph/Bilan/BilanDepensesPersonnel';
import BilanDynContribDirDot from './Graph/Bilan/BilanDynContribDirDot';
import BilanEvolRecettesGestion from './Graph/Bilan/BilanEvolRecettesGestion';
import BilanEvolImpotsTaxes from './Graph/Bilan/BilanEvolImpotsTaxes';
import BilanEvolDotGlobaleFct from './Graph/Bilan/BilanEvolDotGlobaleFct';
import BilanEvolDepensesGestion from './Graph/Bilan/BilanEvolDepensesGestion';
import BilanEvolDepensesPerso from './Graph/Bilan/BilanEvolDepensesPerso';
import BilanEvolChargesCaracGeneral from './Graph/Bilan/BilanEvolChargesCaracGeneral';
import BilanPartDepPersoSurDepGestion from './Graph/Bilan/BilanPartDepPersoSurDepGestion';
import BilanPartContribSurRecettesGestion from './Graph/Bilan/BilanPartContribSurRecettesGestion';
import BilanMontantDotationFct from './Graph/Bilan/BilanMontantDotationFct';
import BilanTauxEpargneGestion from './Graph/Bilan/BilanTauxEpargneGestion';
import BilanTauxEpargneBrute from './Graph/Bilan/BilanTauxEpargneBrute';
import BilanTauxEpargneNette from './Graph/Bilan/BilanTauxEpargneNette';
import BilanTauxEndettementProsp from './Graph/Bilan/BilanTauxEndettementProsp';
import BilanEncoursDette31_12 from './Graph/Bilan/BilanEncoursDette31_12';
import BilanEncoursDetteEvolNette from './Graph/Bilan/BilanEncoursDetteEvolNette';
import BilanCapaciteDesendettementProsp from './Graph/Bilan/BilanCapaciteDesendettementProsp';
import BilanEpargneBrute from './Graph/Bilan/BilanEpargneBrute';
import BilanEpargneNette from './Graph/Bilan/BilanEpargneNette';
import BilanDepensesInvProsp from './Graph/Bilan/BilanDepensesInvProsp';
import BilanResultatCloture31_12 from './Graph/Bilan/BilanResultatCloture31_12';


const AccueilOrganisme = (props) =>
{
    const purple = '#6571FE'

    const [yearsRetro, setYearsRetro] = useState(undefined)
    const [yearsProsp, setYearsProsp] = useState(undefined)
    
    const [aff, setAff] = useState(false)
    const [affH1, setAffH1] = useState(true)
    const [affH2, setAffH2] = useState(true)
    const [affH3, setAffH3] = useState(true)
    const [affRetro, setAffRetro] = useState(true)
    const [affProsp, setAffProsp] = useState(false)


    async function mount() {
        /* Récupération des années de retro et prosp */
        await getyears(props.user.id_budget, 'R')
        .then(async(years_retro) => {
            await setYearsRetro(years_retro)
        })

        await getyears(props.user.id_budget, 'P')
        .then(async(years_prosp) => {
            await setYearsProsp(years_prosp)
        })
    }


    useEffect(() => {
        if (!props.user) {
            props.getUser()
        }
        else {
            mount();
        }
        
    }, [props.user])

        
        return (
            <Access needConnection={true} type_user={[2, 3]}>
            <Navbar />

            <div style={{flex: 1, margin: 20, display: 'flex'}}>
                <div style={{flex: 1, display: 'flex'}}>
                    <p style={{marginLeft: 5, color: purple, fontWeight: 'bold'}}>Graphiques</p>
                </div>
                <div style={{flex: 1}}>
                    {props.user?
                    <p style={{flex: 1, textAlign: 'center'}}>{props.user.nom_budget}</p>
                    :null}
                </div>
                <div style={{flex: 1}}></div>
            </div>
            
            <div style={{flex: 1}}>
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button
                    className='shadow-md rounded-md'
                    style={
                        affRetro?
                        {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                        :
                        {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                    }
                    onClick={async() => {
                        if (!affRetro) {
                            await setAffRetro(true)
                            await setAffProsp(false)
                        }
                        await setAff(!aff);
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p style={{margin: 5}}>Retrospective</p>
                        </div>
                    </button>

                    <button
                    className='shadow-md rounded-md'
                    style={
                        affProsp?
                        {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                        :
                        {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                    }
                    onClick={async() => {
                        if (!affProsp) {
                            await setAffRetro(false)
                            await setAffProsp(true)
                        }
                        await setAff(!aff);
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p style={{margin: 5}}>Prospective</p>
                        </div>
                    </button>
                </div>
                <div style={{flex: 1}}></div>
            </div>

            {props.user?
            <div>
                {yearsRetro && yearsProsp?
                <div>
                    {affRetro?
                    <div>
                        <div style={{display: 'flex', marginTop: 25}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Répartition des recettes et des dépenses de fonctionnement</p>
                            </div>
                        </div>

                        <div style={{flex: 1, display: 'flex', margin: 25}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanRepartRecettes user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanRepartDepenses user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                        </div>





                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Dynamique générale du fonctionnement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 10}}>
                            <div style={{flex: 1, margin: 10, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDynRecDepGestion user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 10, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDynRecGestion user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 10, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDynDepGestion user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Structure des recettes de fonctionnement</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 25}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanImpotsTaxesSurRecFct user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynDotations user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>


                            <div style={{display: 'flex', margin: 25}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanAcDscRecues user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanRevenusImmeubles user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>





                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Structure des dépenses de fonctionnement</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDepPersoSurRecFct user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanChargesGenSurRecFct user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>


                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanAcDscVersees user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvolDepEnergie user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Taux d'épargne</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEpargneSurRecettes user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEpargneBruteSurRecettes user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEpargneNetteSurRecettes user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Dynamique d'investissement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDepensesEquipement user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanTauxSubvMoy user={props.user} download={true} annees={yearsRetro}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDepInvSurDepTot user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Résultat de clôture et restes à réaliser</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanResultatCloture user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEquivalentResultClo user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvoRAR user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanCouvertureRAR user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Niveau des bases d'imposition</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTH user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTFB user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesFNB user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesCFE user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div> 




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Évolution des bases d'imposition</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTHRevalForf user={props.user} download={true} annees={yearsRetro} revalForf={true}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTFBRevalForf user={props.user} download={true} annees={yearsRetro} revalForf={true}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesFNBRevalForf user={props.user} download={true} annees={yearsRetro} revalForf={true}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesCFERevalForf user={props.user} download={true} annees={yearsRetro} revalForf={true}/>
                                </div>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Bases d'imposition par habitant</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTHhabitant user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesTFBhabitant user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesFNBhabitant user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDynBasesCFEhabitant user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Niveaux des taux d'imposition</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxTH user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxFB user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxFNB user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxCFE user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>
                    




                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Évolution des taux d'imposition</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvolutionTauxTH user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvolutionTauxFB user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvolutionTauxFNB user={props.user} download={true} annees={yearsRetro}/>
                                </div>

                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEvolutionTauxCFE user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>
                    



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Panier des ressources fiscales</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanPanierRessourcesFiscales user={props.user} download={true} annees={yearsRetro}/>
                            </div>
                        </div>
                    



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Extinction de la dette</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanExtDetteCapital user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanExtDetteAmortInterets user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>
                    



                    <div style={{display: 'flex', marginTop: 100}}>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <p style={{color: purple, fontSize: 20}}>Répartition de l'encours de dette par prêteur</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', margin: 50}}>
                        <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                            <BilanEncoursDettePreteur user={props.user} download={true} annees={yearsRetro}/>
                        </div>
                    </div>
                
                    



                    <div style={{display: 'flex', marginTop: 100}}>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <p style={{color: purple, fontSize: 20}}>Charge de la dette</p>
                        </div>
                    </div>

                    <div style={{display: 'flex', margin: 50}}>
                        <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                            <BilanEvolNetteEncoursDette user={props.user} download={true} annees={yearsRetro}/>
                        </div>
                        
                        <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                            <BilanInteretsDette user={props.user} download={true} annees={yearsRetro}/>
                        </div>
                        
                        <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                            <BilanInteretsSurEquiFct user={props.user} download={true} annees={yearsRetro}/>
                        </div>
                    </div>
                
                    



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Soutenabilité de la dette</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanPoidsRembSurEquiFct user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxEndettement user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanRythmeRembDette user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanCapaciteDesendettement user={props.user} download={true} annees={yearsRetro}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :null}

                    {affProsp?
                    <div>

                        <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH1?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                            }
                            onClick={async() => {
                                await setAffH1(!affH1);
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 1</p>
                                </div>
                            </button>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH2?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                            }
                            onClick={async() => {
                                await setAffH2(!affH2);
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 2</p>
                                </div>
                            </button>

                            <button
                            className='shadow-md rounded-md'
                            style={
                                affH3?
                                {backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                                :
                                {color: purple, fontSize: 14, marginTop: 20, marginRight: 20, padding: 15}
                            }
                            onClick={async() => {
                                await setAffH3(!affH3);
                                await setAff(!aff);
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: 5}}>Hypothèse 3</p>
                                </div>
                            </button>

                        </div>


                        <div style={{display: 'flex', marginTop: 55}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Dynamique générale du fonctionnement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanHypEvolRecDepGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                            
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDepensesPersonnel aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanDynContribDirDot aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                        </div>



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Évolution des recettes de fonctionnement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolRecettesGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                            
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolImpotsTaxes aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolDotGlobaleFct aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                        </div>



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Évolution des dépenses de fonctionnement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolDepensesGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                            
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolDepensesPerso aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanEvolChargesCaracGeneral aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                        </div>



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Indicateurs complémentaires de la section fonctionnement</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanPartDepPersoSurDepGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                            
                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanPartContribSurRecettesGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>

                            <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                <BilanMontantDotationFct aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                        </div>
                



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Taux d'épargne</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', margin: 50}}>
                            <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                                <BilanTauxEpargneGestion aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                            
                            <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                                <BilanTauxEpargneBrute aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>

                            <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                                <BilanTauxEpargneNette aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                            </div>
                        </div>
                



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Endettement projeté</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEncoursDette31_12 aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanTauxEndettementProsp aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEncoursDetteEvolNette aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanCapaciteDesendettementProsp aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                            </div>
                        </div>
                



                        <div style={{display: 'flex', marginTop: 100}}>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <p style={{color: purple, fontSize: 20}}>Financement de l'investissement</p>
                            </div>
                        </div>

                        <div>
                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEpargneBrute aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanEpargneNette aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', margin: 50}}>
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanDepensesInvProsp aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                                
                                <div style={{flex: 1, margin: 5, backgroundColor: 'white'}} className='shadow-md rounded'>
                                    <BilanResultatCloture31_12 aff={aff} setAff={setAff} affH1={affH1} affH2={affH2} affH3={affH3} user={props.user} download={true} annees={yearsProsp}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :null}


                </div>
                :null}
            </div>
            :null
        }
        </Access>
	);
}

export default AccueilOrganisme;
