import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';

const Nn1n2 = (props) =>
{
    const [aff, setAff] = useState(false)
    const [n, setN] = useState('0')
    const [n_1, setN_1] = useState('0')
    const [n_2, setN_2] = useState('0')

    useEffect(() => {
        if (props.budget) {
            setN(props.budget['n'])
            setN_1(props.budget['n-1'])
            setN_2(props.budget['n-2'])
            setAff(!aff)
        }
    }, [props.budget])

    async function validation(data)
    {
        await setN('0')
        await setN_1('0')
        await setN_2('0')
        const params = {
            'updateBudget': true,
            'id_budget': props.budget.id,
            'n': '0',
            'n_1': '0',
            'n_2': '0'
        }
        if (data === 'n') {
            await setN('1')
            params['n'] = '1'
        }
        if (data === 'n-1') {
            await setN_1('1')
            params['n_1'] = '1'
        }
        if (data === 'n-2') {
            await setN_2('1')
            params['n_2'] = '1'
        }

        await axios.post("https://app-slfinance.fr/api/budgets.php", qs.stringify(params))
        .catch((err) => {
            console.log(err)
        })
        await setAff(!aff)
    }

	return (
        <div className='shadow-md' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <div style={{flex: 1, textAlign: 'center', padding: 5}}>
                <p>Modifier l'année de référence pour le calcul FCTVA:</p>
            </div>

            {props.budget?
            <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>

                <div style={{flex: 1, margin: 25}}>
                    <p>n</p>
                    <p style={{flex: 0.1}}>{<input type="checkbox" checked={n === '0'?false:true} onChange={() => validation('n')} />}</p>
                </div>

                <div style={{flex: 1, margin: 25}}>
                    <p>n-1</p>
                    <p style={{flex: 0.1}}>{<input type="checkbox" checked={n_1 === '0'?false:true} onChange={() => validation('n-1')} />}</p>
                </div>

                <div style={{flex: 1, margin: 25}}>
                    <p>n-2</p>
                    <p style={{flex: 0.1}}>{<input type="checkbox" checked={n_2 === '0'?false:true} onChange={() => validation('n-2')}/>}</p>
                </div>

            </div>
            :null}

        </div>
	);
}

export default Nn1n2;
