import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanDynBasesTFBRevalForf(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(() => {
        
        var filename = "Évolution annuelle des bases de taxe sur le foncier bâti";

        var tmp_annees = [];
        var j = 0;
        props.annees.forEach((an, i) => {
            if (i > 0) {
                tmp_annees[j] = an;
                j++;
            }
        });
        

        var ComEpci = null;
        if (props.user.id_organisme_type === '1') {
            ComEpci = 'Com';
        }
        else if (props.user.id_organisme_type === '2') {
            ComEpci = 'Epci';
        }
        else if (props.user.id_organisme_type === '3') {
            ComEpci = 'Com';
        }

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                type: 'line',
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: tmp_annees
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return addSpace(Math.round(value*100)/100, true) + "%";
                    }
                },
            },
            dataLabels: {
                formatter: (val) => {
                    return addSpace(val, true)+"%";
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4
                },
            },
            fill: {
              opacity: 1
            }
        }
        setOptions(option_tmp)

        axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
            'getReiRevalForf': true,
            'ComEpci': ComEpci,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
            'hypothese': 'R',
            'matricule': 'REIBASEFB'
        }))
        .then(async (res) => {
            await setSeries([{
                name: "Foncier bâti",
                data: res.data[0],
                color: '#2c3e50',
                type: 'column',
            },{
                name: "dont revalorisation forfaitaire",
                data: res.data[1],
                color: '#c0392b',
                type: 'line',
            }])
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:400}
                type="line"
            />:null}
		</div>
	)
}

export default BilanDynBasesTFBRevalForf;
