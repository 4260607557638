import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as Logo } from './Img/logo-slfinance2.svg';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { escapeHtml, rescueHtml } from './components/Tools';
import 'react-notifications/lib/notifications.css';
import { ReactComponent as Check } from './Img/icon-check.svg';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as IconOpenEye50 } from './Img/icon-oeil-ouvert-50x50.svg';
import { ReactComponent as IconCloseEye50 } from './Img/icon-oeil-ferme-50x50.svg';

const Register = (props) =>
{
    let history = useHistory();
    const [userid, setUserid] = useState("");
    const [useremail, setUseremail] = useState("");
    // const [viewPassword, setViewPassword] = useState(false)
    // const [viewConfirmPassword, setViewConfirmPassword] = useState(false)
    // const [password, setPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    
    const green = '#59B44A'

    useEffect(() => {
        if (window.location.href.search("token") === -1) {
            NotificationManager.error('Token non valide', '', 700);
            setTimeout(() => history.push('/logout'), 900);
        }
        else
        {
            // Récupération de l'URL
            let url = window.location.href.split('?')
            url = url[1].split('&')
            var result = {};
            
            // Récupération du token dans l'URL
            url.forEach(function(el)
            {
                var param = el.split('=');
                result[param[0]] = param[1];
            });
    
            // Vérification si le token existe
            axios.get("https://app-slfinance.fr/api/users.php?token="+result.token)
            .then((res) => {
                if (!res.data) {
                    NotificationManager.error('Token non valide', '', 700);
                    setTimeout(() => history.push('/logout'), 900);
                }
                else {
                    setUserid(res.data[0]);
                    setUseremail(rescueHtml(res.data[6]));
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        
    }, [])

    
    function updateUser(nom, prenom, email) {
        const url = 'https://app-slfinance.fr/api/connexion.php';
        const params = {
            'updateUser': true,
            'id': userid,
            'nom': escapeHtml(nom.toUpperCase()),
            'prenom': escapeHtml(prenom),
            'email': escapeHtml(email),
            // 'password': password
        };
        axios.post(url, qs.stringify(params))
        .then(function (res) {
            if (res.data['status'] === 1) {
                NotificationManager.success(res.data['status_message'], '', 700);
                setTimeout(() => history.push('/'), 900);
            }
            else {
                NotificationManager.warning(res.data['status_message'], '', 2000);
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    // function validPassword(password) {
    //     var passformat = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$*%^&+=!]).{8,}$/;
    //     if (password.match(passformat)) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

    
    
    // function updatePassword() {
    //     if(nom === "" || prenom === "" || password === "" || confirmPassword === "") {
    //         NotificationManager.warning('Champ(s) manquant(s)', '', 2000);
    //     }
    //     else
    //     {
    //         if (password === confirmPassword)
    //         {
    //             if (validPassword(password)) {
    //                 updateUser(nom, prenom, useremail, password);
    //             }
    //             else {
    //                 NotificationManager.warning("Votre mot de passe doit contenir au minimum 8 caractères avec au moins une minuscule, une majuscule, un chiffre et un caractère spécial ' @#$*%^&+=! '", '', 10000);
    //             }
    //         }
    //         else {
    //             NotificationManager.warning("La confirmation du mot de passe n'est pas bonne", '', 2000);
    //         }
    //     }
    // };

	return (
        <div>
            <NotificationContainer />
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>
                <a href='/'>
                    <Logo width="400" />
                </a>
                <div style={{flex: 1}}></div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>

                <div style={{justifyContent: 'center', minWidth: 450, backgroundColor: 'white'}}>
                    <div className='shadow-md' style={{flex: 1, borderRadius: 3, padding: 5}}>
                        <div style={{textAlign: 'center'}}>
                            <p style={{margin: 5, color: 'grey'}}>{useremail}</p>
                            <div style={{margin: 25, textAlign: 'left'}}>
                                    <p style={{fontSize: 15}}>Nom</p>
                                    <input
                                        style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}}
                                        onChange={async (e) => {
                                            setNom(e.target.value)
                                        }}
                                        // placeholder='Nom'
                                    />
                                </div>
                                <div style={{margin: 25, textAlign: 'left'}}>
                                    <p style={{fontSize: 15}}>Prenom</p>
                                    <input
                                        style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}}
                                        onChange={async (e) => {
                                            setPrenom(e.target.value)
                                        }}
                                        // placeholder='Prenom'
                                    />
                                </div>
                                {/* <div style={{margin: 25, textAlign: 'left'}}>
                                    <p style={{fontSize: 15}}>Mot de passe</p>
                                    <div style={{display: 'flex'}}>
                                        <input
                                            style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}}
                                            // placeholder='Mot de passe'
                                            type={viewPassword?'text':"password"}
                                            onChange={async (e) => {
                                                setPassword(e.target.value)
                                            }}
                                        />

                                        <button
                                        style={{marginLeft: 15, marginTop: 10}}
                                        onClick={() => {setViewPassword(!viewPassword)}}
                                        >
                                            {viewPassword?
                                            <IconOpenEye50 width="20px" />
                                            :
                                            <IconCloseEye50 width="20px" />
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div style={{margin: 25, textAlign: 'left'}}>
                                    <p style={{fontSize: 15}}>Confirmez votre mot de passe</p>
                                    <div style={{display: 'flex'}}>
                                        <input
                                        style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}}
                                        // placeholder='Confirmation'
                                        type={viewConfirmPassword?'text':"password"}
                                        onChange={async (e) => {
                                            setConfirmPassword(e.target.value)
                                        }}
                                        />

                                        <button
                                        style={{marginLeft: 15, marginTop: 10}}
                                        onClick={() => {setViewConfirmPassword(!viewConfirmPassword)}}
                                        >
                                            {viewConfirmPassword?
                                            <IconOpenEye50 width="20px" />
                                            :
                                            <IconCloseEye50 width="20px" />
                                            }
                                        </button>

                                    </div>
                                </div> */}

                                <button style={{margin: 10}}
                                onClick={() => {updateUser(nom, prenom, useremail)}}>
                                    <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                        <Check width="12" />
                                        <p style={{display: 'flex', margin: 7}}>Valider</p>
                                    </div>
                                </button>

                        </div>
                    </div>
                </div>

                <div style={{flex: 1}}></div>
            </div>
        </div>
	);
}

export default Register;
