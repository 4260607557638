import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import { escapeHtml, rescueHtml } from './Tools';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from '../Img/icon-add.svg';

const CreateBudget = () =>
{
    const [organismes, setOrganismes] = useState([]);
    const green = '#59B44A'

    useEffect(() => {
        axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom)
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = data => {
        if (data.id_organisme != '' && data.nom != '' && data.siret != '' && data.nomen != '')
        {
            const params = {
                'feed_budgets': true,
                'budget_list': [{'id_organisme': data.id_organisme, 'nom': escapeHtml(data.nom), 'siret': data.siret, 'budget_annexe': '1', 'nomen': data.nomen.toUpperCase()}]
            };
            axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify(params))
            .then(async function (result) {
                if (result.data['status'] === 1) {
                    NotificationManager.success(result.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.warning(result.data['status_message'], '', 5000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            NotificationManager.warning('Champ manquant', '', 5000);
        }
    };

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <NotificationContainer />
            <p>Créer un budget (Annexe) :</p>
            <form style={{textAlign: 'left', textAlign: 'center'}} onSubmit={handleSubmit(onSubmit)}>
                <div style={{margin: 10}}>
                    <div style={{margin: 10}}>
                        <select className='shadow-md' style={{width: 250, padding: 5}} {...register("id_organisme")}>
                            <option value="">...</option>
                            {
                                organismes.map((organisme) => 
                                <option key={organisme['id']} value={organisme['id']}>{organisme['nom']}</option>
                                )
                            }
                        </select>
                    </div>
                    <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nom' {...register("nom")} /></div>
                    <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Siret' {...register("siret")} /></div>
                    <div style={{margin: 10}}><input className='shadow-md' style={{width: 250}} placeholder='Nomenclature ex: M57' {...register("nomen")} /></div>
                    
                    
                    <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                        <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                            <Add width="12" />
                            <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Créer" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
	);
}

export default CreateBudget;
