import React, { useEffect, useState } from 'react';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import FileUpload from "./components/FileUpload";
import { rescueHtml, escapeHtml } from './components/Tools';
import axios from 'axios';
import qs from 'qs';


const MassiveMailing = (props) =>
{
    const green = '#59B44A'

    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const [expediteurMail, setExpediteurMail] = useState('')
    const [expediteurMailMDP, setExpediteurMailMDP] = useState('')

    useEffect(() => {
        if (props.user === null) {
            props.getUser();
        }
    }, [])

	return (
        <div>
            <Access needConnection={true} type_user={[1]}>
                <NotificationContainer />
                <Navbar user={props.user} />
                    <div style={{display: 'flex', margin: 25}}>
                        <div style={{flex: 1}}></div>
                        <div className='shadow-md' style={{borderRadius: 5, padding: 25}}>
                            <div style={{textAlign: 'center'}}>
                                <p>Expéditeur</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                <div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Email</p>
                                    </div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>MDP Email</p>
                                    </div>
                                </div>

                                <div style={{marginLeft: 20}}>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        style={{borderRadius: 5, padding: 5, width: 300}}
                                        onChange={async (e) => {
                                            setExpediteurMail(e.target.value)
                                        }}
                                        placeholder=''
                                        />
                                    </div>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        type='password'
                                        style={{borderRadius: 5, padding: 5, width: 250}}
                                        onChange={async (e) => {
                                            setExpediteurMailMDP(e.target.value)
                                        }}
                                        placeholder=''
                                        />
                                    </div>
                                </div>
                                <div style={{flex: 1}}></div>
                            </div>
                            
                            <div style={{textAlign: 'center', marginTop: 50}}>
                                <p>Déstinataire(s)</p>
                                <p style={{fontSize: 14}}>(Format CSV: [Mail] [Nom] [Civilité])</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                <div style={{marginTop: 25}}>
                                    {props.user?
                                    <FileUpload user={props.user} save='export'/>
                                    :null}
                                </div>
                                <div style={{flex: 1}}></div>
                            </div>

                            {/* <div style={{marginTop: 25, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                className='shadow-md p-2 rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14}}
                                onClick={() => {
                                    
                                }}>
                                    Valider
                                </button>
                            </div> */}
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
            </Access>
        </div>
	);
}

export default MassiveMailing;
