import React, { useEffect, useState } from 'react';
import { ReactComponent as IconSingleNeutral } from './Img/icon-single-neutral.svg';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Access from "./components/Access";
import Navbar from "./components/Navbar";
import { rescueHtml, escapeHtml } from './components/Tools';
import axios from 'axios';
import qs from 'qs';
// import { ReactComponent as IconLock } from './Img/icon-lock.svg';
// import { ReactComponent as IconOpenEye50 } from './Img/icon-oeil-ouvert-50x50.svg';
// import { ReactComponent as IconCloseEye50 } from './Img/icon-oeil-ferme-50x50.svg';


const Moncompte = (props) =>
{
    const [aff, setAff] = useState(false)
    const [user, setUser] = useState({})
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    // const [oldPassword, setoldPassword] = useState('')
    // const [newPassword, setNewPassword] = useState('')
    // const [confirmNewPassword, setConfirmNewPassword] = useState('')
    // const [viewPassword, setViewPassword] = useState(false)
    
    const green = '#59B44A'
    
    useEffect(() => {
        getUser()
        setAff(!aff)
    }, [])

    function getUser()
    {
        const currentUser = localStorage.getItem("session_token")

        if (currentUser)
        {
            const url = 'https://app-slfinance.fr/api/connexion.php';
			const params = {
				'session_token': currentUser
			};
			axios.post(url, qs.stringify(params))
            .then((res) => {
				const tmp = {
                    'id' : res.data.id,
					'email' : rescueHtml(res.data.email),
					'nom' : rescueHtml(res.data.nom.toUpperCase()),
					'prenom' : rescueHtml(res.data.prenom),
                    'id_type_users': res.data.id_type_users
				}
                setUser(tmp)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    // function validPassword(password) {
    //     var passformat = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$*%^&+=!]).{8,}$/;
    //     if (password.match(passformat)) {
    //         return true;
    //     }
    //     else {
    //         NotificationManager.warning("Votre mot de passe doit contenir au minimum 8 caractères avec au moins une minuscule, une majuscule, un chiffre et un caractère spécial ' @#$*%^&+=! '", '', 10000);
    //         return false;
    //     }
    // }

    // function updatePassword()
    // {
    //     // Update MDP
    //     if (oldPassword != '' && newPassword != '' && confirmNewPassword != '') {
    //         if (newPassword === confirmNewPassword) {
    //             const url = 'https://app-slfinance.fr/api/connexion.php';
    //             const params = {
    //                 'checkPassword': true,
    //                 'id': user.id,
    //                 'password': oldPassword
    //             };
    //             axios.post(url, qs.stringify(params))
    //             .then(function (res) {
    //                 // // Si le mdp actuel est valide et que la confirmation est correct
    //                 if (res.data === true) {
    //                     if (validPassword(newPassword)) {
    //                         axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify({'updateUser': true, 'id': user.id, 'password': newPassword}))
    //                         .then(async (ret) => {
    //                             console.log(ret.data)
    //                             if (ret.data['status'] === 1) {
    //                                 await NotificationManager.success(ret.data['status_message'], '', 700);
    //                                 await setTimeout(() => window.location = window.location.href, 900);
    //                             }
    //                             else {
    //                                 await NotificationManager.warning(ret.data['status_message'], '', 2000);
    //                             }
    //                         })
    //                     }
    //                 }
    //                 else {
    //                     NotificationManager.warning('Ancien mot de passe non valide', '', 2000);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             })
    //         }
    //         else {
    //             NotificationManager.warning('La confirmation du mot de passe n\'est pas bonne', '', 2000);
    //         }


    //     }
    //     else if (oldPassword != '' || newPassword != '' || confirmNewPassword != '') {
    //         NotificationManager.warning('Tous les champs pour le mot de passe ne sont pas remplis', '', 3000);
    //     }
    // }

    function updateUser()
    {
        var params = {}
        // Update Nom
        if (nom && nom !== '') {
            params = {
                'updateUser': true,
                'nom': escapeHtml(nom),
                'id': user.id
            }
            axios.post('https://app-slfinance.fr/api/users.php', qs.stringify(params))
            .then((e) => {
                if (e.data['status'] === 1) {
                    NotificationManager.success(e.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.success(e.data['status_message'], '', 3000);
                }
            })
        }

        // Update Prenom
        if (prenom && prenom !== '') {
            params = {
                'updateUser': true,
                'prenom': escapeHtml(prenom),
                'id': user.id
            }
            axios.post('https://app-slfinance.fr/api/users.php', qs.stringify(params))
            .then((e) => {
                if (e.data['status'] === 1) {
                    NotificationManager.success(e.data['status_message'], '', 700);
                    setTimeout(() => window.location = window.location.href, 900);
                }
                else {
                    NotificationManager.warning(e.data['status_message'], '', 3000);
                }
            })
        }
    }

	return (
        <div>
            <Access needConnection={true} type_user={[0]}>
                <NotificationContainer />
                <Navbar user={props.user} />
                <div style={{flex: 1, display: 'flex', padding: 25}}>

                    <div style={{flex: 1}}></div>

                    {/* -------------- Gestion Nom - Prenom -------------- */}
                    <div style={{display: 'flex', margin: 25}}>
                        <div style={{flex: 1}}></div>
                        <div className='shadow-md' style={{borderRadius: 5, padding: 25}}>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconSingleNeutral width="60px" />
                            </div>
                            
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                <div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Nom</p>
                                    </div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Prénom</p>
                                    </div>
                                </div>

                                <div style={{marginLeft: 20}}>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        style={{borderRadius: 5, padding: 5, width: 200}}
                                        onChange={async (e) => {
                                            setNom(e.target.value)
                                        }}
                                        placeholder={user.nom}
                                        />
                                    </div>
                                    <div style={{marginTop: 10, height: 30}}>
                                        <input
                                        style={{borderRadius: 5, padding: 5, width: 200}}
                                        onChange={async (e) => {
                                            setPrenom(e.target.value)
                                        }}
                                        placeholder={user.prenom}
                                        />
                                    </div>
                                </div>
                                <div style={{flex: 1}}></div>
                            </div>

                            <div style={{marginTop: 25, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                className='shadow-md p-2 rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14}}
                                onClick={() => {
                                    updateUser();
                                }}>
                                    Valider
                                </button>
                            </div>
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>




                    {/* -------------- Gestion MDP -------------- */}

                    {/* <div style={{display: 'flex', margin: 25}}>
                        <div style={{flex: 1}}></div>
                        <div className='shadow-md' style={{borderRadius: 5, padding: 25}}>

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconLock width="60px" />
                            </div>
                            
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}></div>
                                <div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Mot de passe actuel</p>
                                    </div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Nouveau mot de passe</p>
                                    </div>
                                    <div style={{marginTop: 10, padding: 5, height: 30}}>
                                        <p>Confirmation</p>
                                    </div>
                                </div>

                                <div style={{marginLeft: 20}}>
                            <div style={{marginTop: 10, height: 30}}>
                                <input
                                style={{padding: 5, borderRadius: 5, width: 230}}
                                type={viewPassword === true?'text':'password'}
                                onChange={async (e) => {
                                    setoldPassword(e.target.value)
                                }}
                                />
                            </div>
                            <div style={{marginTop: 10, height: 30, display: 'flex'}}>
                                <input
                                style={{padding: 5, borderRadius: 5, width: 230}}
                                type={viewPassword === true?'text':'password'}
                                onChange={async (e) => {
                                    setNewPassword(e.target.value)
                                }}
                                />
                                
                                <button
                                style={{marginLeft: 5}}
                                onClick={() => {setViewPassword(!viewPassword)}}
                                >
                                    {viewPassword?
                                    <IconOpenEye50 width="20px" />
                                    :
                                    <IconCloseEye50 width="20px" />
                                    }
                                </button>
                            </div>
                            <div style={{marginTop: 10, height: 30}}>
                                <input
                                style={{padding: 5, borderRadius: 5, width: 230}}
                                type={viewPassword === true?'text':'password'}
                                onChange={async (e) => {
                                    setConfirmNewPassword(e.target.value)
                                }}
                                />
                            </div>
                                </div>
                                <div style={{flex: 1}}></div>
                            </div>

                            <div style={{marginTop: 25, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                className='shadow-md p-2 rounded-md'
                                style={{backgroundColor: green, color: 'white', fontSize: 14}}
                                onClick={() => {
                                    updatePassword();
                                }}>
                                    Valider
                                </button>
                            </div>
                        </div>
                        <div style={{flex: 1}}></div>
                    </div> */}

                    

                    <div style={{flex: 1}}></div>

                </div>
            </Access>
        </div>
	);
}

export default Moncompte;
