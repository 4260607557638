import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import Modal from 'react-modal';
import BounceLoader from "react-spinners/BounceLoader";
import { useHistory } from 'react-router-dom';
import { rescueHtml, feedRei } from './Tools';
import { ReactComponent as Download } from '../Img/icon-download.svg';
import {NotificationManager} from 'react-notifications';
import Dialog from "./Dialog";

const SiphonnageDataOrg = (props) =>
{
    const purple = '#6571FE';
    const green = '#59B44A';

    let history = useHistory();
    const [organismes, setOrganismes] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [id_budget, setId_budget] = useState('');
    const [siren, setSiren] = useState('');
    const [siret, setSiret] = useState('');
    const [id_organisme, setId_organisme] = useState('');
    const [debut, setDebut] = useState(undefined);
    const [fin, setFin] = useState(undefined);
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            CleanBudget();
        }
    }


    async function mount() {
        await axios.post('https://app-slfinance.fr/api/application.php', qs.stringify({'get': true, 'parametre': 'retro_an_deb'}))
        .then((res) => {
            setDebut(res.data.retro_an_deb);
        })
        await axios.post('https://app-slfinance.fr/api/application.php', qs.stringify({'get': true, 'parametre': 'retro_an_fin'}))
        .then((res) => {
            setFin(res.data.retro_an_fin);
        })

        await axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true, 'ASC': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom),
                    siren: res.data[i].siren,
                    code_geo: res.data[i].code_geo,
                    ComGfp: res.data[i].id_organisme_type === '1'?'COM':'GFP',
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        mount();
    }, [])


    async function siphonnage(vars)
    {
        var count_sc = [];
        var c28 = [];
        var c29 = [];
        var index = 0;
        var tmp = [];
        var start = parseInt(vars.debut)-1;
        var end = parseInt(vars.fin);
        var tmp_end = end;
        var datas_tmp = [];
        var nomen = '';

        // Boucle qui va recuperer les infos des budgets par année.
        while(start <= end)
        {
            count_sc[end] = 0;
            c28[fin] = [0, 0];//[recettes, depenses]
            c29[fin] = [0, 0];//[recettes, depenses]

            /******* Protocole si c'est une Commune ********/
            if (vars.ComGfp === "COM")
            {
                // Recuperation de la liste des budgets (siret, nom, budget annexe)
                await axios.get('https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-communes-en-'+end+'&q=&rows=-1&facet=ident&facet=lbudg&facet=cbudg&refine.ident='+vars.siret)
                .then(async function (result)
                {
                    await result.data.records.forEach(record =>
                    {
                        // Creation de la liste des datas_brut
                        if ( record.fields.ident == vars.siret)
                        {
                            if (nomen === '' || !nomen.includes(record.fields.nomen.replace('A', ''))) {
                                nomen = nomen + '' + record.fields.nomen.replace('A', '') + ';';
                            }
                            if (tmp[record.fields.exer] === undefined)
                            {
                                tmp[record.fields.exer] = [];
                                tmp[record.fields.exer].rd001 = 0;
                                tmp[record.fields.exer].rd001_tmp = 0;
                            }
                            
                            record.fields.compte = record.fields.compte.toString();
                            record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100;
                            record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100;
                            record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100;
                            record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100;
                            record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100;
                            record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100;
                            record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100;
                            record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100;

                            if (record.fields.compte === '110') {
                                tmp[record.fields.exer].compte110 = record.fields.sc;
                                tmp[record.fields.exer].rd001_tmp -= (record.fields.sc);
                            }
                            if (record.fields.compte === '119') {
                                tmp[record.fields.exer].compte119 = record.fields.sd;
                                tmp[record.fields.exer].rd001_tmp += (record.fields.sd);
                            }

                            if (record.fields.compte === '1069'
                            || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                            || (record.fields.compte.startsWith('1688'))
                            || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                            || record.fields.compte.startsWith('3')
                            || record.fields.compte.startsWith('4')
                            || record.fields.compte.startsWith('5'))
                            {
                                if (!record.fields.compte.startsWith('31')
                                && !record.fields.compte.startsWith('33')
                                && !record.fields.compte.startsWith('35')
                                && !record.fields.compte.startsWith('454')
                                && !record.fields.compte.startsWith('455')
                                && !record.fields.compte.startsWith('456')
                                && !record.fields.compte.startsWith('457')
                                && !record.fields.compte.startsWith('458')
                                && !record.fields.compte.startsWith('481'))
                                {
                                    if ((record.fields.compte.startsWith('39')
                                    || record.fields.compte.startsWith('49')
                                    || record.fields.compte.startsWith('59'))
                                    && record.fields.compte.endsWith('2')) {}
                                    else {
                                        tmp[record.fields.exer].rd001 += (record.fields.sd - record.fields.sc);
                                    }
                                }
                            }
                            
                            if (record.fields.compte.startsWith('1')
                            || record.fields.compte.startsWith('2')
                            || record.fields.compte.startsWith('31')
                            || record.fields.compte.startsWith('33')
                            || record.fields.compte.startsWith('35'))
                            {
                                if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                    tmp[record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb);
                                }
                            }

                            if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                            {
                                datas_tmp[index] = []
                                datas_tmp[index]['siret'] = record.fields.ident;
                                datas_tmp[index]['nomen'] = record.fields.nomen.replace('A', '');
                                datas_tmp[index]['annee'] = record.fields.exer;
                                datas_tmp[index]['compte'] = record.fields.compte;
                                if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                    datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100;
                                }
                                if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                    datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100;
                                }
                                datas_tmp[index]['hypothese'] = 'R';

                                if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                    if (!count_sc[end]) {
                                        count_sc[end] = 0;
                                    }
                                    count_sc[end] += record.fields.sc;
                                }
                                index++
                            }
                        }
                    });
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            /***********************************************/


            /******* Protocole si c'est un EPCI/GFP ********/
            if (vars.ComGfp === "GFP" || vars.ComGfp === "SYN")
            {
                if (vars.ComGfp === "GFP" && end > 2018)
                {
                    // Requette pour recuperer les dernieres années sur data.economie.gouv pour un GFP
                    var url = ""
                    if (vars.ComGfp === "GFP") {
                        url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-groupements-a-fiscalite-propre-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.ident='+vars.siret+'&refine.exer='+end
                    }
                    await axios.get(url)
                    .then(async function (result) {
                        await result.data.records.forEach(record =>
                        {
                            // Creation de la liste des datas_brut
                            if (record.fields.ident === vars.siret)
                            {
                                if (nomen === '' || !nomen.includes(record.fields.nomen.replace('A', ''))) {
                                    nomen = nomen + '' + record.fields.nomen.replace('A', '') + ';';
                                }
                                if (tmp[record.fields.exer] === undefined)
                                {
                                    tmp[record.fields.exer] = [];
                                    tmp[record.fields.exer].rd001 = 0;
                                    tmp[record.fields.exer].rd001_tmp = 0;
                                }
                                
                                record.fields.compte = record.fields.compte.toString();
                                record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100;
                                record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100;
                                record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100;
                                record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100;
                                record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100;
                                record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100;
                                record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100;
                                record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100;

                                if (record.fields.compte === '110') {
                                    tmp[record.fields.exer].compte110 = record.fields.sc;
                                }
                                if (record.fields.compte === '119') {
                                    tmp[record.fields.exer].compte119 = record.fields.sd;
                                }

                                if (record.fields.compte === '1069'
                                || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                                || (record.fields.compte.startsWith('1688'))
                                || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                                || record.fields.compte.startsWith('3')
                                || record.fields.compte.startsWith('4')
                                || record.fields.compte.startsWith('5'))
                                {
                                    if (!record.fields.compte.startsWith('31')
                                    && !record.fields.compte.startsWith('33')
                                    && !record.fields.compte.startsWith('35')
                                    && !record.fields.compte.startsWith('454')
                                    && !record.fields.compte.startsWith('455')
                                    && !record.fields.compte.startsWith('456')
                                    && !record.fields.compte.startsWith('457')
                                    && !record.fields.compte.startsWith('458')
                                    && !record.fields.compte.startsWith('481'))
                                    {
                                        if ((record.fields.compte.startsWith('39')
                                        || record.fields.compte.startsWith('49')
                                        || record.fields.compte.startsWith('59'))
                                        && record.fields.compte.endsWith('2')) {}
                                        else {
                                            tmp[record.fields.exer].rd001 += (record.fields.sd - record.fields.sc);
                                        }
                                    }
                                }
                                
                                if (record.fields.compte.startsWith('1')
                                || record.fields.compte.startsWith('2')
                                || record.fields.compte.startsWith('31')
                                || record.fields.compte.startsWith('33')
                                || record.fields.compte.startsWith('35'))
                                {
                                    if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                        tmp[record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb);
                                    }
                                }
                                if (record.fields.compte === "110") {
                                    tmp[record.fields.exer].rd001_tmp -= (record.fields.sc);
                                }
                                else if (record.fields.compte === "119") {
                                    tmp[record.fields.exer].rd001_tmp += (record.fields.sd);
                                }

                                if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                                {
                                    record.fields.nomen = record.fields.nomen.replace('A', '');
                                    datas_tmp[index] = []
                                    datas_tmp[index]['siret'] = record.fields.ident
                                    datas_tmp[index]['nomen'] = record.fields.nomen
                                    datas_tmp[index]['annee'] = record.fields.exer
                                    datas_tmp[index]['compte'] = record.fields.compte
                                    if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                        datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100
                                    }
                                    if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                        datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100
                                    }
                                    datas_tmp[index]['hypothese'] = 'R';

                                    if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                        if (!count_sc[end]) {
                                            count_sc[end] = 0;
                                        }
                                        count_sc[end] += record.fields.sc;
                                    }
                                    index++
                                }
                            }
                        });
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
                else if ((vars.ComGfp === "GFP" && end >= 2013 && end <= 2018) || vars.ComGfp == "SYN")
                {
                    /* Requette pour lires les infos depuis les fichiers */
                    var params = {}
                    if (vars.ComGfp === "GFP") {
                        params = {
                            'annee': end,
                            'siret': vars.siret,
                            'GFP': true
                        }
                    }
                    else if (vars.ComGfp === "SYN") {
                        params = {
                            'annee': end,
                            'siret': vars.siret,
                            'SYN': true
                        }
                    }
                    
                    await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                    .then(async (result) => {
                        if (result.data.status !== 0)
                        {
                            await result.data.forEach(async record =>
                            {
                                // Creation de la liste des datas_brut
                                if (record.ident === vars.siret)
                                {
                                    if (nomen === '' || !nomen.includes(record.nomen.replace('A', ''))) {
                                        nomen = nomen + '' + record.nomen.replace('A', '') + ';';
                                    }
                                    if (tmp[record.exer] === undefined) {
                                        tmp[record.exer] = [];
                                        tmp[record.exer].rd001 = 0;
                                        tmp[record.exer].rd001_tmp = 0;
                                    }
                                    
                                    record.compte = record.compte.toString();
                                    record.onbcre = Math.round(parseFloat(record.onbcre.replace(",", ".")) * 100) / 100;
                                    record.onbdeb = Math.round(parseFloat(record.onbdeb.replace(",", ".")) * 100) / 100;
                                    record.sc = Math.round(parseFloat(record.sc.replace(",", ".")) * 100) / 100;
                                    record.sd = Math.round(parseFloat(record.sd.replace(",", ".")) * 100) / 100;
                                    record.obnetcre = Math.round(parseFloat(record.obnetcre.replace(",", ".")) * 100) / 100;
                                    record.obnetdeb = Math.round(parseFloat(record.obnetdeb.replace(",", ".")) * 100) / 100;
                                    record.oobcre = Math.round(parseFloat(record.oobcre.replace(",", ".")) * 100) / 100;
                                    record.oobdeb = Math.round(parseFloat(record.oobdeb.replace(",", ".")) * 100) / 100;

                                    if (record.compte === '110') {
                                        tmp[record.exer].compte110 = record.sc;
                                    }
                                    if (record.compte === '119') {
                                        tmp[record.exer].compte119 = record.sd;
                                    }

                                    if (record.compte === '1069'
                                    || (record.compte.startsWith('15') && record.compte.endsWith('1'))
                                    || (record.compte.startsWith('1688'))
                                    || (record.compte.startsWith('29') && record.compte.endsWith('1'))
                                    || record.compte.startsWith('3')
                                    || record.compte.startsWith('4')
                                    || record.compte.startsWith('5'))
                                    {
                                        if (!record.compte.startsWith('31')
                                        && !record.compte.startsWith('33')
                                        && !record.compte.startsWith('35')
                                        && !record.compte.startsWith('454')
                                        && !record.compte.startsWith('455')
                                        && !record.compte.startsWith('456')
                                        && !record.compte.startsWith('457')
                                        && !record.compte.startsWith('458')
                                        && !record.compte.startsWith('481'))
                                        {
                                            if ((record.compte.startsWith('39')
                                            || record.compte.startsWith('49')
                                            || record.compte.startsWith('59'))
                                            && record.compte.endsWith('2')) {}
                                            else {
                                                tmp[record.exer].rd001 += (record.sd - record.sc);
                                            }
                                        }
                                    }
                                    
                                    if (record.compte.startsWith('1')
                                    || record.compte.startsWith('2')
                                    || record.compte.startsWith('31')
                                    || record.compte.startsWith('33')
                                    || record.compte.startsWith('35'))
                                    {
                                        if (record.compte !== '1069' && !record.compte.startsWith('15') && !record.compte.startsWith('1688')) {
                                            tmp[record.exer].rd001_tmp += (record.onbcre - record.onbdeb);
                                        }
                                    }
                                    if (record.compte === "110") {
                                        tmp[record.exer].rd001_tmp -= (record.sc);
                                    }
                                    else if (record.compte === "119") {
                                        tmp[record.exer].rd001_tmp += (record.sd);
                                    }

                                    if (((record.obnetcre - record.oobcre) != 0 || (record.obnetdeb - record.oobdeb) != 0))
                                    {
                                        if (record.exer >= 2013)
                                        {
                                            record.nomen = record.nomen.replace('A', '');
                                            datas_tmp[index] = [];
                                            datas_tmp[index]['siret'] = record.ident;
                                            datas_tmp[index]['nomen'] = record.nomen;
                                            datas_tmp[index]['annee'] = record.exer;
                                            datas_tmp[index]['compte'] = record.compte;
                                            if ((record.obnetcre - record.oobcre) != 0) {
                                                datas_tmp[index]['recette'] = Math.round((record.obnetcre - record.oobcre) * 100) / 100;
                                            }
                                            if ((record.obnetdeb - record.oobdeb) != 0) {
                                                datas_tmp[index]['depense'] = Math.round((record.obnetdeb - record.oobdeb) * 100) / 100;
                                            }
                                            datas_tmp[index]['hypothese'] = 'R';
                                            
                                            if (record.compte === '1641' || record.compte === '1643' || record.compte.startsWith('163')) {
                                                if (!count_sc[end]) {
                                                    count_sc[end] = 0;
                                                }
                                                count_sc[end] += record.sc;
                                            }
                                            index++
                                        }
                                    }
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                }
            }
            /***********************************************/


            /* Creation de la banque */
            var dette = count_sc[end];
            var nom_banque = 'Dette Financière';
            var rang = 1;
            await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify({'add': true, 'id_budget': vars.id_budget, 'nom': nom_banque, 'rang': rang, 'dette': dette, 'annee': end}))
            /*************************/

            if (tmp[(end)+''] !== undefined)
            {
                /* Stockage et creation du RD002 */
                datas_tmp[index] = [];
                datas_tmp[index]['siret'] = vars.siret;
                datas_tmp[index]['annee'] = (end)+'';
                datas_tmp[index]['matricule'] = '2014M57R002';
                datas_tmp[index]['recette'] = parseFloat(tmp[(end)+'']['compte110'])?parseFloat(tmp[(end)+'']['compte110']):0;
                index++;

                datas_tmp[index] = [];
                datas_tmp[index]['siret'] = vars.siret;
                datas_tmp[index]['annee'] = (end)+'';
                datas_tmp[index]['matricule'] = '2014M57D002';
                datas_tmp[index]['depense'] = parseFloat(tmp[(end)+'']['compte119'])?parseFloat(tmp[(end)+'']['compte119']):0;
                index++;
            }
            
            /* Creations des donnees brutes */
            if (vars.ComGfp === "COM" && vars.code_geo !== '') {
                await feedRei(vars.id_budget, end, 'R', vars.ComGfp, vars.code_geo);
            }
            if (vars.ComGfp === "GFP") {
                await feedRei(vars.id_budget, end, 'R', vars.ComGfp, vars.siren)
            }

            end--;
        }

        while (start <= tmp_end)
            {
                /* Stockage et creation du RD001 */
                if (tmp[(start-1)+''] != undefined && tmp[(start)+''] != undefined) {
                    var rd001 = tmp[start-1+'']['rd001'] + tmp[(start)+'']['rd001_tmp'];
                    rd001 = Math.round(rd001 * 100) / 100;
                    if (rd001 != 0)
                    {
                        datas_tmp[index] = [];
                        datas_tmp[index]['siret'] = vars.siret;
                        datas_tmp[index]['annee'] = (start)+'';
                        if (rd001 > 0) {
                            datas_tmp[index]['matricule'] = '2014M57R001';
                            datas_tmp[index]['recette'] = rd001;
                        }
                        else if (rd001 < 0) {
                            datas_tmp[index]['matricule'] = '2014M57D001';
                            datas_tmp[index]['depense'] = rd001 * -1;
                        }
                        datas_tmp[index]['hypothese'] = 'R';
                        index++;
                    }
                }
            start++;
        }
        

        /* Creations des donnees brutes */
        if (datas_tmp.length != 0)
        {
            var tmpi = 0
            var tab_tmp = {}
            while(tmpi <= datas_tmp.length / 100)
            {
                tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 100), 100 + (tmpi * 100))
                const params = {
                    'feed_data_brut': true,
                    'id_organisme': vars.id_organisme,
                    'list_datas': tab_tmp[tmpi]
                };
                
                /* Insertion des datas_brut */
                await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                .then(async function (result) {
                    if (result.data['status'] === 1) {
                        NotificationManager.success(result.data['status_message'], '', 2000);
                    }
                    else {
                        NotificationManager.warning(result.data['status_message'], '', 2000);
                    }
                })
                tmpi++;
            }
        }

        /* Vérification si la table config_budget est bien remplie pour ce budget. Si ce n'est pas le cas alors crée le champ nomen->valeur */
        axios.post("https://app-slfinance.fr/api/config_budget.php", qs.stringify({
            'verif_nomen': true,
            'id_budget': vars.id_budget,
            'parametre': 'nomen',
            'valeur': nomen,
        }))
        .catch((err) => {
            console.log(err)
        })

        await setLoading(false);
        await setTimeout(() => window.location = window.location.href, 500);
    }


    async function siphonnage_get_params(params)
    {
        axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify({
            'siphonnage_get_params': true,
            'id_organisme': params.id_organisme,
            'id_budget': params.id_budget,
            'debut': params.debut,
            'fin': params.fin,
        }))
        .then(async (res) => {
            await siphonnage(res.data);
        })
        .catch((err) => {
            console.log(err)
        })
    }


    function CleanBudget()
    {
        var d = parseInt(debut);
        var f = parseInt(fin);
        
        if (id_budget !== '' && siret !== '' && id_organisme !== '' && debut !== '' && fin !== '' && d <= f && d >= 2013)
        {
            setSpinner(true);
            axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify({'clean_budget_pour_siphonnage': true, 'id_budget': id_budget, 'debut': debut, 'fin': fin}))
            .then(async (res) => {
                if (res.data['status'] === 1) {
                    var params = {
                        'id_organisme': id_organisme,
                        'id_budget': id_budget,
                        'siret': siret,
                        'debut': d,
                        'fin': f,
                    };
                    await siphonnage_get_params(params);
                }
                else {
                    NotificationManager.warning(res.data['status_message'], '', 5000);
                }
            })
            .catch((err) => {
                console.log(err)
            })

        }
        else {
            if (debut === '' || fin === '') {
                NotificationManager.warning("Veuillez renseigner tous les champs", '', 5000);
            }
            if (debut !== '' && fin !== '' && f < d) {
                NotificationManager.warning("L'année de fin ne pas être inferieure à l'année de debut", '', 5000);
            }
            if (debut !== '' && fin !== '' && d < 2013) {
                NotificationManager.warning("L'année de debut ne peut être inferieure à 2014", '', 5000);
            }
        }
    }

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Êtes-vous sûr de vouloir écraser les données existantes ?"}
            />

            <Modal isOpen={spinner} style={customStyles} ariaHideApp={false}>
                <BounceLoader color={purple} />
            </Modal>

            <p>Siphonnage Organisme :</p>
            <div style={{margin: 10, textAlign: 'center'}}>
                <select
                style={{padding: 5}}
                    className='shadow-md'
                    value={id_organisme}
                    onChange={async e => {
                        await setId_organisme(e.target.value);

                        await axios.get("https://app-slfinance.fr/api/budgets.php?id_organisme="+e.target.value+'&list=true')
                        .then(async (result) => {
                            var tmp = []
                            var i = 0
                            await result.data.forEach(async budget => {
                                if (budget.budget_annexe === '0') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            
                            await result.data.forEach(async budget => {
                                if(budget.budget_annexe === '1') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            await setId_budget(tmp[0].id_budget)
                            await setSiret(tmp[0].siret)
                            await setBudgets(tmp);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }}
                >
                    <option value="">...</option>
                    {
                        organismes.map(
                        (organisme) =>
                            <option key={organisme['id']} value={organisme['id']} >{organisme['nom']}</option>
                        )
                    }
                </select>

                {id_organisme != '' && budgets.length != 0?
                <div style={{marginTop: 10, textAlign: 'center'}}>
                    <div>
                        <select
                        className='shadow-md'
                        value={id_budget}
                        onChange={async (e) => {
                            await setId_budget(e.target.value);
                        }}>
                            {budgets.map((elem, i) =>
                            <option key={i} value={elem.id_budget}>{elem.nom}</option>
                            )}
                        </select>
                    </div>

                    <div style={{marginTop: 20}}>
                        <p>Années à siphonner :</p>
                    </div>
                    <div style={{display: 'flex'}}>
                        <input className='shadow-md' style={{flex: 1, margin: 5, textAlign: 'center'}} placeholder='Debut' value={debut}
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 9999) {
                                setDebut(e.target.value);
                            }
                        }}
                        />
                        <input className='shadow-md' style={{flex: 1, margin: 5, textAlign: 'center'}} placeholder='Fin' value={fin}
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 9999) {
                                setFin(e.target.value);
                            }
                        }}
                        />
                    </div>


                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <button
                        style={{marginTop: 10, display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}
                        onClick={() => {setDialogModalVisible(true)}}
                        >
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <Download width="12" />
                                <p style={{marginLeft: 5}}>Siphonnage !</p>
                            </div>
                        </button>
                        <div style={{flex: 1}}></div>
                    </div>

                </div>
                :null}
            </div>
        </div>
	);
}

const customStyles = {
    content: {
        flex: 1,
        border: '0px solid rgba(0, 0, 0, 0)',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.01)'
    },
};

export default SiphonnageDataOrg;
