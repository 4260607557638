import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import BounceLoader from "react-spinners/BounceLoader";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

const Access = (props) =>
{
    let history = useHistory();
	const [authorized, setAuthorized] = useState(false)
	const currentUser = localStorage.getItem("session_token")

    const purple = '#6571FE'

	
	async function valide_droits(id_type_user)
	{
		var ret = false;
		if (parseInt(id_type_user) === 1) {
			ret = true;
		}
		await props.type_user.forEach(element => {
			if (element === parseInt(id_type_user)) {
				ret = true;
			}
		});
		return ret;
	}


	async function mount() {
        if (currentUser) {
			const url = 'https://app-slfinance.fr/api/connexion.php';
			const params = {
				'session_token': currentUser
			};
			await axios.post(url, qs.stringify(params))
			.then(async (res) => {
				// si le session_token correspond bien a un utilisateur
				if (res.data)
				{
					/* [0] => donne acces à tout le monde */
					if (props.type_user && props.type_user.length === 1 && props.type_user[0] === 0) {
						await setAuthorized(true)
					}
					else if (props.type_user) {
						await valide_droits(res.data.id_type_users)
						.then((res) => {
							if (res === true) {
								setAuthorized(true)
							}
							else {
								setAuthorized(false)
								setTimeout(() => history.push('/'), 900);
							}
						})
					}
					else {
						await setAuthorized(false)
						await setTimeout(() => history.push('/'), 900);
					}
				}
				else {
					await history.push('/logout')
				}
			})
			.catch(error => {
				console.log("Access: ", error)
            })
		}
		else if (!props.needConnection)
		{
			await setAuthorized(true)
		}
		else {
			await history.push('/logout')
		}
	}


    useEffect(() => {
		mount();
    }, [])

	return(
		<div>
			<NotificationContainer />
			{authorized && <>
                {props.children}
            </>}
            {!authorized && <>
				<div style={{display: 'flex'}}>
					<div style={{flex: 1}}></div>
					<div style={{flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 100}}>
						<BounceLoader color={purple} />
					</div>
					<div style={{flex: 1}}></div>
				</div>
            </>}
		</div>
	);
}

export default Access;
