import React, { useState } from 'react';
import Modal from 'react-modal';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Bin } from '../Img/icon-bin.svg';
import { addSpace, escapeHtml, rescueHtml } from "./Tools";
import Dialog from "./Dialog";


const customStyles = {
    content: {
        top: '35%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
};


const ModalUpdateUser = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    const red = '#E33636'

    console.log(props)

    const date = new Date();
    const annee = date.getFullYear();
    const mois = String(date.getMonth() + 1).padStart(2, '0');
    const jour = String(date.getDate()).padStart(2, '0');
    const dateFormatted = `${annee}-${mois}-${jour}`;
    const [aff, setAff] = useState(false);
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            suppress();
        }
    }

    function closeModal() {
        props.setModalVisible(false);
    }

    async function suppress()
    {
        const params = {
            'delete': true,
            'id': props.who,
            'target_id': props.user.id
        }
        
        axios.post("https://app-slfinance.fr/api/users.php", qs.stringify(params))
        .then(async (res) => {
            if (res.data.status === 1) {
                NotificationManager.success(res.data.status_message, '', 2000);
                setTimeout(() => window.location = window.location.href, 2000);
            }
            else {
                NotificationManager.warning(res.data.status_message, '', 5000);
            }
        })
        .catch((err) => {console.log(err)})
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

        <Dialog
            setModalVisible={setDialogModalVisible}
            modalVisible={dialogModalVisible}
            callbackDialog={callbackDialog}
            message={"Confirmez la suppression :"}
        />
            <div>
                
                <p style={{color: purple, fontWeight: 'bold'}}>Utilisateur [ {props.user.id?props.user.id:null} ]</p>
                
                <div style={{flex: 1, margin: 5, justifyContent: 'right'}}>
                    <p>créé le : {props.user.created?props.user.created.split('-')[2]+'/'+props.user.created.split('-')[1]+'/'+props.user.created.split('-')[0]:null}</p>
                    <p>modifié le : {props.user.modified?props.user.modified.split('-')[2]+'/'+props.user.modified.split('-')[1]+'/'+props.user.modified.split('-')[0]:null}</p>
                </div>
                
                <div style={{display: 'flex', marginTop: 25}}>
                    {/* Nom */}
                    <div style={{flex: 1, margin: 5, justifyContent: 'right'}}>
                        <input
                            style={{marginLeft: 0, width: 250, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={props.user.nom?rescueHtml(props.user.nom):'Nom'}
                            onBlur={async(e) => {
                                if (e.target.value !== '') {
                                    var nom = escapeHtml(e.target.value).toUpperCase();
                                    
                                    const params = {
                                        'update': true,
                                        'id': props.user.id,
                                        'nom': nom
                                    }
                                    
                                    axios.post("https://app-slfinance.fr/api/users.php", qs.stringify(params))
                                    .then(async (res) => {
                                        if (res.data.status === 1) {
                                            NotificationManager.success(res.data.status_message, '', 3000);
                                            props.user.nom = nom;
                                            props.user.modified = dateFormatted
                                            e.target.value = '';
                                            await setAff(!aff);
                                        }
                                        else {
                                            NotificationManager.warning(res.data.status_message, '', 5000);
                                        }
                                    })
                                    .catch((err) => {console.log(err)})
                                }
                            }}
                        />
                    </div>
                    
                    {/* Prenom */}
                    <div style={{flex: 1, margin: 5, justifyContent: 'right'}}>
                        <input
                            style={{marginLeft: 0, width: 250, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={props.user.prenom?rescueHtml(props.user.prenom):'Prenom'}
                            onBlur={async(e) => {
                                if (e.target.value !== '') {
                                    var prenom = escapeHtml(e.target.value);
                                    
                                    const params = {
                                        'update': true,
                                        'id': props.user.id,
                                        'prenom': prenom
                                    }
                                    
                                    axios.post("https://app-slfinance.fr/api/users.php", qs.stringify(params))
                                    .then(async (res) => {
                                        if (res.data.status === 1) {
                                            NotificationManager.success(res.data.status_message, '', 3000);
                                            props.user.prenom = prenom;
                                            props.user.modified = dateFormatted
                                            e.target.value = '';
                                            await setAff(!aff);
                                        }
                                        else {
                                            NotificationManager.warning(res.data.status_message, '', 5000);
                                        }
                                    })
                                    .catch((err) => {console.log(err)})
                                }
                            }}
                        />
                    </div>

                </div>


                <div style={{display: 'flex', marginTop: 10}}>
                    
                    {/* Email */}
                    <div style={{flex: 1, margin: 5, justifyContent: 'right'}}>
                        <input
                            style={{marginLeft: 0, width: 520, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                            placeholder={props.user.email?rescueHtml(props.user.email):'Email'}
                            onBlur={async(e) => {
                                if (e.target.value !== '') {
                                    var email = e.target.value;
                                    
                                    const params = {
                                        'update': true,
                                        'id': props.user.id,
                                        'email': email
                                    }
                                    
                                    axios.post("https://app-slfinance.fr/api/users.php", qs.stringify(params))
                                    .then(async (res) => {
                                        if (res.data.status === 1) {
                                            NotificationManager.success(res.data.status_message, '', 3000);
                                            props.user.email = email;
                                            props.user.modified = dateFormatted
                                            e.target.value = '';
                                            await setAff(!aff);
                                        }
                                        else {
                                            NotificationManager.warning(res.data.status_message, '', 5000);
                                        }
                                    })
                                    .catch((err) => {console.log(err)})
                                }
                            }}
                        />
                    </div>

                </div>


                <div style={{display: 'flex', marginTop: 10}}>
                    
                    {/* id_type_users */}
                    {/* <div style={{flex: 1, margin: 5, justifyContent: 'right'}}>
                        <select className='shadow-md' style={{width: 250, padding: 5}}>
                            {props.user.id_type_users.map((type, i) => 
                            <option key={i} value={type}>{type}</option>
                            )}
                        </select>
                    </div> */}

                </div>

                <div style={{display: 'flex', marginTop: 25}}>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        
                        <div style={{display: 'flex'}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{marginLeft: 5, backgroundColor: red, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                onClick={() => {
                                    setDialogModalVisible(true)
                                }}
                            >
                                <Bin width="12"/>
                                <p style={{marginLeft: 5}}>Supprimer</p>
                            </button>
                        </div>
                        
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default ModalUpdateUser;