import React, { useEffect, useState } from 'react';
import { addSpace } from '../../components/Tools';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import qs from 'qs';

function BilanDepInvSurDepTot(props)
{
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)

    useEffect(() => {

        var filename = "Part des dépenses d\'inv. sur les dépenses totales";

        var ComEpci = null;
        if (props.user.id_organisme_type === '1') {
            ComEpci = 'Com';
        }
        else if (props.user.id_organisme_type === '2') {
            ComEpci = 'Epci';
        }
        else if (props.user.id_organisme_type === '3') {
            ComEpci = 'Com';
        }

        /* Initialisation des options avec les années */
        var option_tmp = {
            chart: {
                toolbar: {
                    show: props.download?true:false,
                    export: {
                        svg: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        png: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                        csv: {
                          filename: filename.replace("'", '_').replace("/", '_').replace(".", ''),
                        },
                    },
                },
                stackType: '100%',
            },
            title: {
                text: filename
            },
            xaxis: {
                categories: props.annees
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return addSpace(Math.round(value*100)/100, true) + "%";
                  }
                },
            },
            stroke: {
                curve: 'smooth',
            },
            plotOptions: {
                bar: {
                    borderRadius: 4
                },
            },
            fill: {
              opacity: 1
            }
        }
        setOptions(option_tmp)

        axios.post('https://app-slfinance.fr/api/graph.php', qs.stringify({
        	'depInvSurDepTot': true,
            'ComEpci': ComEpci,
            'id_budget': props.user.id_budget,
            'annees': props.annees,
            'hypothese': 'R'
        }))
        .then(async (res) => {
            await setSeries([{
                name: "Dépenses d'invest. / (Dépenses d'invest. + Dépenses de gestion)",
                data: res.data[0],
                color: '#27ae60',
                type: 'column',
            },{
                name: "Moyenne annuelle sur la période",
                data: res.data[1],
                color: '#e74c3c',
                type: 'line',
            }])
        })
    }, [])


	return (
		<div style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={'100%'}
                height={props.height?props.height:300}
            />:null}
		</div>
	)
}

export default BilanDepInvSurDepTot;
