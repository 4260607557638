import React, { useEffect, useState } from 'react';
import Access from "../../../components/Access";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { addSpace, exportGrilleSaisieCsv, rescueHtml } from '../../../components/Tools';
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";
import qs from 'qs';
import { ReactComponent as Download } from '../../../Img/icon-download.svg';
import { ReactComponent as Check } from '../../../Img/icon-check.svg';
import { ReactComponent as OpenEye } from '../../../Img/icon-open-eye.svg';
import { ReactComponent as CloseEye } from '../../../Img/icon-close-eye.svg';

function GrilleSaisie(props)
{
    const [validButton, setValidButton] = useState(false);
    const [displayAll, setDisplayAll] = useState(false);

    const greyChapter = '#DBDBDB'
    const purple = '#6571FE'
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    const green = '#59B44A'


    async function recalcule(obj)
    {
        await updateProduits(obj)
        await props.setAff(!props.aff)
    }

    
    useEffect(() => {
        if (props.objet.length != 0) {
            recalcule(props.objet)
        }
    }, [props.objet])



    async function sumProduit(objet)
    {
        var j = 0
        var k = 0
        // Mise a zero de la ligne REIPRD
        await objet.forEach((line, i) => {
            j = 0
            line.forEach(() => {
                if (j > 0) {
                    if (objet[i][0][0] === 'REIPRD') {
                        objet[i][j][1][0] = 0
                        k = i
                    }
                }
                j++;
            });
        });

        await objet.forEach(async(line, i) => {
            j = 0
            await line.forEach(() => {
                if (j > 0) {
                    if (objet[i][0][0] !== 'REIPRD' && objet[i][0][0].includes('PRD')) {
                        objet[k][j][1][0] = objet[k][j][1][0] + parseFloat(objet[i][j][1][0])
                        objet[k][j][1][0] = Math.round(objet[k][j][1][0] * 100) / 100
                    }
                }
                j++;
            });
        });

        await props.setObjet(objet)
        await props.setAff(!props.aff)
    }



    async function updateProduits(objet)
    {
        await objet.forEach(async(line, i) => {
            /* line[0][3] correspond à la colonne calcul */
            if (line[0][3] && line[0][3] !== '') {
                var matriculeBase = line[0][3].split(';')[0]
                var matriculeTaux = line[0][3].split(';')[1]
                
                await line.forEach(async(elem, j) => {
                    if (j > 0) {
                        var annee = elem[0]
                        var base = 0
                        var taux = 0
                        /* Récupération des bases et des taux */
                        await objet.forEach(async(tmp_line) => {
                            if (tmp_line[0][0] === matriculeBase || tmp_line[0][0] === matriculeTaux) {
                                await tmp_line.forEach((year) => {
                                    if (year[0] === annee) {
                                        if (tmp_line[0][0] === matriculeBase) {
                                            base = parseFloat(year[1][0])
                                        }
                                        else if (tmp_line[0][0] === matriculeTaux) {
                                            taux = (parseFloat(year[1][0])/100)
                                        }
                                    }
                                });
                            }
                        });
                        objet[i][j][1][0] = Math.round((base*taux)*100)/100
                    }
                });
            }
        });
        await sumProduit(objet)
    }

    async function updateObject(matricule, annee, value)
    {
        var tmp = props.objet
        await tmp.forEach((line, i) => {
            if (line[0][0] === matricule)
            {
                line.forEach((col, j) => {
                    if (col[0] === annee) {
                        if (value === '') {
                            value = 0
                        }
                        tmp[i][j][1][0] = Math.round(value * 100) / 100
                        if (value !== 0 && value !== '' && value !== '0') {
                            tmp[i][j][1][1] = '1'
                        }
                        if (value === '0' || value === 0 || value === '') {
                            tmp[i][j][1][1] = '0'
                        }
                    }
                });
            }
        });
        return tmp
    }


    function emptyLine(line)
    {
        var ret = true
        line.forEach((element, i) => {
            if (i > 0) {
                if (element[1][0] !== '' && element[1][0] !== '0' && element[1][0] !== 0) {
                    ret = false
                }
            }
        });
        return ret;
    }


    async function validation()
    {
        var ret = true
        var datas_tmp = props.objet
        var tmpi = 0
        var tab_tmp = {}
        while(tmpi <= datas_tmp.length / 10)
        {
            tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'updateGrilleSaisieFiscRetro': true,
                'hypothese': props.hypothese,
                'id_budget': props.id_budget,
                'grilleSaisie': tab_tmp[tmpi]
            }
            await axios.post("https://app-slfinance.fr/api/datas_rei.php", qs.stringify(params))
            .catch((err) => {
                ret = false
                console.log(err)
            })
            tmpi++;
        }
        return ret;
    }

    return (
        <div>
            <NotificationContainer />
            <Access needConnection={true} type_user={[0]}>
                {!validButton?
                <div style={{display: 'flex'}}>
                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 20}}
                        onClick={async () => {
                            setDisplayAll(!displayAll)
                        }}
                    >
                        {displayAll?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}>
                            <CloseEye width="15"/>
                            <p style={{marginLeft: 5}}>Cacher les lignes vides</p>
                        </div>
                        :
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}>
                            <OpenEye width="15"/>
                            <p style={{marginLeft: 5}}>Afficher toutes les lignes</p>
                        </div>}
                    </button>

                    <div style={{flex: 1}}></div>

                    {/* <div style={{textAlign: 'center'}}>
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                            onClick={async () => {
                                const filename = rescueHtml(props.nom_organisme.replace(' ', '_')+"_"+props.nom_budget.replace(' ', '_')+" RETRO_FISCALITE").replace("'", "").toUpperCase();
                                await exportGrilleSaisieCsv(props.objet, props.annees, filename)
                            }}
                        >
                            <Download width="12"/>
                            <p style={{marginLeft: 5}}>Exporter Excel</p>
                        </button>
                    </div> */}

                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: green, color: 'white', fontSize: 14, margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            setValidButton(true)
                            await props.setAff(!props.aff)
                            await validation()
                            .then(() => {
                                NotificationManager.success("Grille sauvegardée", '', 700);
                                setTimeout(() => window.location = window.location.href, 900);
                            })
                        }}
                    >
                        <Check width="12"/>
                        <p style={{marginLeft: 5}}>Valider</p>
                    </button>
                </div>
                :null}
                
                {props.objet.length === 0 || validButton?
                <div style={{flex: 1, margin: 100, display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <BounceLoader color={purple} />
                    <div style={{flex: 1}}></div>
                </div>
                :
                <div className='shadow-2xl rounded-md' style={{flex: 1, marginRight: 20, marginLeft: 20, marginBottom: 50, backgroundColor: 'white'}}>
                    <div style={{display: 'flex', flex: 1, borderBottom: '1px solid white', height: 50, backgroundColor: blue}}>

                        <div style={{flex: 2, marginLeft: 5, textAlign: 'center', minWidth: 150, alignItems: 'center'}}>
                            <p style={{marginTop: 12, fontSize: 16, color: 'white'}}>Intitulé</p>
                        </div>
                        
                        {props.annees.map((annee, j) =>
                        <div key={j} style={{display: 'flex', flex: 1, marginLeft: 5, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50, alignItems: 'center'}}>
                            
                            {j > 0?
                            <div style={{flex: 1, fontSize: 14, color: 'white'}}>
                                <p>Evol %</p>
                            </div>
                            :null}

                            <div style={{flex: 1.8, fontSize: 16, color: 'white'}}>
                                <p>{annee}</p>
                            </div>
                        </div>)}

                    </div>
                    

                    {/* Listing des lignes */}
                    <div style={{flex: 1}}>
                        {props.objet.map((ligne, i) =>
                        displayAll?

/***************************** DISPLAY ALL *****************************/

                        <div key={i} style={ligne[0][2] != '1'?i % 2 === 0?{display: 'flex', flex: 1, backgroundColor: smoothGrey}:{display: 'flex', flex: 1}:{display: 'flex', flex: 1, backgroundColor: greyChapter}}>
                            {/* Affichage de la colone des comptes et noms de compte */}
                            <div style={{flex: 2, marginLeft: 5, fontSize: 14, minWidth: 150}}>
                                {ligne[0][2] != '2'?
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{fontWeight: "bold", marginLeft: 5, color: 'black'}}>{ligne[0][1]}</p>
                                </div>
                                :
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{marginLeft: 30, color: 'black'}}>{ligne[0][1]}</p>
                                </div>}
                            </div>

                            {/* Listing des cellules */}

                            {ligne.map((col, j) =>
                            j > 0?
                            <div key={j} style={ligne[0][2] != '1'?{flex: 1, display: 'flex', fontSize: 13, borderLeft: '1px solid white', minWidth: 50}:{flex: 1, display: 'flex', fontSize: 9, borderLeft: '1px solid white', minWidth: 50}}>
                                {/* Input taux */}
                                {j > 1 && ligne[0][0] !== 'REIBASE' && ligne[0][0] !== 'REITX'?
                                <input
                                style={{flex: 1, width: 50, paddingRight: 5, textAlign: 'right'}}
                                placeholder={
                                    ligne[j][1][0] != 0 && ligne[j-1][1][0] != 0?
                                        addSpace(Math.round((((ligne[j][1][0]-ligne[j-1][1][0])/ligne[j-1][1][0])*100)*100)/100)+'%'
                                        :
                                        ligne[j][1][0] == 0 && ligne[j-1][1][0] != 0?'-100.00%':null
                                    }
                                disabled
                                />
                                :
                                ligne[0][0] === 'REIPRD' && j > 1?
                                <input
                                style={{flex: 1, width: 50, paddingRight: 5, textAlign: 'right'}}
                                placeholder={addSpace(Math.round(col[1][0]), true)}
                                disabled
                                />
                                :null}
                                
                                {/* Input valeurs */}
                                {ligne[0][2] !== '1'?
                                ligne[0][0] === 'REIREFFB2021' && col[0] !== 2021?
                                <input
                                className='rounded-md'
                                style={i % 2 !== 0?{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}:{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}}
                                onBlur={async (e) => {
                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                    if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                        e.target.value = parseFloat(e.target.value)
                                        await updateObject(ligne[0][0], col[0], e.target.value)
                                        .then(async(resObj) => {
                                            await recalcule(resObj)
                                        })
                                    }
                                    e.target.value = ''
                                }}
                                placeholder={col[1][0] !== '' && col[1][0] !== 0?
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        ligne[0][0] === 'REITXTASCOM'?
                                        addSpace(col[1][0])
                                        :
                                        addSpace(Math.round(col[1][0]*100)/100, true)+'%'
                                    :
                                        addSpace(Math.round(col[1][0]), true)
                                :
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        '0%'
                                    :
                                        '0.00'
                                }
                                disabled
                                />
                                :
                                <input
                                className='rounded-md'
                                style={i % 2 !== 0?{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}:{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}}
                                onBlur={async (e) => {
                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                    if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                        e.target.value = parseFloat(e.target.value)
                                        await updateObject(ligne[0][0], col[0], e.target.value)
                                        .then(async(resObj) => {
                                            await recalcule(resObj)
                                        })
                                    }
                                    e.target.value = ''
                                }}
                                placeholder={col[1][0] !== '' && col[1][0] !== 0?
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        ligne[0][0] === 'REITXTASCOM'?
                                        addSpace(Math.round(col[1][0]*100)/100)
                                        :
                                        addSpace(Math.round(col[1][0]*100)/100)+'%'
                                    :
                                        addSpace(Math.round(col[1][0]), true)
                                :
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        '0%'
                                    :
                                        '0.00'
                                }
                                />
                                :
                                ligne[0][0] === 'REIPRD'?
                                <div style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', display: 'flex', alignItems: 'center'}}>
                                    <p style={{flex: 1, color: 'grey'}}>{col[1][0] !== ''?addSpace(Math.round(col[1][0]), true):'0.00'}</p>
                                </div>
                                :null}
                            </div>
                            :null)}
                        </div>
                        :

/***************************** HIDE EMPTY LINES *****************************/

                        !emptyLine(ligne) || ligne[0][2] === '1'?
                        <div key={i} style={ligne[0][2] != '1'?{display: 'flex', flex: 1, borderTop: '2px solid white'}:{display: 'flex', flex: 1, backgroundColor: greyChapter}}>
                            
                            {/* Affichage de la colone des comptes et noms de compte */}
                            <div style={{flex: 2, marginLeft: 5, fontSize: 14, minWidth: 150}}>
                                {ligne[0][2] != '2'?
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{fontWeight: "bold", marginLeft: 5, color: 'black'}}>{ligne[0][1]}</p>
                                </div>
                                :
                                <div style={{marginTop: 4, marginBottom: 4}}>
                                    <p style={{marginLeft: 30, color: 'black'}}>{ligne[0][1]}</p>
                                </div>}
                            </div>

                            {/* Listing des cellules */}
                            {ligne.map((col, j) =>
                            j > 0?
                            <div key={j} style={{flex: 1, display: 'flex', marginLeft: 5, fontSize: 13, borderLeft: '1px solid white', minWidth: 50}}>
                                
                                {/* Input taux */}
                                
                                {j > 1 && ligne[0][0] !== 'REIBASE' && ligne[0][0] !== 'REITX'?
                                <input
                                style={{flex: 1, width: 50, paddingRight: 5, textAlign: 'right'}}
                                placeholder={
                                    ligne[j][1][0] != 0 && ligne[j-1][1][0] != 0?
                                        addSpace(Math.round((((ligne[j][1][0]-ligne[j-1][1][0])/ligne[j-1][1][0])*100)*100)/100)+'%'
                                        :
                                        ligne[j][1][0] == 0 && ligne[j-1][1][0] != 0?'-100.00%':null
                                    }
                                disabled
                                />
                                :null}
                                
                                {/* Input valeurs */}
                                {ligne[0][2] != '1'?
                                ligne[0][0] === 'REIREFFB2021' && col[0] !== 2021?
                                <input
                                className='rounded-md'
                                style={(col[1][1] === '0' || col[1][1] === 0 || col[1][1] === null)?{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}:{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}}
                                onBlur={async (e) => {
                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                    if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                        e.target.value = parseFloat(e.target.value)
                                        await updateObject(ligne[0][0], col[0], e.target.value)
                                        .then(async(resObj) => {
                                            await recalcule(resObj)
                                        })
                                    }
                                    e.target.value = ''
                                }}
                                placeholder={col[1][0] !== '' && col[1][0] !== 0?
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        ligne[0][0] === 'REITXTASCOM'?
                                        addSpace(Math.round(col[1][0]*100)/100)
                                        :
                                        addSpace(Math.round(col[1][0]*100)/100)+'%'
                                    :
                                        addSpace(Math.round(col[1][0]), true)
                                :
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        '0%'
                                    :
                                        '0.00'
                                }
                                disabled
                                />
                                :
                                <input
                                className='rounded-md'
                                style={(col[1][1] === '0' || col[1][1] === 0 || col[1][1] === null)?{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}:{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}}
                                onBlur={async (e) => {
                                    e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                    if (e.target.value !== '' && (parseFloat(e.target.value) || e.target.value === '0')) {
                                        e.target.value = parseFloat(e.target.value)
                                        await updateObject(ligne[0][0], col[0], e.target.value)
                                        .then(async(resObj) => {
                                            await recalcule(resObj)
                                        })
                                    }
                                    e.target.value = ''
                                }}
                                placeholder={col[1][0] !== '' && col[1][0] !== 0?
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        ligne[0][0] === 'REITXTASCOM'?
                                        addSpace(Math.round(col[1][0]*100)/100)
                                        :
                                        addSpace(Math.round(col[1][0]*100)/100)+'%'
                                    :
                                        addSpace(Math.round(col[1][0]), true)
                                :
                                    (ligne[0][0].includes('TX') || ligne[0][0] === 'REIREFFB2021')?
                                        '0%'
                                    :
                                        '0.00'
                                }
                                />
                                :
                                ligne[0][0] === 'REIPRD'?
                                <div style={{flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', display: 'flex', alignItems: 'center'}}>
                                    <p style={{flex: 1, color: 'grey'}}>{col[1][0] !== ''?addSpace(Math.round(col[1][0]), true):'0.00'}</p>
                                </div>
                                :null}
                            </div>
                            :null)}
                        </div>:null)}

                    </div>
                </div>}

            </Access>
        </div>
    );
}

export default GrilleSaisie;