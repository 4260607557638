import React, { useEffect } from 'react';
import LogoSLF from '../Img/logo_SLF.png';
import { ReactComponent as IconSubstract } from '../Img/icon-substract.svg';
import { ReactComponent as IconEqual } from '../Img/icon-equal.svg';
import MatriculeTableauBord from '../Graph/MatriculeTableauBord';
import { rescueHtml } from '../components/Tools.js';

const Page5DetteTreso = (props) => {

    const blue = '#069BD3';
    const purple = '#6571FE'
    
    useEffect(() => {
        if (!props.user) {
            props.getUser()
        }
    }, [props.user])


    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25, display: 'flex', alignItems: 'center'}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, height: '80%'}}>
                <div style={{flex: 1, display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 15}}>
                    
                    {props.objet.map((annee) => 
                    parseInt(annee) == parseInt(props.year)?
                    annee[1].map((line, j) =>
                    line[0] === "Emprunts nouveaux"?
                        <div key={j} style={{flex: 1}}>
                            <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule'] + (line[1]['compte'] != ''?' ('+line[1]['compte']+')':'')} grille={annee[1][j]} color={'#c0392b'}/>
                            {console.log(line)}
                        </div>
                        :null)
                    :null)}

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconSubstract width="20"/>
                    </div>

                    {props.objet.map((annee) => 
                    parseInt(annee) == parseInt(props.year)?
                    annee[1].map((line, j) =>
                        line[0] === "2014M5716"?
                        <div key={j} style={{flex: 1}}>
                            <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule'] + (line[1]['compte'] != ''?' ('+line[1]['compte']+')':'')} grille={annee[1][j]} color={'#3498db'}/>
                        </div>
                        :null)
                    :null)}

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconEqual width="20"/>
                    </div>

                    {props.objet.map((annee) => 
                    parseInt(annee) == parseInt(props.year)?
                    annee[1].map((line, j) =>
                        line[0] === "TRESORERIE"?
                        <div key={j} style={{flex: 1}}>
                            <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={'Solde net de dette'} grille={annee[1][j]} color={'#2ecc71'}/>
                        </div>
                        :null)
                    :null)}
                </div>

                <div style={{flex: 1, display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 5}}>
                    {props.objet.map((annee) => 
                    parseInt(annee) == parseInt(props.year)?
                    annee[1].map((line, j) =>
                        line[0] === "SOLDETRESO"?
                        <div key={j} style={{flex: 1}}>
                            <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule']} grille={annee[1][j]} color={'#2c3e50'} width={500}/>
                        </div>
                        :null)
                    :null)}

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{color: purple, fontWeight: 'bold', margin: 5}}>OU</p>
                    </div>

                    {props.objet.map((annee) => 
                    parseInt(annee) == parseInt(props.year)?
                    annee[1].map((line, j) =>
                        line[0] === "TRESOMOY"?
                        <div key={j} style={{flex: 1}}>
                            <MatriculeTableauBord user={props.user} annee={annee[0]} intitule={line[1]['intitule']} grille={annee[1][j]} color={'#2c3e50'} width={500}/>
                        </div>
                        :null)
                    :null)}
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
};

export default Page5DetteTreso;
