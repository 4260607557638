import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import { addSpace } from '../components/Tools';
import axios from 'axios';
import qs from 'qs';

function MatriculeTableauBord(props)
{
    const purple = '#6571FE'
    const green = '#59B44A'
    const [series, setSeries] = useState(undefined)
    const [options, setOptions] = useState(undefined)


    useEffect(() => {
        if (!series || !options)
        {
            /* Initialisation des options avec les années */
            var option_tmp = {
                legend: {
                  position: 'top',
                  offsetY: 10
                },
                chart: {
                  type: 'line',
                },
                title: {
                    text: props.intitule
                },
                yaxis: {
                    labels: {
                      formatter: function (value) {
                        return addSpace(Math.round(value*100)/100, true);
                      }
                    },
                },
                stroke: {
                  width: [0, 4]
                },
                xaxis: {
                    labels: {
                      rotate: -35
                    },
                    categories: ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'bar'
                },
            }

            setOptions(option_tmp)
            var mois = [
                Math.round((props.grille[1].janvier)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet + props.grille[1].aout)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet + props.grille[1].aout + props.grille[1].septembre)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet + props.grille[1].aout + props.grille[1].septembre + props.grille[1].octobre)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet + props.grille[1].aout + props.grille[1].septembre + props.grille[1].octobre + props.grille[1].novembre)),
                Math.round((props.grille[1].janvier + props.grille[1].fevrier + props.grille[1].mars + props.grille[1].avril + props.grille[1].mai + props.grille[1].juin + props.grille[1].juillet + props.grille[1].aout + props.grille[1].septembre + props.grille[1].octobre + props.grille[1].novembre + props.grille[1].decembre)),
            ]

            var budget_vote = [
                Math.round((Math.round(props.grille[1].total_vote)) / 12),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 2),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 3),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 4),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 5),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 6),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 7),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 8),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 9),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 10),
                Math.round((Math.round(props.grille[1].total_vote)) / 12 * 11),
                Math.round((Math.round(props.grille[1].total_vote)))
            ]

            setSeries(
                props.intitule != 'Trésorerie moyenne sur le mois' && props.intitule != 'Solde de trésorerie fin de mois'?
                [{
                    name: "Cumul",
                    data: mois,
                    color: props.color,
                    type: 'column',
                },
                
                {
                    name: "Budget voté",
                    data: budget_vote,
                    color: '#A3A3A3',
                    type: 'line'
                }]
                :
                [{
                    name: "Cumul",
                    data: mois,
                    color: props.color,
                    type: 'column',
                }]
            )
        }
    }, [])



	return (
		<div className='shadow-xl' style={{margin: 5}}>
            {options && series?
			<ApexCharts
                series={series}
                options={options}
                width={props.width?props.width:400}
                height={props.height?props.height:270}
            />:null}
		</div>
	)
}

export default MatriculeTableauBord;
