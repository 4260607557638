import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Trash } from '../../Img/icon-white-trash.svg';
import { addSpace } from '../../components/Tools';
import {NotificationManager} from 'react-notifications';
import { ReactComponent as Check } from '../../Img/icon-check.svg';
import { ReactComponent as Add } from '../../Img/icon-add.svg';

const GrilleTableauBord = (props) =>
{
    const heightObject = window.innerHeight/1.7
    // const [alertCloseWindow, setAlertCloseWindow] = useState(false);
    const [aff, setAff] = useState(false);

    const purple = '#6571FE'
    const blue = '#069BD3'
    const green = '#59B44A'
    const smoothGrey = '#f4f4f4'
    
    // if (alertCloseWindow === true) {
    //     window.addEventListener('beforeunmount', function (e) {
    //         e.preventDefault();
    //         e.returnValue = '';
    //     });
    // }


    async function suppressDM(DM)
    {
        var tmp = props.objet
        await tmp.forEach(async(line, i) => {
            if (line[DM]) {
                await props.updateObjet(line.matrice.matricule, DM, 0, 'totalVote', props.DM)
            }
        });
        await validation('notnotif')
        if (props.DM.length > 0 && parseInt(DM.slice(2)) === props.DM[props.DM.length - 1]) {
            if (props.DM.length === 1) {
                await props.setDM([0])
            }
            else {
                await props.DM.pop()
            }
        }
        await setAff(!aff)
    }



    async function validation(notnotif = '')
    {
        const params = {
            'set_datas': true,
            'id_budget': props.user.id_budget,
            'annee': props.annee,
            'mois': props.mois,
            'DM': props.DM,
            'objet': props.objet
        };
        axios.post('https://app-slfinance.fr/api/tableau_bord.php', qs.stringify(params))
        .then(async(res) => {
            await props.setAlertCloseWindow(false)
            if (notnotif !== 'notnotif') {
                if (res.data.status === 1) {
                    await NotificationManager.success(res.data.status_message, '', 700);
                }
                else {
                    await NotificationManager.warning(res.data.status_message, '', 3000);
                }
            }
        })
        .catch(error => {
            console.log("Access: ", error)
        })
    }


	return (
        <div>
            <div style={{display: 'flex'}}>

                {/* Bouton pour ajouter un DM */}
                <div style={{marginLeft: 20, display: 'flex', alignItems: 'center'}}>
                    <button
                        className='shadow-md p-2 rounded-md'
                        style={{backgroundColor: purple, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20}}
                        onClick={async () => {
                            var tmp = props.DM
                            tmp[tmp.length] = tmp[tmp.length-1] + 1
                            props.setDM(tmp)
                            await setAff(!aff)
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Add width="12" />
                            <p style={{margin: 5}}>Ajouter une Décision modificative</p>
                        </div>
                    </button>
                </div>

                <div style={{flex: 1}}></div>

                {/* Bouton pour valider la grille */}
                <div style={{marginRight: 20}}>
                    <button
                        className='shadow-md p-2 rounded-md'
                        style={{backgroundColor: green, color: 'white', fontSize: 14, marginTop: 20, marginRight: 20}}
                        onClick={async () => {
                            await validation()
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Check width="12" />
                            <p style={{margin: 5}}>Valider</p>
                        </div>
                    </button>
                </div>
            </div>
            

            {/* Grille */}
            <div className='shadow-xl rounded-md' style={{margin: 20}}>
                
                {/* Premiere ligne Intitulé des colones */}
                <div style={{overflowY: 'scroll', textAlign: 'center'}}>
                    <div style={{flex: 1, borderTop: '1px solid white', display: 'flex'}}>
                        
                        <div style={{flex: 2, backgroundColor: blue, display: 'flex', textAlign: 'right', minHeight: 28, minWidth: 200}}>
                        </div>

                        <div style={{flex: 0.5, backgroundColor: blue, borderLeft: '1px solid white', width: 100, padding: 2}}>
                            <p style={{color: 'white', fontSize: 18, marginLeft: 5}}>Budget primitif</p>
                        </div>

                        {/* Liste des DM */}
                        {props.DM.map((index, l) =>
                        index > 0?
                        <div key={l} style={{flex: 0.5, backgroundColor: blue, borderLeft: '1px solid white', width: 100, padding: 2, display: 'flex', textAlign: 'center'}}>
                            <div style={{flex: 1}}></div>
                            <p style={{color: 'white', fontSize: 18, marginRight: 5}}>DM{index}</p>
                            <button
                            onClick={async () => {
                                var dialog = window.confirm("Veuillez confirmer la suppression");
                                if (dialog) {
                                    suppressDM('DM'+index)
                                }
                            }}>
                                <Trash />
                            </button>
                            <div style={{flex: 1}}></div>
                        </div>:null)}

                        <div style={{flex: 0.5, backgroundColor: blue, borderLeft: '1px solid white', width: 100, padding: 2}}>
                            <p style={{color: 'white', fontSize: 18, marginLeft: 5}}>Total voté</p>
                        </div>

                        <div style={{flex: 0.5, backgroundColor: blue, borderLeft: '1px solid white', width: 100, padding: 2}}>
                            <p style={{color: 'white', fontSize: 18, marginLeft: 5}}>{props.frontMonth}</p>
                        </div>
                    </div>
                </div>

                {/* Liste de la grille avec les données */}
                <div style={{overflowY: 'scroll', maxHeight: heightObject}}>
                    {props.objet.map((line, i) =>
                    <div key={i} style={{flex: 1, borderTop: '1px solid white', display: 'flex'}}>
                        
                        {line.matrice.chapitre === "0"?
                        <div style={i%2===0?{flex: 2, display: 'flex', textAlign: 'right', minHeight: 28, minWidth: 200, backgroundColor: smoothGrey}:{flex: 2, display: 'flex', textAlign: 'right', minHeight: 28, minWidth: 200, backgroundColor: 'white'}}>
                            <p style={{color: 'red', minWidth: 100}}>{line.matrice.compte}</p>
                            <p style={{marginLeft: 10}}>{line.matrice.intitule}</p>
                        </div>
                        :
                        <div style={{flex: 2, padding: 3, backgroundColor: blue}}>
                            <p style={{color: 'white', fontSize: 18, marginLeft: 5}}>{line.matrice.intitule}</p>
                        </div>
                        }
                        
                        {/* Budget Primitif */}
                        {line.matrice.chapitre != '1'?
                        <input
                            style={i%2===0?{flex: 0.5, textAlign: 'right', borderLeft: '1px solid white', width: 100, padding: 2, backgroundColor: smoothGrey}:{flex: 0.5, textAlign: 'right', borderLeft: '1px solid #f4f4f4', width: 100, padding: 2, backgroundColor: 'white'}}
                            onBlur={async(e) =>{
                                if (e.target.value !== '' && e.target.value !== null) {
                                    await props.setAlertCloseWindow(true);
                                    var value = parseFloat(e.target.value)
                                    if (value || value === 0) {
                                        await props.updateObjet(line.matrice.matricule, 'budget_primitif', value, 'totalVote', props.DM)
                                        e.target.value = ''
                                        await setAff(!aff)
                                    }
                                }
                            }}
                            placeholder={line.budget_primitif?addSpace(line.budget_primitif, true):''}
                        />
                        :null}


                        {/* DM */}
                        {line.matrice.chapitre != '1'?
                            props.DM.map((elem, k) =>
                            elem > 0?
                            <input
                                key={k}
                                style={i%2===0?{flex: 0.5, textAlign: 'right', borderLeft: '1px solid white', width: 100, padding: 2, backgroundColor: smoothGrey}:{flex: 0.5, textAlign: 'right', borderLeft: '1px solid #f4f4f4', width: 100, padding: 2, backgroundColor: 'white'}}
                                placeholder={line['DM'+elem]?addSpace(line['DM'+elem], true):''}
                                onBlur={async(e) =>{
                                    if (e.target.value !== '' && e.target.value !== null) {
                                        await props.setAlertCloseWindow(true);
                                        var value = parseFloat(e.target.value)
                                        if (value || value === 0) {
                                            await props.updateObjet(line.matrice.matricule, 'DM'+elem, value, 'totalVote', props.DM)
                                            e.target.value = ''
                                            await setAff(!aff)
                                        }
                                    }
                                }}
                            />
                            :null)
                        :null}
                        
                        {/* Total Voté */}
                        {line.matrice.chapitre != '1'?
                        <input
                            style={i%2===0?{flex: 0.5, textAlign: 'right', borderLeft: '1px solid white', width: 100, padding: 2, backgroundColor: smoothGrey}:{flex: 0.5, textAlign: 'right', borderLeft: '1px solid #f4f4f4', width: 100, padding: 2, backgroundColor: 'white'}}
                            onBlur={async(e) =>{
                                if (e.target.value !== '' && e.target.value !== null) {
                                    await props.setAlertCloseWindow(true);
                                    var value = parseFloat(e.target.value)
                                    if (value || value === 0) {
                                        await props.updateObjet(line.matrice.matricule, 'total_vote', value)
                                        e.target.value = ''
                                        await setAff(!aff)
                                    }
                                }
                            }}
                            placeholder={line.total_vote?addSpace(Math.round(line.total_vote*100)/100, true):''}
                        />
                        :null}

                        {/* MOIS */}
                        {line.matrice.chapitre != '1'?
                        <input
                            style={i%2===0?{flex: 0.5, textAlign: 'right', borderLeft: '1px solid white', width: 100, padding: 2, backgroundColor: smoothGrey}:{flex: 0.5, textAlign: 'right', borderLeft: '1px solid #f4f4f4', width: 100, padding: 2, backgroundColor: 'white'}}
                            placeholder={line[props.mois]?addSpace(line[props.mois], true):''}
                            onBlur={async(e) =>{
                                if (e.target.value !== '' && e.target.value !== null) {
                                    await props.setAlertCloseWindow(true);
                                    var value = parseFloat(e.target.value)
                                    if (value || value === 0) {
                                        await props.updateObjet(line.matrice.matricule, props.mois, value)
                                        e.target.value = ''
                                        await setAff(!aff)
                                    }
                                }
                            }}
                        />
                        :null}
                    </div>
                    )}

                </div>

            </div>
        </div>
	);
}

export default GrilleTableauBord;
