import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { addSpace, escapeHtml, rescueHtml } from "./Tools";



const ModalPilotage = (props) =>
{
    const greenpastel = '#b8e994'
    const orangepastel = '#ffbe76'
    const redpastel = '#ff7979'
    const grey = '#BDC3C7'
    
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            paddingLeft: 75,
            paddingRight: 75,
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            overflow: 'hidden',
            border: '3px solid '+ (props.objet[9] == 1? greenpastel:props.objet[9] == 0 && (new Date(props.objet[6])) <= (new Date()) ? redpastel : '#ffffff')
        },
    }
    console.log(props.objet)
    const [aff, setAff] = useState(false);

    function closeModal() {
        props.setModalVisible(false);
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >
            <div>

                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{flex: 1, marginTop: 20}}>
                        <p>Date</p>
                        <input
                            type="date"
                            className='shadow-md'
                            style={{padding: 3, flex: 1, minWidth: 90, alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid '+grey, borderRadius: 3}}
                            value={props.objet[2] || ''}
                            onChange={(e) => {
                                props.objet[2] = e.target.value;
                                setAff(!aff);
                            }}
                        />
                    </div>

                    <div style={{marginTop: 20}}>
                        <p>Instance réunie</p>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}></div>
                            <input
                                className='shadow-md'
                                style={{padding: 5, minWidth: 500, alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid '+grey, borderRadius: 3}}
                                value={props.objet[3] ? rescueHtml(props.objet[3]) : ''}
                                onChange={(e) => {
                                    props.objet[3] = e.target.value;
                                    setAff(!aff);
                                }}
                            />
                            <div style={{flex: 1}}></div>
                        </div>
                    </div>
                </div>

                <div style={{marginTop: 20}}>
                    <p>Relevé de décision</p>
                    <textarea
                        className='shadow-md'
                        placeholder="Mettre l'intitulé à la première ligne"
                        style={{padding: 5, minWidth: 700, minHeight: 150, alignItems: 'center', justifyContent: 'center', border: '1px solid '+grey, borderRadius: 3}}
                        value={props.objet[4] ? rescueHtml(props.objet[4]) : ''}
                        onChange={(e) => {
                            props.objet[4] = e.target.value;
                            setAff(!aff);
                        }}
                    />
                </div>

                <div style={{marginTop: 20}}>
                    <p>Action suivante</p>
                    <textarea
                        className='shadow-md'
                        placeholder="Mettre l'intitulé à la première ligne"
                        style={{padding: 5, minWidth: 700, minHeight: 150, alignItems: 'center', justifyContent: 'center', border: '1px solid '+grey, borderRadius: 3}}
                        value={props.objet[5] ? rescueHtml(props.objet[5]) : ''}
                        onChange={(e) => {
                            props.objet[5] = e.target.value;
                            setAff(!aff);
                        }}
                    />
                </div>

                <div style={{display: 'flex'}}>
                    
                    <div style={{flex: 1, margin: 20}}>
                        <p>Date limite de l'action</p>
                        <input
                            type="date"
                            className='shadow-md'
                            style={{padding: 3, flex: 1, minWidth: 90, alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid '+grey, borderRadius: 3}}
                            value={props.objet[6] || ''}
                            onChange={(e) => {
                                props.objet[6] = e.target.value;
                                setAff(!aff);
                            }}
                        />
                    </div>

                    <div style={{flex: 2, marginTop: 20}}>
                        <p>Responsable de l'action</p>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}></div>
                            <input
                                className='shadow-md'
                                style={{padding: 5, minWidth: 400, alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid '+grey, borderRadius: 3}}
                                value={props.objet[7] ? rescueHtml(props.objet[7]) : ''}
                                onChange={(e) => {
                                    props.objet[7] = e.target.value;
                                    setAff(!aff);
                                }}
                            />
                            <div style={{flex: 1}}></div>
                        </div>
                    </div>

                </div>

                <div style={{marginTop: 20}}>

                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <p>Liste des mails</p>
                        <p style={{fontStyle: 'italic', fontSize: 13, marginLeft: 5}}>(séparés par un retour à la ligne)</p>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <textarea
                            className='shadow-md'
                            style={{padding: 5, minWidth: 500, alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid '+grey, borderRadius: 3}}
                            value={props.objet[8] ? rescueHtml(props.objet[8]) : ''}
                            onBlur={(e) => {
                                props.objet[8] = e.target.value.replace(/ +/g, "").replace(/\n+$/, "");
                                setAff(!aff);
                            }}
                            onChange={(e) => {
                                props.objet[8] = e.target.value;
                                setAff(!aff);
                            }}
                        />
                        <div style={{flex: 1}}></div>
                    </div>
                </div>


                <div style={{display: 'flex', flex: 1, marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                    <p>Action terminée</p>
                    <input
                        style={{marginLeft: 10}}
                        type='checkbox'
                        checked={props.objet[9] == 1?true:false}
                        onClick={(e) => {
                            if (e.target.checked === true) {
                                props.objet[9] = 1;
                            } else {
                                props.objet[9] = 0;
                            }
                            setAff(!aff);
                        }}
                    />


                </div>

            </div>

        </Modal>
    );
};

export default ModalPilotage;