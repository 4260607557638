import * as React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import qs from 'qs';
import {NotificationManager} from 'react-notifications';


const customStyles = {
    content: {
        top: '35%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
  };

const copyHyp = (props) =>
{
    const purple = '#6571FE'

    function closeModal() {
        props.setModalVisible(false);
    }


    async function copy(to)
    {
        var params = {
            copyHypothese: true,
            id_budget: props.id_budget,
            hypothese: props.hypothese.toUpperCase(),
            to: to
        }

        await axios.post('https://app-slfinance.fr/api/copie_hypothese.php', qs.stringify(params))
        .then(async (ret) => {
            if (ret.data.status === 1) {
                NotificationManager.success(ret.data.status_message, '', 700);
                closeModal()
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }



    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >
            <p>Copie de {props.hypothese.toUpperCase()} vers :</p>
            <div>
                {props.hypothese !== 'h1'?
                <button
                className='shadow-md p-2 rounded-md'
                style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 10}}
                onClick={() => {
                    copy('H1')
                }}
                >
                    <p>H1</p>
                </button>:null}

                {props.hypothese !== 'h2'?
                <button
                className='shadow-md p-2 rounded-md'
                style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 10}}
                onClick={() => {
                    copy('H2')
                }}
                >
                    <p>H2</p>
                </button>:null}

                {props.hypothese !== 'h3'?
                <button
                className='shadow-md p-2 rounded-md'
                style={{backgroundColor: purple, color: 'white', fontSize: 14, margin: 10}}
                onClick={() => {
                    copy('H3')
                }}
                >
                    <p>H3</p>
                </button>:null}
            </div>
        </Modal>
    );
};

export default copyHyp;