import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { rescueHtml, deleteOrganisme } from './Tools';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Bin } from '../Img/icon-bin.svg';
import Dialog from "./Dialog";

const DeleteOrganisme = () =>
{
    const [organismes, setOrganismes] = useState([]);
    const [organisme, setOrganisme] = useState();

    const red = '#E33636'
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    function callbackDialog(ret) {
        if (ret === true) {
            suppress();
        }
    }

    useEffect(() => {
        axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom)
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])
        
    function suppress()
    {
        if (organisme !== undefined && organisme !== '')
        {
            deleteOrganisme(organisme)
            NotificationManager.success('Organisme et Budgets supprimés', '', 700);
            setTimeout(() => window.location = window.location.href, 900);
        }
    }

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>

            <NotificationContainer />

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Confirmez la suppression :"}
            />

            <p>Supprimer un Organisme :</p>
            <div style={{textAlign: 'center'}}>
                <div style={{margin: 10}}>
                    <div style={{margin: 10}}>
                        <select
                        className='shadow-md'
                        style={{width: 250, padding: 5}}
                        onChange={(e) => {
                            setOrganisme(e.target.value)
                        }}
                        >
                            <option value="">...</option>
                            {organismes.map((organisme) => 
                            <option key={organisme['id']} value={organisme['id']}>{organisme['nom']}</option>
                            )}
                        </select>
                    </div>

                    <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                        <button
                            className='shadow-md rounded-md'
                            style={{backgroundColor: red, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                            onClick={() => {
                                if (organisme !== undefined && organisme !== '') {
                                    setDialogModalVisible(true)
                                }
                            }}
                        >
                            <Bin width="12"/>
                            <p style={{marginLeft: 5}}>Supprimer</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default DeleteOrganisme;
