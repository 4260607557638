import React from 'react';
import LogoSLF from '../Img/logo_SLF.png';
import BilanEpargneBrute from '../Graph/Bilan/BilanEpargneBrute';
import BilanEpargneNette from '../Graph/Bilan/BilanEpargneNette';
import BilanDepensesInvProsp from '../Graph/Bilan/BilanDepensesInvProsp';
import BilanResultatCloture31_12 from '../Graph/Bilan/BilanResultatCloture31_12';
import { rescueHtml } from '../components/Tools';

const Page41Graph = (props) => {
    const blue = '#069BD3';
    const smoothGrey = '#f4f4f4';

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25}}>
                <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%'}}>
                <div style={{flex: 1, display: 'flex', marginLeft: 25, marginRight: 25}}>
                    <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                        <BilanEpargneBrute aff={props.aff} setAff={props.setAff} affH1={props.affH1} affH2={props.affH2} affH3={props.affH3} user={props.user} annees={props.annees} height={250}/>
                    </div>
                    <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                        <BilanEpargneNette aff={props.aff} setAff={props.setAff} affH1={props.affH1} affH2={props.affH2} affH3={props.affH3} user={props.user} annees={props.annees} height={250}/>
                    </div>
                </div>
                <div style={{flex: 1, display: 'flex', marginLeft: 25, marginRight: 25}}>
                    <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                        <BilanDepensesInvProsp aff={props.aff} setAff={props.setAff} affH1={props.affH1} affH2={props.affH2} affH3={props.affH3} user={props.user} annees={props.annees} height={250}/>
                    </div>
                    <div style={{flex: 1, margin: 5}} className='shadow-md rounded'>
                        <BilanResultatCloture31_12 aff={props.aff} setAff={props.setAff} affH1={props.affH1} affH2={props.affH2} affH3={props.affH3} user={props.user} annees={props.annees} height={250}/>
                    </div>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
};

export default Page41Graph;
