import * as React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '35%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
  };

const Doalog = (props) =>
{
    const green = '#59B44A'
    const red = '#E33636'

    function closeModal(ret) {
        props.callbackDialog(ret)
        props.setModalVisible(false);
    }


    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >
            <div>
                <div style={{margin: 10}}>
                    <p>{props.message}</p>
                </div>
                <div style={{display: 'flex'}}>
                    
                    <div style={{flex: 1}}></div>

                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: red, color: 'white', fontSize: 12}}
                        onClick={async () => {
                            await closeModal(false)
                        }}
                    >
                        <p style={{margin: 10}}>Annuler</p>
                    </button>

                    <div style={{flex: 0.2}}></div>

                    <button
                        className='shadow-md rounded-md'
                        style={{backgroundColor: green, color: 'white', fontSize: 12}}
                        onClick={async () => {
                            await closeModal(true)
                        }}
                    >
                        <p style={{marginTop: 10, marginBottom: 10, marginLeft: 20, marginRight: 20}}>OK</p>
                    </button>

                    <div style={{flex: 1}}></div>

                </div>
            </div>
        </Modal>
    );
};

export default Doalog;