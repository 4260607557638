import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from './Img/logo-slfinance2.svg';
import Access from "./components/Access";
import { escapeHtml } from './components/Tools';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
// import { ReactComponent as Check } from './Img/icon-check.svg';
// import { ReactComponent as IconOpenEye50 } from './Img/icon-oeil-ouvert-50x50.svg';
// import { ReactComponent as IconCloseEye50 } from './Img/icon-oeil-ferme-50x50.svg';

const Login = (props) =>
{
    let history = useHistory();
    const [response, setResponse] = useState(false);
    // const [useremail, setUseremail] = useState('');
    // const [password, setPassword] = useState('')
    // const [viewPassword, setViewPassword] = useState(false)

    const purple = '#6571FE'
    const green = '#59B44A'


    function validEmail(email) {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(email.match(mailformat)) {
            return true;
        }
        else {
            NotificationManager.warning('Mauvais format de mail', '', 2000);
            return false;
        }
    }


    // Resultat du formulaire
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        if(data.email === "") {
            NotificationManager.warning('Champ manquant', '', 2000);
        }
        else {
            if (validEmail(data.email))
            {
                const url = 'https://app-slfinance.fr/api/auth.php';
                const params = {
                    'generateToken': true,
                    'email': escapeHtml(data.email),
                    'url': window.location.host
                };
                axios.post(url, qs.stringify(params))
                .then(async function (res) {
                    console.log(res.data)
                    if (res.data.status === 1) {
                        await NotificationManager.success(res.data.status_message, '', 5000);
                        await setResponse(res.data.status_message)
                    }
                    else {
                        await NotificationManager.warning("Erreur "+res.data.code+": ["+res.data.status_message+"]", '', 5000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    };

	return (
        <Access needConnection={false}>
            <NotificationContainer />
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>
                <div style={{textAlign: 'center'}}>
                    <Logo width="400" />
                </div>
                <div style={{flex: 1}}></div>
            </div>

            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}></div>

                <div style={{justifyContent: 'center', minWidth: 450, backgroundColor: 'white'}}>
                    <div className='shadow-md' style={{flex: 1, borderRadius: 3, padding: 5}}>
                        <div style={{textAlign: 'center'}}>

                            <div style={{display: 'flex'}}>
                                <form onSubmit={handleSubmit(onSubmit)} style={{flex: 1}}>
                                    <div style={{margin: 25, textAlign: 'left'}}>
                                        <p style={{fontSize: 15}}>Adresse email</p>
                                        <input style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}} placeholder='Email' type='email' {...register("email")} />
                                    </div>
                                    
                                    {/* <div style={{margin: 25, textAlign: 'left'}}>
                                        <p style={{fontSize: 15}}>Mot de passe</p>
                                        <input style={{width: 400, marginTop: 10, border: '1px solid #E9ECEF', borderRadius: 5, padding: 10}} placeholder='Mot de passe' type="password" {...register("password")} />
                                    </div> */}

                                    {response === false?
                                    <div style={{margin: 10}}>
                                        <input className='shadow-md p-2 rounded-md' style={{backgroundColor: green, color: 'white', fontSize: 14, fontSize: 14}} type="submit" value="Se connecter" />
                                    </div>
                                    :
                                    <div style={{margin: 10}}>
                                        <p style={{fontSize: 15, color: green}}>{response}</p>
                                        <p style={{fontSize: 15, color: green}}>Cliquez sur le lien reçu par mail</p>
                                    </div>
                                    }
                                </form>
                            </div>
                            {/* <div style={{marginBottom: 20, marginTop: 15}}>
                                <a href="/forgetpassword"  style={{color: purple, fontSize: 15}}>Mot de passe oublié</a>
                            </div> */}

                        </div>
                    </div>
                </div>

                <div style={{flex: 1}}></div>
            </div>
        </Access>
	);
}

export default Login;
