import React from 'react';
import LogoSLF from '../Img/logo_SLF.png';
import BilanEpargneSurRecettes from '../Graph/Bilan/BilanEpargneSurRecettes';
import BilanEpargneBruteSurRecettes from '../Graph/Bilan/BilanEpargneBruteSurRecettes';
import BilanEpargneNetteSurRecettes from '../Graph/Bilan/BilanEpargneNetteSurRecettes';
import { rescueHtml } from '../components/Tools.js';

const Page9Graph = (props) => {
    const blue = '#069BD3';
    const smoothGrey = '#f4f4f4';

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>

            {/* Header */}
            <div style={{display: 'flex'}}>
                <div style={{margin: 25}}>
                    <img style={{width: 200}} src={LogoSLF} />
                </div>

                <div style={{flex: 1}}></div>
                
                <div style={{margin: 25}}>
                    <p style={{color: blue, fontSize: 24}}>{rescueHtml(props.user.nom_organisme)}</p>
                </div>
            </div>

            <div style={{borderBottom: '1px solid '+blue, marginLeft: 25, display: 'flex'}}>
                <div>
                    <p style={{color: blue, fontSize: 26}}>{props.titre}</p>
                </div>
                <div style={{flex: 1, justifyContent: 'end', alignItems: 'end'}}>
                    <p style={{color: blue, fontSize: 20, fontStyle: 'italic', marginLeft: 5, marginTop: 5}}>(Épargne / Recettes réelles {(props.user.nomen == 'M4' || props.user.nomen == 'M41' || props.user.nomen == 'M43' || props.user.nomen == 'M49'?'d\'exploitation':'de fonctionnement')})</p>
                </div>
            </div>


            {/* Container */}
            <div style={{flex: 1, marginTop: 25, height: '80%'}}>
                <div style={{flex: 1, display: 'flex', margin: 25}}>
                    {props.user.nomen != 'M4' && props.user.nomen != 'M41' && props.user.nomen != 'M43' && props.user.nomen != 'M49'?
                    <div style={{flex: 1, margin: 5, marginTop: 100}} className='shadow-md rounded'>
                        <BilanEpargneSurRecettes user={props.user} annees={props.annees} height={350}/>
                    </div>
                    :null}
                    <div style={{flex: 1, margin: 5, marginTop: 100}} className='shadow-md rounded'>
                        <BilanEpargneBruteSurRecettes user={props.user} annees={props.annees} height={350}/>
                    </div>
                    <div style={{flex: 1, margin: 5, marginTop: 100}} className='shadow-md rounded'>
                        <BilanEpargneNetteSurRecettes user={props.user} annees={props.annees} height={350}/>
                    </div>
                </div>
            </div>


            {/* Footer */}
            <div style={{display: 'flex', marginBottom: 15}}>
                <div style={{marginLeft: 25}}>
                    <p style={{fontSize: 10}}>{props.date}</p>
                </div>
                <div style={{flex: 1}}></div>
                <div style={{marginRight: 25}}>
                    <p style={{fontSize: 10}}>Page : {props.index}</p>
                </div>
            </div>
        </div>
    );
};

export default Page9Graph;
