import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { checkUser } from "./components/Tools";

/* Les différents fichiers */
import Homepage from "./Homepage";
import Adminpage from "./Adminpage";
import Utilisateurs from "./Gestion/Utilisateurs";
import Application from "./Gestion/Application";
import Organismepage from "./Organismepage";
import Partenairepage from "./Partenairepage";
import Moncompte from "./Moncompte";
import SaisieRetro from "./SaisieRetro";
import SaisieProsp from "./SaisieProsp";
import FichesProjets from "./FichesProjets";
import FicheProjet from "./FicheProjet";
import SaisieFisc from "./SaisieFisc";
import Dette from "./Dette";
import Restitution from "./Restitution";
import NotFound from "./NotFound";
import Register from "./Register";
import Checktoken from "./Checktoken";
import Login from "./Login";
// import Forgetpassword from "./Forgetpassword";
import Logout from "./Logout";
import MassiveMailing from "./MassiveMailing";
import TableauDeBord from "./TableauDeBord";
import GraphTableauDeBord from "./BilanTableauBord/GraphTableauDeBord";
import AccueilOranisme from "./AccueilOranisme";
import VerifRetro from "./VerifRetro";
import CalculEmpruntsManuel from "./CalculEmpruntsManuel";
import CalculEmpruntsAuto from "./CalculEmpruntsAuto";
import BilanPDF from "./Bilan/BilanPDF";
import Hypothese from "./CommentaireHypothese";


const App = () =>
{
	const [user, setUser] = useState(null);

	function getUser()
	{
		const token = localStorage.getItem("session_token")
		checkUser(token)
		.then(async (result) => {
			if (result !== false) {
				await setUser(result);
			}
		})
	}

    return (
		<div style={{background: "linear-gradient(#EDEDED, #FFFFFF)", minHeight: window.innerHeight}}>
			<Router>
				<Switch>
					<Route path='/' exact> <Homepage /></Route>

				{/* Les routes en connexion */}
					<Route path='/adminpage' exact> <Adminpage user={user} getUser={getUser} /></Route>
					<Route path='/gestion/utilisateurs' exact> <Utilisateurs user={user} getUser={getUser} /></Route>
					<Route path='/gestion/application' exact> <Application user={user} getUser={getUser} /></Route>
					<Route path='/organismepage' exact> <Organismepage user={user} getUser={getUser} /></Route>
					<Route path='/partenairepage' exact> <Partenairepage user={user} getUser={getUser} /></Route>
					<Route path='/moncompte' exact> <Moncompte user={user} getUser={getUser} /></Route>
					<Route path='/massivemailing' exact> <MassiveMailing user={user} getUser={getUser} /></Route>

					{/* Fonctionnement */}
					<Route path='/saisie/retrospective/fct/recette' exact> <SaisieRetro user={user} getUser={getUser} /></Route>
					<Route path='/saisie/retrospective/fct/depense' exact> <SaisieRetro user={user} getUser={getUser} /></Route>

					<Route path='/saisie/prospective/fct/recette' exact> <SaisieProsp user={user} getUser={getUser} /></Route>

					<Route path='/saisie/prospective/fct/depense' exact> <SaisieProsp user={user} getUser={getUser} /></Route>

					{/* Investissemenet */}
					<Route path='/saisie/retrospective/inv/recette' exact> <SaisieRetro user={user} getUser={getUser} /></Route>
					<Route path='/saisie/retrospective/inv/depense' exact> <SaisieRetro user={user} getUser={getUser} /></Route>

					{/* Fiscalité */}
					<Route path='/saisie/retrospective/fiscalite' exact> <SaisieFisc user={user} getUser={getUser} /></Route>

					<Route path='/saisie/prospective/fiscalite' exact> <SaisieFisc user={user} getUser={getUser} /></Route>

					{/* Fiches projets */}
					<Route path='/saisie/prospective/fichesprojets' exact><FichesProjets user={user} getUser={getUser} /></Route>
					<Route path='/saisie/prospective/ficheprojet/:id' ><FicheProjet user={user} getUser={getUser} /></Route>

					{/* Commentaires Hypothèses */}
					<Route path='/saisie/prospective/commentaire' exact><Hypothese user={user} getUser={getUser} /></Route>

					{/* Dette */}
					<Route path='/saisie/dette' exact> <Dette user={user} getUser={getUser} /></Route>

					{/* Vérification Retro */}
					<Route path='/verification/retrospective' exact> <VerifRetro user={user} getUser={getUser} /></Route>

					{/* Restitution */}
					<Route path='/restitution/retrospective/fct' exact> <Restitution user={user} getUser={getUser} /></Route>
					<Route path='/restitution/retrospective/inv' exact> <Restitution user={user} getUser={getUser} /></Route>
					<Route path='/restitution/prospective/fct' exact> <Restitution user={user} getUser={getUser} /></Route>
					<Route path='/restitution/prospective/inv' exact> <Restitution user={user} getUser={getUser} /></Route>

					{/* Tableau de Bord */}
					<Route path='/tableaudebord' exact> <TableauDeBord user={user} getUser={getUser} /></Route>
					<Route path='/graphtableaudebord' exact> <GraphTableauDeBord user={user} getUser={getUser} /></Route>

					{/* Calcul Emprunts Manuel */}
					<Route path='/emprunts/prospective/manuel' exact> <CalculEmpruntsManuel user={user} getUser={getUser} /></Route>

					{/* Calcul Emprunts Automatique */}
					<Route path='/emprunts/auto' exact> <CalculEmpruntsAuto user={user} getUser={getUser} /></Route>

					{/* Accueil Organisme */}
					<Route path='/accueil/organisme' exact> <AccueilOranisme user={user} getUser={getUser} /></Route>
					
					{/* PDF */}
					<Route path='/bilan/pdf' exact> <BilanPDF user={user} getUser={getUser} /></Route>

				{/* Les routes hors connexion */}
					<Route path='/register' exact> <Register /></Route>
					<Route path='/checktoken' exact> <Checktoken /></Route>
					{/* <Route path='/forgetpassword' exact> <Forgetpassword /></Route> */}
					<Route path='/login' exact> <Login /></Route>
					<Route path='/logout' exact> <Logout /></Route>

					<Route> <NotFound/></Route>
				</Switch>
			</Router>
		</div>
    );
}

export default App;