import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Bin } from '../Img/icon-bin.svg';
import Dialog from "./Dialog";


const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
};


const ModalGestionPPR = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    const blue = '#069BD3'
    
    const [aff, setAff] = useState(false);
    const [ppr, setPpr] = useState('');
    const [pprToSuppress, setPprToSuppress] = useState('');
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    
    useEffect(() => {
    }, [props])

    function callbackDialog(ret, value) {
        if (ret === true) {
            suppress();
        }
    }

    function closeModal() {
        props.setModalVisible(false);
    }

    async function valider()
    {
        if (ppr !== '')
        {
            await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                'create': true,
                'id_budget': props.user.id_budget,
                'intitule': ppr
            }))
            .then(async (e) => {
                
                if (e.data['status'] === 1) {

                    /* Recharge la liste des tag ppr pour ce budget */
                    await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                        getTags: true,
                        id_budget: props.user.id_budget
                    }))
                    .then(async (result) => {
                        await props.setListeppr(result.data);
                        await setAff(!aff);
                    })

                }
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }



    async function suppress()
    {
        if (pprToSuppress !== '') {
            console.log(pprToSuppress);
            await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                'suppress': true,
                'id_budget': props.user.id_budget,
                'intitule': pprToSuppress
            }))
            .then(async (e) => {
                
                if (e.data['status'] === 1) {

                    /* Recharge la liste des tag ppr pour ce budget */
                    await axios.post('https://app-slfinance.fr/api/tags_ppr.php', qs.stringify({
                        getTags: true,
                        id_budget: props.user.id_budget
                    }))
                    .then(async (result) => {
                        await props.setListeppr(result.data);
                        await setAff(!aff);
                    })

                }
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

            <Dialog
                setModalVisible={setDialogModalVisible}
                modalVisible={dialogModalVisible}
                callbackDialog={callbackDialog}
                message={"Confirmez vous la suppression?"}
            />

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <input
                className='shadow-md p-2 rounded-md'
                style={{display: 'flex', padding: 5, border: '1px solid '+purple, borderRadius: 5, minWidth: 300}}
                onChange={async(e) => {
                    setPpr(e.target.value);
                }}
                placeholder='Créer un tag'
                />

                <button
                    className='shadow-md rounded-md'
                    style={{backgroundColor: green, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10, marginLeft: 10}}
                    onClick={async () => {
                        valider();
                    }}
                >
                    <Check width="12" />
                    <p style={{marginLeft: 10}}>Valider</p>
                </button>
            </div>

            <div style={{flex: 1, margin: 25}}>
                {props.objet.map((elem, i) => (
                    <button
                    key={i}
                    style={{margin: 10, backgroundColor: blue, borderRadius: 3, padding: 5}}
                    onClick={async (e) => {
                        await setPprToSuppress(elem[0]);
                        await setDialogModalVisible(true);
                    }}
                    >
                        <p style={{color: 'white'}}>{elem}</p>
                    </button>
                ))}
            </div>
        
        </Modal>
    );
};

export default ModalGestionPPR;