import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {NotificationManager} from 'react-notifications';
import axios from 'axios';
import qs from 'qs';
import { ReactComponent as Check } from '../Img/icon-check.svg';
import { ReactComponent as Bin } from '../Img/icon-bin.svg';
import { addSpace, escapeHtml, rescueHtml } from "./Tools";
import Dialog from "./Dialog";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingLeft: 75,
        paddingRight: 75,
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        overflow: 'hidden'
    },
};


const ModalTaskGantt = (props) =>
{
    const green = '#59B44A'
    const purple = '#6571FE'
    const red = '#E33636'

    // const date = new Date();
    // const annee = date.getFullYear();
    // const mois = String(date.getMonth() + 1).padStart(2, '0');
    // const jour = String(date.getDate()).padStart(2, '0');
    // const dateFormatted = `${annee}-${mois}-${jour}`;
    const [aff, setAff] = useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [type, setType] = useState('');
    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [dependencies, setDependencies] = useState('');
    
    const [dialogModalVisible, setDialogModalVisible] = useState(false);
    


    function callbackDialog(ret) {
        if (ret === true) {
            suppress();
        }
    }



    function closeModal() {
        props.setModalVisible(false);
    }



    useEffect(() => {
        if (props.addupdate === 'add') {
            setId('');
            setName('Intitulé');
            setStart('');
            setEnd('');
            setType('task');
            setProgress(0);
            setColor('');
            setCommentaire('');
            setDependencies('');
        }
        else if (props.addupdate === 'update') {
            setId(props.id);
            setName(props.name);
            setStart(props.start);
            setEnd(props.end);
            setType(props.type);
            setProgress(props.progress?props.progress:0);
            setColor(props.color);
            setCommentaire(props.commentaire);
            setDependencies(props.dependencies);
        }
    }, [props])



    async function valider()
    {
        /* INSERT */
        if ((id === '' || !id) && name !== '' && start !== '') {
            if (type === 'task' || type === 'project') {
                if (end !== '') {
                    await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                        'insertTask': true,
                        'id_budget': props.id_budget,
                        'id_projet': props.id_projet,
                        'name': name,
                        'start': start,
                        'end': end,
                        'type': type,
                        'progress': progress,
                        'color': color,
                        'dependencies': dependencies,
                        'commentaire': commentaire,
                    }))
                    .then(async (e) => {
                        await props.getDatas(props.id_budget, props.id_projet);
                        await props.setModalVisible(false);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }
                else {
                    // message d'erreur: 'date de fin non renseignée'
                }
            }
            else if (type === 'milestone') {
                await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                    'insertTask': true,
                    'id_budget': props.id_budget,
                    'id_projet': props.id_projet,
                    'name': name,
                    'start': start,
                    'type': type,
                    'color': color,
                    'dependencies': dependencies,
                    'commentaire': commentaire,
                }))
                .then(async (e) => {
                    await props.getDatas(props.id_budget, props.id_projet);
                    await props.setModalVisible(false);
                })
                .catch((e) => {
                    console.log(e)
                })
            }
        }

        /* UPDATE */
        if (id !== '' && start !== '') {
            if (type === 'task' || type === 'project') {
                if (end !== '') {
                    await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                        'updateTaskById': true,
                        'id': id,
                        'name': name?name:'Intitulé',
                        'start': start,
                        'end': end,
                        'type': type,
                        'progress': progress,
                        'color': color,
                        'dependencies': dependencies,
                        'commentaire': commentaire,
                    }))
                    .then(async () => {
                        await props.getDatas(props.id_budget, props.id_projet);
                        await props.setModalVisible(false);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }
                else {
                    // message d'erreur: 'date de fin non renseignée'
                }
            }
            else if (type === 'milestone') {
                await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                    'updateTaskById': true,
                    'id': id,
                    'name': name?name:'Intitulé',
                    'start': start,
                    'end': null,
                    'type': type,
                    'progress': 0,
                    'color': color,
                    'dependencies': dependencies,
                    'commentaire': commentaire,
                }))
                .then(async () => {
                    await props.getDatas(props.id_budget, props.id_projet);
                    await props.setModalVisible(false);
                })
                .catch((e) => {
                    console.log(e)
                })
            }
        }
    }



    async function suppress()
    {
        await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
            'deleteTaskById': true,
            'id': id,
        }))
        .then(async () => {
            await props.getDatas(props.id_budget, props.id_projet);
            await props.setModalVisible(false);
        })
        .catch((e) => {
            console.log(e)
        })
    }

    return (
        <Modal
        isOpen={props.modalVisible}
        style={customStyles}
        ariaHideApp={false}
        onRequestClose={closeModal}
        >

        <Dialog
            setModalVisible={setDialogModalVisible}
            modalVisible={dialogModalVisible}
            callbackDialog={callbackDialog}
            message={"Confirmez vous la suppression?"}
        />
            <div>
                <div style={{marginTop: 10}}>
                    <input
                        style={{flex: 1, marginLeft: 0, width: 520, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7, textAlign: 'center'}}
                        value={name?rescueHtml(name):''}
                        placeholder='Intitulé'
                        onChange={async(e) => {
                            setName(e.target.value);
                        }}
                    />
                </div>

                <div style={{display: 'flex', marginTop: 20}}>
                    <div style={{flex: 1}}></div>

                    <div style={{}}>
                        <select className='shadow-md' style={{padding: 5}} value={type} onChange={(e) => {setType(e.target.value)}}>
                            <option value="task">Phase</option>
                            <option value="milestone">Point d'étape</option>
                        </select>
                    </div>

                    <div style={{marginLeft: 20}}>
                        <label htmlFor="dateInput">{type === 'milestone'?'Date : ':'Début : '}</label>
                        <input
                            type="date"
                            id="dateInput"
                            className='shadow-md'
                            style={{padding: 5}}
                            value={start || ''}
                            onChange={(e) => {
                                setStart(e.target.value);
                            }}
                        />
                    </div>

                    {type !== 'milestone'?
                    <div style={{marginLeft: 20}}>
                        <label htmlFor="dateInput">Fin : </label>
                        <input
                            type="date"
                            id="dateInput"
                            className='shadow-md'
                            style={{padding: 5}}
                            value={end || ''}
                            onChange={(e) => {
                                setEnd(e.target.value);
                            }}
                        />
                    </div>
                    :null}

                    <div style={{flex: 1}}></div>

                </div>

                <div style={{display: 'flex', marginTop: 20}}>
                    <div style={{flex: 1}}></div>
                    
                    {type !== 'milestone'?
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>Progression : </p>
                        <input
                            style={{marginLeft: 15, border: '1px solid #E9ECEF', borderRadius: 5, padding: 5, textAlign: 'center'}}
                            placeholder={progress ? progress + '%' : '0%'}
                            onBlur={async(e) => {
                                if (parseFloat(e.target.value) && e.target.value >= 0 && e.target.value <= 100) {
                                    await setProgress(e.target.value);
                                    e.target.value = '';
                                }
                                else {
                                    e.target.value = '';
                                }
                            }}
                        />
                    </div>
                    :null}

                    <div style={{marginLeft: 15, marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>Couleur : </p>
                        <input
                            value={color || '#000000'}
                            onChange={(e) => {setColor(e.target.value)}}
                            style={{marginLeft: 10, borderRadius: 2}}
                            type="color"
                        />
                    </div>
                    
                    <div style={{flex: 1}}></div>
                </div>

                <div style={{display: 'flex', marginTop: 20}}>
                    <div style={{flex: 1}}></div>
                    <div style={{display: 'flex'}}>
                        <p style={{padding: 5}}>Dépendance : </p>
                        <select
                        className='shadow-md'
                        style={{padding: 5}}
                        value={dependencies || ''}
                        onChange={(e) => {setDependencies(e.target.value)}}
                        >
                            <option value={''}>Aucune</option>
                            {props.tasks.map((task) =>
                            task.id !== props.id?
                            <option key={task.id} value={task.id}>{task.name}</option>
                            :null)}
                        </select>
                    </div>
                    <div style={{flex: 1}}></div>
                </div>

                <div style={{marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <textarea
                        style={{marginLeft: 15, border: '1px solid #E9ECEF', borderRadius: 5, padding: 5, textAlign: 'left', minWidth: 900, minHeight: 200}}
                        value={commentaire?commentaire:''}
                        placeholder='Commentaire'
                        onChange={async(e) => {
                            setCommentaire(e.target.value);
                        }}
                    />
                </div>


                <div style={{display: 'flex', marginTop: 25}}>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        
                            {props.addupdate === 'add'?
                            <div style={{display: 'flex'}}>
                                <button
                                    className='shadow-md rounded-md'
                                    style={{marginLeft: 5, backgroundColor: green, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                    onClick={() => {
                                        valider();
                                    }}
                                >
                                    <Check width="12"/>
                                    <p style={{marginLeft: 5}}>Valider</p>
                                </button>
                            </div>
                            :
                            <div style={{display: 'flex'}}>
                                <button
                                    className='shadow-md rounded-md'
                                    style={{marginLeft: 5, backgroundColor: green, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                    onClick={() => {
                                        valider();
                                    }}
                                >
                                    <Check width="12"/>
                                    <p style={{marginLeft: 5}}>Valider</p>
                                </button>
                                <button
                                    className='shadow-md rounded-md'
                                    style={{marginLeft: 5, backgroundColor: red, color: 'white', fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10}}
                                    onClick={() => {
                                        setDialogModalVisible(true)
                                    }}
                                >
                                    <Bin width="12"/>
                                    <p style={{marginLeft: 5}}>Supprimer</p>
                                </button>
                            </div>
                            }
                        
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default ModalTaskGantt;