import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import qs from 'qs';

function FileUpload(props) {

	const onDrop = useCallback(acceptedFiles => {
		var file = acceptedFiles[0]
		var dirname = props.user.nom
		
		const url = 'https://app-slfinance.fr/api/import_export.php';
		const params = {
			'importFile': true,
			'file': file,
			'dirname': dirname
		};
		axios.post(url, qs.stringify(params))
		.then(async function (res) {
			console.log(res.data)
		})
		.catch((err) => {
			console.log(err)
		})
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	return (
	<div>
		<div {...getRootProps()} style={dropzoneStyle}>
			<input {...getInputProps()} />
			<p>Faites glisser un fichier ici ou cliquez pour sélectionner un fichier</p>
		</div>
	</div>
	);
}

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default FileUpload;