import React, { useEffect, useState } from 'react';
import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import ModalTaskGantt from "./ModalTaskGantt";
import { ReactComponent as Add } from '../Img/icon-add.svg';
import axios from 'axios';
import qs from 'qs';

const GanttComponent = (props) =>
{
    const purple = '#6571FE'
    const green = '#59B44A'

    const [data, setData] = useState(undefined);
    const [modalTask, setModalTask] = useState(false);
    const [addupdate, setAddupdate] = useState(undefined);
    const [view, setView] = useState(undefined);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [type, setType] = useState('');
    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [dependencies, setDependencies] = useState('');

    async function getDatas(id_budget, id_projet)
    {
        await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
            'getTasks': true,
            'id_projet': id_projet,
            'id_budget': id_budget
        }))
        .then((res) => {
            let tmp = [];

            if (res.data) {
                res.data.forEach(element => {
                    tmp.push({
                        start: new Date(element.start),
                        end: new Date(element.end),
                        name: element.name,
                        id: element.id,
                        type: element.type,
                        progress: element.progress,
                        styles: { backgroundColor: element.color, backgroundSelectedColor: element.color, progressColor: green, progressSelectedColor: green },
                        dependencies: [parseInt(element.dependencies)],
                        commentaire: element.commentaire,
                    })
                });
            }
            setData(tmp)
        })
    }


    useEffect(() => {
        if (props.user) {
            getDatas(props.user.id_budget, props.id_projet);
        }
    }, [])


	return (
        <div style={{}}>

            {props.user && addupdate?
            <ModalTaskGantt
            modalVisible={modalTask}
            setModalVisible={setModalTask}
            id_budget={props.user.id_budget}
            id_projet={props.id_projet}
            getDatas={getDatas}
            addupdate={addupdate}
            id={id}
            name={name}
            start={start}
            end={end}
            type={type}
            progress={progress}
            color={color}
            commentaire={commentaire}
            dependencies={dependencies[0]}
            tasks={data}
            />
            :null}

            <div style={{display: 'flex'}}>

                <button
                    className='shadow-md rounded-md'
                    style={{margin: 5, backgroundColor: green, color: 'white', fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                    onClick={async () => {
                        await setId('');
                        await setName('');
                        await setStart('');
                        await setEnd('');
                        await setType('');
                        await setProgress(0);
                        await setColor('');
                        await setCommentaire('');
                        await setDependencies('');

                        await setAddupdate('add');
                        await setModalTask(true);
                    }}
                >
                    <Add width="12" />
                    <p style={{marginLeft: 10}}>Ajouter une tache</p>
                </button>

                <div style={{flex: 1}}></div>

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                    <button
                        className='shadow-md rounded-md'
                        style={view === 'Day'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Day');
                        }}
                    >
                        <p style={{}}>Jours</p>
                    </button>
                    <button
                        className='shadow-md rounded-md'
                        style={!view || view === 'Month'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Month');
                        }}
                    >
                        <p style={{}}>Mois</p>
                    </button>
                    <button
                        className='shadow-md rounded-md'
                        style={view === 'Year'?{backgroundColor: purple, color: 'white', margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}:{backgroundColor: 'white', color: purple, border: '1px solid '+purple, margin: 5, fontSize: 17, alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}
                        onClick={async () => {
                            await setView('Year');
                        }}
                    >
                        <p style={{}}>Années</p>
                    </button>
                    <div style={{flex: 1}}></div>
                </div>
            </div>

            <div style={{margin: 5}}>
                {data && data.length !== 0 && props.user && props.id_projet?
                <Gantt
                todayColor={'#c8eae9'}
                handleWidth={5}
                rowHeight={35}
                tasks={data}
                locale={'fr'}
                columnWidth={view === 'Day'?60:view === 'Month'?180:view === 'Year'?250:180}
                TaskListHeader={
                    () => (
                        <div style={{flex: 1, display: 'flex', backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minWidth: 450, minHeight: 50, borderBottom: '1px solid white', borderLeft: '1px solid white', borderTop: '1px solid white'}}>
                            <span style={{flex: 1}}>Intitulé</span>
                            <span style={{flex: 0.5}}>Debut</span>
                            <span style={{flex: 0.5}}>Fin</span>
                        </div>
                    )
                }
                TaskListTable={
                    (e) => (
                        e.tasks.map((task) =>
                        <div key={task.id} style={{display: 'flex', backgroundColor: '#f5f5f5', alignItems: 'center', justifyContent: 'center', minHeight: 35, borderLeft: '1px solid white', borderBottom: '1px solid white'}}>
                            <span style={{flex: 1, fontSize: 14}}>{task.name.substring(0, 25)+(task.name.length>25?'..':'')}</span>
                            <span style={{flex: 0.5, fontSize: 14}}>{task.start.getDate()<10?'0'+task.start.getDate():task.start.getDate()}/{(task.start.getMonth()+1)<10?'0'+(task.start.getMonth()+1):(task.start.getMonth()+1)}/{task.start.getFullYear()}</span>
                            <span style={{flex: 0.5, fontSize: 14}}>{task.end.getDate()<10?'0'+task.end.getDate():task.end.getDate()}/{(task.end.getMonth()+1)<10?'0'+(task.end.getMonth()+1):(task.end.getMonth()+1)}/{task.end.getFullYear()}</span>
                        </div>
                    ))
                }
                viewMode={
                    view?
                        view === 'Month'?
                            'Month'
                            :
                            view === 'Year'?
                                'Year'
                                :
                                view === 'Hour'?
                                'Hour'
                                :
                                'Day'
                        :
                        'Month'
                }
                onDateChange={async (e) => {
                    var starttmp = new Date(e.start);
                    var startday = String(starttmp.getDate()).padStart(2, '0');
                    var startmonth = String(starttmp.getMonth() + 1).padStart(2, '0');
                    var startyear = starttmp.getFullYear();
                    var starttmp = `${startyear}-${startmonth}-${startday}`;
                    await setStart(starttmp);

                    var endtmp = new Date(e.end);
                    var endday = String(endtmp.getDate()).padStart(2, '0');
                    var endmonth = String(endtmp.getMonth() + 1).padStart(2, '0');
                    var endyear = endtmp.getFullYear();
                    var endtmp = `${endyear}-${endmonth}-${endday}`;
                    await setEnd(endtmp);

                    await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                        'updateTaskById': true,
                        'id': e.id,
                        'start': starttmp,
                        'end': endtmp
                    }))
                    .then(() => {
                        getDatas(props.user.id_budget, props.id_projet);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }}
                onProgressChange={async (e) => {
                    await setProgress(e.progress);

                    await axios.post('https://app-slfinance.fr/api/gantt.php', qs.stringify({
                        'updateTaskById': true,
                        'id': e.id,
                        'progress': e.progress,
                    }))
                    .then(() => {
                        getDatas(props.user.id_budget, props.id_projet);
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                }}
                onDoubleClick={async (e) => {
                    var starttmp = new Date(e.start);
                    var startday = String(starttmp.getDate()).padStart(2, '0');
                    var startmonth = String(starttmp.getMonth() + 1).padStart(2, '0');
                    var startyear = starttmp.getFullYear();

                    var endtmp = new Date(e.end);
                    var endday = String(endtmp.getDate()).padStart(2, '0');
                    var endmonth = String(endtmp.getMonth() + 1).padStart(2, '0');
                    var endyear = endtmp.getFullYear();

                    await setId(e.id);
                    await setName(e.name);
                    await setStart(`${startyear}-${startmonth}-${startday}`);
                    await setEnd(`${endyear}-${endmonth}-${endday}`);
                    await setType(e.type);
                    await setProgress(e.progress);
                    await setColor(e.styles.backgroundColor);
                    await setCommentaire(e.commentaire);
                    await setDependencies(e.dependencies);

                    await setAddupdate('update');
                    await setModalTask(true);
                }}
                />
                :null}
            </div>

        </div>
	);
}

export default GanttComponent;
