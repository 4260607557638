import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { getyears } from "./Tools";
import { ReactComponent as Check } from '../Img/icon-check.svg';
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';

const FeedPopulation = (props) =>
{
    const [aff, setAff] = useState(false)
    const [objet, setObjet] = useState(false)
    const [YearsRetro, setYearsRetro] = useState(null)

    const green = '#59B44A'

    useEffect(() => {
        /* Récupération des années de retro */
        getyears(props.user.id_budget, 'R')
        .then(async(years_retro) => {
            await setYearsRetro(years_retro)

            /* Récupération de la population par année */
            await axios.post("https://app-slfinance.fr/api/population.php", qs.stringify({
                'get': true,
                'id_organisme': props.user.id_organisme,
                'annees': years_retro
            }))
            .then((res) => {
                setObjet(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
        })
    }, [])


    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = data => {
        var tmp = [];
        var i = 0;
        for (var elem in data) {
            tmp[i] = [elem, data[elem]];
            i++;
        }
        axios.post("https://app-slfinance.fr/api/population.php", qs.stringify({
            'update': true,
            'id_organisme': props.user.id_organisme,
            'objet': tmp
        }))
        .then((res) => {
            console.log(res.data)
            if (res.data['status'] === 1) {
                NotificationManager.success(res.data['status_message'], '', 500);
                setTimeout(() => window.location = window.location.href, 600);
            }
            else {
                NotificationManager.warning(res.data['status_message'], '', 3000);
            }
        })
        .catch((err) => {
            console.log(err)
        })
    };

	return (
        <div className='shadow-md' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <div style={{flex: 1, textAlign: 'center', padding: 5}}>
                <p>Population</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{flex: 1}}>
                    {YearsRetro && objet?
                    objet.map(annee =>
                    <div key={annee[0]} style={{flex: 1, display: 'flex', margin: 5, justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{flex: 0.4}}></div>
                        <p>{annee[0]} : </p>

                        <div style={{flex: 1.2}}>
                            <input style={{width: 150, border: '1px solid #E9ECEF', borderRadius: 5, padding: 7}} placeholder={annee[1]} {...register(annee[0])}/>
                        </div>
                    </div>
                    ):null}
                </div>

                <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 25}}>
                    <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                        <Check width="12" />
                        <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Valider" />
                    </div>
                </div>
            </form>
        </div>
	);
}

export default FeedPopulation;
