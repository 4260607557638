import React from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import axios from 'axios';
import qs from 'qs';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { ReactComponent as Add } from '../Img/icon-add.svg';

const ModalCreateBudget = (props) =>
{
    const green = '#59B44A'

    // Resultat du formulaire
    const { register, handleSubmit } = useForm();
    
    const onSubmit = async data => {
        if (data.siret != '' && data.nomen != '')
        {
            const params = {
                'feed_budgets': true,
                'budget_list': [{'id_organisme': props.organisme.id, 'nom': 'BUDGET PRINCIPAL', 'siret': data.siret, 'budget_annexe': 0, 'nomen': data.nomen.toUpperCase()}]
            };
            await axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify(params))
            .then(async function (result) {
                console.log(result.data)
                NotificationManager.success('Budget créé', '', 700);
                setTimeout(() => window.location = window.location.href, 900);
            })
            .catch((err) => {
                console.log(err)
            })
        }
        else {
            NotificationManager.warning('Champ manquant', '', 2000);
        }
    };


	return (
        <Modal
        isOpen={props.modalVisible}
        style={{borderWidth: '0px solid black', backgroundColor: 'rgba(255, 255, 255, 0.1)', marginRight: 1}}
        ariaHideApp={false}
        >
        <NotificationContainer />
            <button style={{textAlign: 'right', backgroundColor: 'red', borderWidth: '0px solid black', color: 'white'}} onClick={() => {props.setModalVisible(false)}}>X</button>
            <div style={{display: 'flex', flex: 1, textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: 20}}>
                <div style={{flex: 1}}>
                    <div>Créez un budget pour l'organisme : {props.organisme.nom}</div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{margin: 20}}>
                            <div style={{margin: 10}}>
                                <select {...register("type")}>
                                    <option value={0}>Budget Principal</option>
                                </select>
                            </div>
                            <div style={{margin: 10}}><input style={{width: 250}} placeholder='Siret' {...register("siret")} /></div>
                            <div style={{margin: 10}}><input style={{width: 250}} placeholder='Nomenclature ex: M57' {...register("nomen")} /></div>

                            <div style={{margin: 10, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <div style={{display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, paddingLeft: 10}}>
                                    <Add width="12" />
                                    <input style={{marginLeft: 5, fontSize: 14, paddingLeft: 5, paddingRight: 15}} type="submit" value="Créer" />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </Modal>
	);
}

export default ModalCreateBudget;
