import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { rescueHtml } from './Tools';
import { ReactComponent as Switch } from '../Img/icon-switch.svg';

const SwitchOrganisme = (props) =>
{
    let history = useHistory();
    const [organismes, setOrganismes] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [id_budget, setId_budget] = useState('');
    const [id_organisme, setId_organisme] = useState('');

    const purple = '#6571FE'
    const green = '#59B44A'

    useEffect(() => {
        axios.post("https://app-slfinance.fr/api/organismes.php", qs.stringify({'getOrganismes': true, 'ASC': true}))
        .then((res) => {
            var tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp[i] = {
                    id: res.data[i].id,
                    nom: rescueHtml(res.data[i].nom)
                }
            }
            setOrganismes(tmp);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [])


    function switch_organisme()
    {
        if (id_budget != '' && id_organisme != '')
        {
            const params = {
                'id': props.user.id,
                'id_budget': id_budget,
                'id_organisme': id_organisme,
                'updateUser': true
            };
            axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify(params))
            .then(async () => {
                await props.getUser()
                await history.push('/organismepage')
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

	return (
        <div className='shadow-xl' style={{textAlign: 'center',  borderRadius: 3, padding: 5, margin: 1, backgroundColor: 'white'}}>
            <p>Switch Organisme :</p>
            <div style={{margin: 10, textAlign: 'center'}}>
                <select
                style={{padding: 5}}
                    className='shadow-md'
                    value={id_organisme}
                    onChange={async e => {
                        await setId_organisme(e.target.value)
                        await axios.get("https://app-slfinance.fr/api/budgets.php?id_organisme="+e.target.value+'&list=true')
                        .then(async (result) => {
                            var tmp = []
                            var i = 0
                            await result.data.forEach(async budget => {
                                if (budget.budget_annexe === '0') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            
                            await result.data.forEach(async budget => {
                                if(budget.budget_annexe === '1') {
                                    tmp[i] = {
                                        'id_budget': budget.id,
                                        'budget_annexe': budget.budget_annexe,
                                        'nom': budget.nom,
                                        'siret': budget.siret
                                    }
                                    i++;
                                }
                            });
                            await setId_budget(tmp[0].id_budget)
                            await setBudgets(tmp);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    }}
                >
                    <option value="">...</option>
                    {
                        organismes.map((organisme) => 
                        <option key={organisme['id']} value={organisme['id']} >{organisme['nom']}</option>
                        )
                    }
                </select>
                {id_organisme != '' && budgets.length != 0?
                <div style={{marginTop: 10, textAlign: 'center'}}>
                    <div>
                        <select
                        className='shadow-md'
                        value={id_budget}
                        onChange={async (e) => {
                            await setId_budget(e.target.value)
                        }}>
                            {budgets.map((elem, i) => 
                            <option key={i} value={elem.id_budget}>{elem.nom}</option>
                            )}
                        </select>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <button
                        style={{marginTop: 10, display: 'flex', color: 'white', backgroundColor: green, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}
                        onClick={() => {switch_organisme()}}
                        >
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <Switch width="12" />
                                <p style={{marginLeft: 5}}>Go !</p>
                            </div>
                        </button>
                        <div style={{flex: 1}}></div>
                    </div>

                </div>
                :null}
            </div>
        </div>
	);
}

export default SwitchOrganisme;
